import styles from "./buildingOneStaffTableEventDate.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { useFormikContext, Field } from "formik";
import { useParams } from "react-router-dom";

import DatePickerField from "shared/ui/Inputs/DatePickerField";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseForBtn.svg";

import { isValid, parse } from "date-fns";
import { BuildingOneStaffValues } from "stores/BuildingModule/types/BuildingOneStaffValues";
import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { useEffect } from "react";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";

type BuildingOneStaffTableEventDateProps = {
  staff_id: string;
  col_name: string;
  staff_data: Staff;
  openedListName: string;
  changeOpenedListName: (arg: string) => void;
};

const BuildingOneStaffTableEventDate = ({
  staff_id,
  col_name,
  staff_data,
  openedListName,
  changeOpenedListName
}: BuildingOneStaffTableEventDateProps) => {
  const { buildingOneStaffStore } = useStores();
  const { setFieldValue, values, initialValues } =
    useFormikContext<BuildingOneStaffValues>();
  const { id } = useParams();

  // в useEffect при изменении режима просмотра с редактирования на просмотр
  // если значение event_start и event_end null обновить значение ячейки до initialValues
  // так как удалять дату статуса нельзя
  useEffect(() => {
    if (
      !buildingOneStaffStore.openedType?.length &&
      !values[staff_id][col_name]
    ) {
      setFieldValue(
        `${staff_id}.${col_name}`,
        initialValues[staff_id][col_name]
      );
    }
  }, [buildingOneStaffStore.openedType]);

  return buildingOneStaffStore.openedType === "edit" ? (
    <div
      className={classNames(styles.datePicker, {
        [styles.datePicker_withIcon]:
          buildingOneStaffStore.openedType === "edit" &&
          col_name === "event_end"
      })}
    >
      <Field
        name={`${staff_id}.${col_name}`}
        validate={(value: string) => {
          if (col_name === "event_start" && value === null) {
            return "Поле обязательно для заполнения";
          } else if (
            value !== null &&
            !isValid(parse(value, "yyyy-MM-dd", new Date()))
          ) {
            return "Некорректный формат даты";
          } else if (
            col_name === "event_end" &&
            staff_data.event_start !== null &&
            parse(value, "yyyy-MM-dd", new Date()) <
              parse(staff_data.event_start, "yyyy-MM-dd", new Date())
          ) {
            return "Дата окончания не может быть больше даты начала";
          }
        }}
      >
        {() => {
          return (
            <DatePickerField
              name={`${staff_id}.${col_name}`}
              title=""
              placeholderVisible
              isCalendarOpened={openedListName === `${staff_id}.${col_name}`}
              setIsCalendarOpened={() => {
                changeOpenedListName(`${staff_id}.${col_name}`);
              }}
              className={styles.datePickerCustom}
              onChange={(value) => {
                if (value && !value?.includes("_")) {
                  buildingOneStaffStore.updateStaffEvent(
                    id,
                    staff_id,
                    col_name,
                    {
                      event_id: values[staff_id].event.id,
                      [col_name]: value
                    }
                  );
                  if (
                    !buildingOneStaffStore.errorsMessage[id] ||
                    (
                      buildingOneStaffStore.errorsMessage[id] &&
                      Object.values(buildingOneStaffStore.errorsMessage[id])
                    ).length
                  ) {
                    setFieldValue(
                      `${staff_id}.${col_name}`,
                      initialValues[staff_id][col_name]
                    );
                  }
                }
              }}
            />
          );
        }}
      </Field>

      {col_name === "event_end" ? (
        <IconClose
          className={styles.iconClose}
          id={`BuildingOneStaffTableEventDate_clearEventEndBtn_${staff_id}`}
          onClick={() => setFieldValue(`${staff_id}.${col_name}`, null)}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    <div className="d-flex align-items-center">
      <p>{getFormattedDate(staff_data[col_name])}</p>
    </div>
  );
};

export default observer(BuildingOneStaffTableEventDate);
