import styles from "./awardsSearch.module.scss";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import SearchInput from "shared/ui/Inputs/SearchInput";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import { getValues } from "shared/utils/helpers/getValues";
import { Formik } from "formik";

type AwardsSearchProps = {
  id: string;
};

const AwardsSearch = ({ id }: AwardsSearchProps) => {
  const { staffOneAwardsStore } = useStores();
  const [foundAwardsCounter, setFoundAwardsCounter] = useState(0);

  const inputRef = useRef<HTMLInputElement>();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    ref,
    handler: () => staffOneAwardsStore.setIsFocusSearch(false)
  });

  // При первом клике активируем режим поиска, сворачиваем виджеты,
  // при последующих кликах очищаем поле поиска
  // и сбрасываем счетчик найденных наград
  const setSearchModeOn = () => {
    staffOneAwardsStore.setFoundAwards([]);
    staffOneAwardsStore.setAwardsFieldsFoundDuringSearch([]);
    staffOneAwardsStore.changeOpenedAwards("delete", []);
    setFoundAwardsCounter(0);
    staffOneAwardsStore.setSearchValue("");
    staffOneAwardsStore.setIsFocusSearch(true);
    staffOneAwardsStore.changeOpenedAwards(
      "add",
      staffOneAwardsStore.fieldsTitlеsArray
    );
  };

  const toggleFieldsShowing = () => {
    staffOneAwardsStore.searchValue && staffOneAwardsStore.setSearchValue("");
    staffOneAwardsStore.changeOpenedAwards(
      staffOneAwardsStore.awardsArray?.length ? "delete" : "add",
      staffOneAwardsStore.fieldsTitlеsArray
    );
  };

  const clearSearch = () => {
    staffOneAwardsStore.setSearchValue("");
    staffOneAwardsStore.setIsFocusSearch(false);
  };

  // Открываем только те виджеты, в которых есть награды
  // название которых содержит подстроку заданную в поле поиска
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFoundAwardsCounter(0);
    const foundAwards: string[] = [];
    const awardsFieldsFoundDuringSearch: string[] = [];

    staffOneAwardsStore.setSearchValue(e.target.value.toLowerCase());

    getValues(staffOneAwardsStore.awards).map((award) => {
      if (
        award.type_title.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        !awardsFieldsFoundDuringSearch.includes(
          staffOneAwardsStore.awardGroups[award.custom.group].title
        ) &&
          awardsFieldsFoundDuringSearch.push(
            staffOneAwardsStore.awardGroups[award.custom.group].title
          );

        !foundAwards.includes(award.type_title) &&
          foundAwards.push(award.type_title);
      }
    });
    setFoundAwardsCounter(foundAwards.length);
    staffOneAwardsStore.setFoundAwards(foundAwards);
    staffOneAwardsStore.setAwardsFieldsFoundDuringSearch(
      awardsFieldsFoundDuringSearch
    );
    staffOneAwardsStore.changeOpenedAwards(
      "delete",
      awardsFieldsFoundDuringSearch
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          onClick={setSearchModeOn}
          id="AwardsSearch_searchAwardsOn"
          ref={ref}
          className={styles.searchInput}
        >
          <Formik
            initialValues={{ awardsSearchInput: "" }}
            onSubmit={() => {
              return;
            }}
          >
            {({ setFieldValue }) => (
              <SearchInput
                name="awardsSearchInput"
                inputRef={inputRef}
                placeholder="Поиск по наградам сотрудника"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "awardsSearchInput",
                    e.target.value.toLowerCase()
                  );
                }}
                clearSearch={clearSearch}
                autocomplete="off"
                withoutBtn
              />
            )}
          </Formik>
        </div>
        <div className={styles.addButton}>
          <div
            className={styles.tooltip}
            data-tooltip="Добавление наград находится в разработке"
          >
            <ButtonsGroupForFormEdit
              blueBtnTitle="Добавить награду"
              blueBtnOnClick={() => undefined}
            />
          </div>
        </div>
      </div>
      {!staffOneAwardsStore.isFocusSearch ? (
        <div
          id={`AwardsSearch_toggleFieldsShowing_${id}`}
          className={classNames(styles.openAllFieldsButton, {
            [styles.openAllFieldsButtonActive]:
              !staffOneAwardsStore.awardsArray?.length
          })}
          onClick={toggleFieldsShowing}
        >
          {staffOneAwardsStore.awardsArray?.length
            ? "Развернуть всё"
            : "Свернуть всё"}
        </div>
      ) : (
        <div
          className={classNames(styles.openAllFieldsButton, {
            [styles.openAllFieldsButtonActive]:
              !staffOneAwardsStore.awardsArray?.length
          })}
        >{`Найдено наград: ${foundAwardsCounter}`}</div>
      )}
    </div>
  );
};
export default observer(AwardsSearch);
