import styles from "./staffImportButtonsMessageWindow.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import ErrorMessage from "shared/ui/ErrorMessage";
import { Button, ButtonTheme } from "shared/ui/Button";

type StaffImportButtonsMessageWindowProps = {
  setIsOpenedPanel: (value: boolean) => void;
};

const StaffImportButtonsMessageWindow = ({
  setIsOpenedPanel
}: StaffImportButtonsMessageWindowProps) => {
  const { staffImportStore } = useStores();

  const ref = useRef<HTMLDivElement>();

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (
        (typeof event["target"]["className"] === "string" &&
          !event["target"]["className"].includes(
            "staffImportButtons_errorsBlock"
          ) &&
          !event["target"]["className"].includes(
            "staffImportButtons_errorText"
          )) ||
        (typeof event["target"]["className"] === "object" &&
          !event["target"]["className"]["baseVal"]?.includes(
            "staffImportButtons_icon__expand"
          ))
      )
        setIsOpenedPanel(false);
    }
  });

  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      setIsOpenedPanel(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  return (
    <div className={styles.container} ref={ref}>
      {staffImportStore.errorsList.map((error) => {
        return (
          <ErrorMessage
            errors={error}
            key={error["head"]}
            splitItemLi={
              error["head"] ===
              staffImportStore.errorsDict["duplicateRow"]["head"]
                ? "Удалено"
                : null
            }
          />
        );
      })}
      <div className={styles.btnBlock}>
        <Button
          id="StaffImportButtonsWindow_close_panel"
          theme={ButtonTheme.SECONDARY}
          onClick={() => setIsOpenedPanel(false)}
        >
          Скрыть сообщение
        </Button>
      </div>
    </div>
  );
};

export default observer(StaffImportButtonsMessageWindow);
