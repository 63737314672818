import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { SelectModule } from "stores/StaffModule/types/Selects";
import { Col } from "stores/utils/types/Col";
import { Errors } from "stores/utils/types/ErrorsType";
import { Param } from "stores/utils/types/Param";
import { ApiResponse } from "stores/utils/types/ApiResponse";

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

export default class SafetyWorkAddNewStore {
  isLoading = false;
  error = false;
  errorsMessage: Partial<Errors["message"]> = {};

  safetyWorkCols: Record<string, Col> = {};
  safetyWorkParams: Record<string, Param> = {};
  currentTitles: string[] = [];
  newCertObject: Record<string, string | number> = {};
  requiredFields: string[] = [];

  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  getData = () => {
    this.isLoading = true;

    Promise.all([
      !Object.keys(this.safetyWorkParams).length && this.getTableParams(),
      // setTimeout нужен, так как getTableParams отрабатывает иногда позже, чем getTableCols, а getTableParams всегда должен раньше всех загрузить данные
      setTimeout(() => {
        !Object.keys(this.safetyWorkCols).length && this.getTableCols();
        this.getCompaniesList();
        this.getSelects();
        this.getStaffList();
      }, 100)
    ]).then(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.safetyWorkParams = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Col }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableCols"
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.safetyWorkCols = data["result"];
          Object.keys(this.safetyWorkCols).forEach((key) => {
            if (this.safetyWorkParams[key]?.["add_show"] === "on") {
              this.currentTitles.push(key);
            }
            if (this.safetyWorkParams[key]?.["required"]) {
              this.requiredFields.push(key);
            }
          });
          this.currentTitles.forEach((title) => {
            switch (this.safetyWorkCols[title]["type"]) {
              case "bool":
                this.newCertObject[title] = 0;
                break;
              default:
                this.newCertObject[title] = "";
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList",
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if (
            "company" in this.safetyWorkParams &&
            this.safetyWorkParams["company"]["isvariable"] === "on"
          ) {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            Object.values(data["result"]).forEach((value) => {
              directory[value.id] = {
                newname: value.id,
                title: value.title
              };
            });
            this.safetyWorkCols["company"] = {
              ...this.safetyWorkCols["company"],
              directory: directory
            };
          }
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: {
        select: Record<string, SelectModule>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "safetywork",
        method: "getSelects"
      });

      runInAction(() => {
        if (Object.values(data.select).length) {
          Object.entries(data["select"]).forEach(([key, value]) => {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            if (
              key in this.safetyWorkParams &&
              this.safetyWorkParams[key]["isvariable"] === "on"
            ) {
              Object.values(value).forEach((item) => {
                directory[item.id] = {
                  newname: item.id,
                  title: item.title
                };
              });
              this.safetyWorkCols[key] = {
                ...this.safetyWorkCols[key],
                directory: directory
              };
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj["newname"] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<Record<string, StaffListItem>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          if (
            "uid" in this.safetyWorkParams &&
            this.safetyWorkParams["uid"]["isvariable"] === "on"
          ) {
            this.safetyWorkCols["uid"] = {
              ...this.safetyWorkCols["uid"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.error = true;
          this.safetyWorkCols["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,

          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          if (
            "uid" in this.safetyWorkParams &&
            this.safetyWorkParams["uid"]["isvariable"] === "on"
          ) {
            const directory = (
              this.safetyWorkCols["uid"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));
            this.safetyWorkCols["uid"] = {
              ...this.safetyWorkCols["uid"],
              directory: directory
            };
          }
        } else {
          this.safetyWorkCols["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  addCertificate = async (values: Record<string, string | number>) => {
    const formData = { ...values };
    Object.entries(formData).forEach(([key, value]) => {
      if (!value) delete formData[key];
    });
    delete formData["withoutDateEnd"];
    delete formData["add_more"];
    try {
      const data: ApiResponse<string | boolean> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "safetywork",
          method: "addСertificate",
          body: formData
        });

      if (data.result) {
        this.rootStore.safetyWorkListStore.getData();
        if (!values["add_more"]) {
          setTimeout(() => {
            this.rootStore.safetyWorkListStore.getOneCert(
              data["result"] as string
            );
            this.rootStore.safetyWorkListStore.setSelectedCert(
              data["result"] as string
            );
          }, 300);
        }
      } else {
        runInAction(() => {
          this.errorsMessage = data["message"];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setErrorMessage = (value: Partial<Errors["message"]>) => {
    this.errorsMessage = value;
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
