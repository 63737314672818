import styles from "./staffOneInfoOne.module.scss";
import { RefObject, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import StaffOneInfoOneField from "./StaffOneInfoOneField";
import Worker from "features/StaffOneEditForm/StaffOneEditFormWorker";

import { ReactComponent as IconCollapseStroke } from "shared/assets/images/mainIcons/iconCollapseStroke.svg";
import { classNames } from "shared/utils/helpers/classNames";

import { DictType } from "modules/StaffModule/StaffOne/StaffOneInformation/StaffOneInfo/index";
import StaffOneInfoDismissBlock from "./StaffOneInfoDismissBlock";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

type StaffOneInfoOneProps = {
  data: { [key: string]: DictType };
  componentRef?: RefObject<null>;
  openedWidgets: string[];
  changeOpenedWidgets: (
    col: "rightCol" | "leftCol",
    type: "add" | "delete",
    widgets: string[]
  ) => void;
  isScroll: boolean;
};

const StaffOneInfoOne = ({
  data,
  componentRef,
  openedWidgets,
  changeOpenedWidgets,
  isScroll
}: StaffOneInfoOneProps) => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  const [selectedWorkerClothes, setSelectedWorkerClothes] = useState("none");

  const navigate = useNavigate();

  // hardcode: id групп СИЗ и Покинул ПОЛАТИ, статуса Уволен для Покинул ПОЛАТИ
  const PPE = "3eb713e6cf297ec580e5509c337616d33fb9dc39";
  const dismiss = "cc22b21ce8eff886c3564893bdd350fdb61b9431";
  const dismissStatus = "e329ed9b615763361b86d0d31cc3aac6b620c262";
  const companyBlock = "583c55060f8d067e25b59e69c98fea6482c575f2";

  const handleCheckoutAppsettings = () => {
    navigate("./edit=appsettings");
  };

  return (
    <div
      className={classNames(styles.blockForPrint, {
        [styles.workerPrint]: PPE in data
      })}
    >
      {Object.entries(data).map(([widgetId, item]) => {
        const handleSetOpenedWidgets = () => {
          changeOpenedWidgets(
            "leftCol",
            openedWidgets.includes(widgetId) ? "delete" : "add",
            [widgetId]
          );
        };
        const getDismissCondition = (company: string) => {
          if (
            id in staffOneStore.building_staff_events &&
            staffOneStore.building_staff_events[id][company] &&
            Object.values(staffOneStore.building_staff_events[id][company])
              .length
          ) {
            return Object.values(
              staffOneStore.building_staff_events[id][company]
            ).some((buildingStatus) => {
              return Object.values(buildingStatus).some(
                (status) => status["type"] === dismissStatus
              );
            });
          } else {
            return (
              data?.[widgetId]?.["dismiss_position"] ||
              data?.[widgetId]?.["dismiss_date"] ||
              data?.[widgetId]?.["grey_list"] ||
              data?.[widgetId]?.["black_list"]
            );
          }
        };
        return (
          <div
            key={widgetId}
            className={classNames("", {
              [styles.blockBottom]:
                !Object.keys(item).length &&
                widgetId !== "Настройки для приложения",
              [styles.dashedLine]:
                openedWidgets.includes(widgetId) &&
                widgetId !== "Настройки для приложения"
            })}
          >
            {staffOneStore.staffGroups[widgetId]?.title ||
            widgetId === "Настройки для приложения" ? (
              <div
                id={`staffOneInfoOne_showInfo_${widgetId}`}
                onClick={handleSetOpenedWidgets}
                className={classNames("", {
                  [styles.blind]: !isScroll,
                  [styles.blind_miniTop]: isScroll,
                  [styles.blind_withoutPadding]:
                    !openedWidgets.includes(widgetId)
                })}
              >
                <IconCollapseStroke
                  className={classNames("", {
                    [styles.blind__icon_close]:
                      !openedWidgets.includes(widgetId),
                    [styles.blind__icon_show]: openedWidgets.includes(widgetId)
                  })}
                />
                <p>
                  {widgetId === "Настройки для приложения"
                    ? "Настройки для приложения"
                    : staffOneStore.staffGroups[widgetId]?.title}
                </p>
                <div
                  className={classNames("", {
                    [styles.blind__gradientLine_gray]:
                      !openedWidgets.includes(widgetId),
                    [styles.blind__gradientLine]:
                      openedWidgets.includes(widgetId)
                  })}
                />
              </div>
            ) : (
              ""
            )}
            {openedWidgets.includes(widgetId) ? (
              widgetId === "Настройки для приложения" ? (
                <div className={styles.grayBtnWrapper}>
                  <Button
                    theme={ButtonTheme.SECONDARY}
                    size={ButtonSize.M}
                    id={`staffOneInfoOne_settingsShow_${widgetId}`}
                    onClick={handleCheckoutAppsettings}
                  >
                    Установить новый пароль от приложения
                  </Button>
                </div>
              ) : (
                <>
                  {widgetId === dismiss ? (
                    <div className={styles.dismissCompanyBlock}>
                      {staffOneStore.companiesTabs.filter((value) =>
                        getDismissCondition(value["id"])
                      ).length ? (
                        staffOneStore.companiesTabs
                          .filter((value) => getDismissCondition(value["id"]))
                          .map((value, i) => {
                            return (
                              <StaffOneInfoDismissBlock
                                key={value["id"]}
                                data={item}
                                value={value}
                                i={i}
                              />
                            );
                          })
                          .reverse()
                      ) : (
                        <div className={styles.withoutDismissInfo}>
                          У сотрудника нет увольнений
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {Object.keys(item).length &&
                  widgetId !== dismiss &&
                  widgetId !== companyBlock ? (
                    <>
                      <div
                        className={classNames(styles.field, {
                          [styles.fieldData]: PPE in data
                        })}
                      >
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                          <StaffOneInfoOneField
                            data={item}
                            componentRef={componentRef}
                            setSelectedWorkerClothes={setSelectedWorkerClothes}
                          />
                        </ErrorBoundary>
                        {widgetId === PPE ? (
                          <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Worker
                              selectedOneForEdit={{}}
                              selectedWorkerClothes={selectedWorkerClothes}
                              showMeasurer={false}
                              titleClothes={undefined}
                              titleHeight={undefined}
                              titleShoes={undefined}
                            />
                          </ErrorBoundary>
                        ) : null}
                      </div>
                    </>
                  ) : !staffOneStore.isLoadingForWidgetMetrics[id] &&
                    widgetId !== dismiss ? (
                    <div className={styles.field}>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <StaffOneInfoOneField
                          data={item}
                          componentRef={componentRef}
                          setSelectedWorkerClothes={setSelectedWorkerClothes}
                        />
                      </ErrorBoundary>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

export default observer(StaffOneInfoOne);
