import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "shared/ui/PageTitle";
import LoadedComponent from "widgets/LoadedComponent";
import styles from "./friendInviteOneComments.module.scss";
import CommentInputField from "features/Comments/CommentInputField";
import CommentsShowWithButton from "features/Comments/CommentsShowWithButton";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";

const FriendInviteOneComments = () => {
  const { menuStore, friendInviteStore, commentsStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    menuStore.setOpenedModule("friendInviteOne");
    menuStore.setOpenedSubmodule("friendInviteOneComments");
  }, []);

  useEffect(() => {
    menuStore.sendTabId(id);
    friendInviteStore.setSelectedOneInvite(id);
    menuStore.setSelectedWindow(`/friendinvite/id=${id}`);
    menuStore.updateWindow({
      mainPath: `/friendinvite/id=${id}`,
      path: `/friendinvite/id=${id}/comments`
    });
    !commentsStore.comments[id] && commentsStore.getComments(id);
  }, [id]);

  return (
    <LoadedComponent
      isLoading={commentsStore.isLoading[id]}
      error={commentsStore.error[id]}
    >
      <>
        <PageTitle title="Комментарии" leftCol />
        <div className={styles.wrapper}>
          <div className={styles.commentsList}>
            {!isEmpty(commentsStore.comments[id]) ? (
              <ul>
                {getValues(commentsStore.comments[id]).map((comment) => (
                  <CommentsShowWithButton
                    comment={comment}
                    key={comment.id}
                    deleteComment={commentsStore.deleteComment}
                  />
                ))}
              </ul>
            ) : (
              <ul className={styles.withoutComment}>
                У сотрудника ещё нет комментариев
              </ul>
            )}
          </div>
          <CommentInputField
            addComment={commentsStore.addComment}
            commentCol={friendInviteStore.inviteOneFriendTitles.comment}
          />
        </div>
      </>
    </LoadedComponent>
  );
};

export default observer(FriendInviteOneComments);
