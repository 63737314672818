import { observer } from "mobx-react-lite";
import styles from "./history.module.scss";
import StatusIcon from "shared/ui/StatusIcon";
import { useState } from "react";
import { classNames } from "shared/utils/helpers/classNames";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";

type HistoryProps = {
  history: {
    [key: string]: {
      [key: string]: string;
    };
  };
};

const History = ({ history }: HistoryProps) => {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => setIsHistoryOpen(!isHistoryOpen)}
        className={styles.container}
      >
        <StatusIcon
          icon="iconcollapsestroke"
          color="bw-gray6"
          className={classNames(styles.iconCollapse, {
            [styles.iconCollapseOpened]: isHistoryOpen,
            [styles.iconCollapseClosed]: !isHistoryOpen
          })}
        />
        <p className={styles.title}>История изменений</p>
      </div>
      {isHistoryOpen ? (
        getValues(history).length ? (
          getEntries(history).map(([date, history]) =>
            getEntries(history).map(([author, change]) => {
              return (
                <div className={styles.historyBlock} key={date}>
                  <div className={styles.date}>
                    <p>{getFormattedDate(date).replace(/ /g, ", в ")}</p>
                    <p className={styles.author}>{author}</p>
                  </div>
                  <div className={styles.change}>{change}</div>
                </div>
              );
            })
          )
        ) : (
          <div className={styles.withoutHistory}>Изменения не вносились</div>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default observer(History);
