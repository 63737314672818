import styles from "./staffOneHistory.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";
import { useParams } from "react-router-dom";
import StaffOneHistoryOne from "./StaffOneHistoryOne";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const StaffOneHistory = () => {
  const { staffOneAllSectionsStore, menuStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    staffOneAllSectionsStore.setSelectedOneForAllSections(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffHistory");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/history`
    });
  }, [id]);

  return (
    <>
      <PageTitle title="История" leftCol />
      <LoadedComponent
        isLoading={staffOneAllSectionsStore.isLoading[id]}
        error={
          staffOneAllSectionsStore.error[id] ||
          !staffOneAllSectionsStore.history
        }
      >
        {staffOneAllSectionsStore.history &&
        Object.entries(staffOneAllSectionsStore.history).length ? (
          <div>
            {Object.entries(staffOneAllSectionsStore.history).map(
              ([time, group]) => {
                const historyItems = Object.values(Object.values(group)[0]);

                const { user_name } = historyItems[0];
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    key={historyItems[0].id}
                  >
                    <StaffOneHistoryOne
                      time={time}
                      user_name={user_name}
                      historyItems={historyItems}
                    />
                  </ErrorBoundary>
                );
              }
            )}
          </div>
        ) : (
          <div className={styles.noHistory}>У сотрудника ещё нет истории</div>
        )}
      </LoadedComponent>
    </>
  );
};

export default observer(StaffOneHistory);
