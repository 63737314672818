import styles from "./itemsScrollBoardList.module.scss";
import { observer } from "mobx-react-lite";
import LoadedComponent from "widgets/LoadedComponent";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import ItemsScrollBoardItem from "./ItemsScrollBoardItem";
import { classNames } from "shared/utils/helpers/classNames";

type ItemsScrollBoardListProps = {
  options:
    | {
        [key: string]: OptionWithTitle;
      }
    | OptionWithTitle[];
  values: string | string[] | number;
  notSearchable: boolean;
  addItem: (option: OptionWithTitle) => void;
  isItemBtnMode: boolean;
  isSearchWithPagination: boolean;
  page: number;
  isLoading: boolean;
  foundedItems: OptionWithTitle[];
  otherItems: OptionWithTitle[];
  setFoundedItems: (arg: OptionWithTitle[]) => void;
  onDelete: () => void;
  selectedItem: OptionWithTitle;
  valueField: string;
};

const ItemsScrollBoardList = ({
  options,
  values,
  notSearchable,
  addItem,
  isItemBtnMode,
  isSearchWithPagination,
  page,
  isLoading,
  foundedItems,
  otherItems,
  setFoundedItems,
  onDelete,
  selectedItem,
  valueField
}: ItemsScrollBoardListProps) => {
  const deleteItemFromFoundedItems = (value: OptionWithTitle) => {
    for (let i = 0; i < foundedItems.length; ++i) {
      if (foundedItems[i][valueField] === value[valueField]) {
        const newArray = [...foundedItems];
        newArray.splice(i, 1);
        setFoundedItems(newArray);
      }
    }
  };
  const handleClickOnItem =
    (item: OptionWithTitle, deleteItem?: "delete") => () => {
      if (addItem) {
        addItem(item);
        deleteItem && deleteItemFromFoundedItems(item);
      }
    };

  if (isSearchWithPagination) {
    const filteredOptions = (options as OptionWithTitle[])?.filter(
      (item) => !(values as string[])?.includes(item[valueField] as string)
    );
    return (
      <div
        className={classNames("", { [styles.board]: !isItemBtnMode })}
        data-list="true"
      >
        <div
          className={classNames("", { [styles.itemList]: isItemBtnMode })}
          data-list="true"
        >
          {filteredOptions?.map((item) => (
            <ItemsScrollBoardItem
              key={item[valueField] as string}
              isItemBtnMode={isItemBtnMode}
              itemId={item[valueField]}
              itemTitle={item["title"]}
              isSearchWithPagination
              onClick={handleClickOnItem(item)}
            />
          ))}
          {page !== 1 ? (
            <>
              {isLoading && (
                <p className={styles.loadingText} data-list="true">
                  Пожалуйста, подождите
                </p>
              )}
            </>
          ) : (
            <LoadedComponent isLoading={isLoading} withoutText />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames("", { [styles.board]: !isItemBtnMode })}
      data-list="true"
    >
      <div
        className={classNames("", {
          [styles.itemList]: isItemBtnMode,
          [styles.separatingBorder]: isItemBtnMode && foundedItems.length
        })}
        data-list="true"
      >
        {!isItemBtnMode && !isSearchWithPagination && selectedItem ? (
          <ItemsScrollBoardItem
            itemId={selectedItem[valueField]}
            itemTitle={selectedItem.title}
            isSearchWithPagination={isSearchWithPagination}
            onClick={onDelete && onDelete}
            isPressed
          />
        ) : null}
        {foundedItems.map((item, i) => {
          const nextI = i + 1 !== foundedItems.length ? i + 1 : false;
          const fited = nextI
            ? foundedItems[nextI]["title"].charAt(0) ===
              foundedItems[i]["title"].charAt(0)
            : "";

          return (
            <ItemsScrollBoardItem
              key={item[valueField] as string}
              isItemBtnMode={isItemBtnMode}
              itemId={item[valueField]}
              itemTitle={item["title"]}
              isSearchWithPagination={isSearchWithPagination}
              onClick={handleClickOnItem(item, "delete")}
              hasBlueBorder={!nextI}
              hasGrayBorder={!fited}
            />
          );
        })}
      </div>
      <div
        className={classNames("", {
          [styles.itemList]: isItemBtnMode
        })}
        data-list="true"
      >
        {otherItems
          ? otherItems.map((item, i) => {
              const nextI = i + 1 !== otherItems.length ? i + 1 : "";
              const fited = nextI
                ? otherItems[nextI]["title"].charAt(0) ===
                  otherItems[i]["title"].charAt(0)
                : "";

              return (
                <ItemsScrollBoardItem
                  key={item[valueField] as string}
                  isItemBtnMode={isItemBtnMode}
                  itemId={item[valueField]}
                  itemTitle={item["title"]}
                  isSearchWithPagination={isSearchWithPagination}
                  onClick={handleClickOnItem(item)}
                  hasGrayBorder={!fited && !notSearchable}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default observer(ItemsScrollBoardList);
