import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import styles from "./staffOnePremiumLevel.module.scss";
import StaffOnePremiumLevelAbout from "./StaffOnePremiumLevelAbout";
import StaffOnePremiumLevelBlocks from "./StaffOnePremiumLevelBlocks";
import StaffOnePremiumLevelHeader from "./StaffOnePremiumLevelHeader";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const fieldsList = ["history_level", "reward"];

const StaffOnePremiumLevel = () => {
  const { menuStore, staffOnePremiumLevelStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    staffOnePremiumLevelStore.getData(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffPremiumLevel");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/premium_level`
    });
  }, [id]);
  return (
    <LoadedComponent
      isLoading={staffOnePremiumLevelStore.isLoading[id]}
      error={staffOnePremiumLevelStore.error[id]}
    >
      <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffOnePremiumLevelHeader />
        </ErrorBoundary>

        {id in staffOnePremiumLevelStore.premium_level_current &&
        staffOnePremiumLevelStore.premium_level_current[id] &&
        Object.values(staffOnePremiumLevelStore.premium_level_current[id])
          .length ? (
          <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <StaffOnePremiumLevelAbout />
            </ErrorBoundary>
            {fieldsList.map((field) => {
              return (
                <ErrorBoundary FallbackComponent={ErrorFallback} key={field}>
                  <StaffOnePremiumLevelBlocks field={field} />
                </ErrorBoundary>
              );
            })}
          </>
        ) : (
          <div className={styles.withoutData}>
            {staffOnePremiumLevelStore.premium_level_error_text[id] ||
              "Не найден в рейтинге"}
          </div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOnePremiumLevel);
