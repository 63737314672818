import styles from "./friendInviteAllTable.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

import { Table } from "react-bootstrap";
import LoadedComponent from "widgets/LoadedComponent";
import ScrollButton from "shared/ui/Buttons/ScrollButton";

import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";

const FriendInviteAllTable = () => {
  const { friendInviteStore, menuStore } = useStores();

  const navigate = useNavigate();
  const navigateUserProfile = ({ id }: { id: string }) => {
    navigate(`./id=${id}`);
    menuStore.setScrollPosition(menuStore.scroll);
  };

  const tableRef = useRef<HTMLTableElement>();

  const orderInvites = (field: string) => {
    let direction = "ASC";
    if (field === friendInviteStore.orderField) {
      direction = friendInviteStore.orderDirection === "ASC" ? "DESC" : "ASC";
    }
    friendInviteStore.setOrder(field, direction);
    friendInviteStore.setPage(1);
    friendInviteStore.getAllInviteFriendData();
  };

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      friendInviteStore.maxPage >= friendInviteStore.page &&
      friendInviteStore.page === friendInviteStore.prevPage
    ) {
      friendInviteStore.setPage(friendInviteStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {Object.keys(friendInviteStore.inviteAllFriendData).length ? (
        <>
          <ScrollButton tableRef={tableRef} />
          <Table striped className={styles.table} ref={tableRef}>
            <thead className={menuStore.isScroll ? styles.shadow : ""}>
              <tr>
                {Object.values(friendInviteStore.showFields).map((title) => {
                  return (
                    <th
                      id={`FriendInviteAllTable_sortInvites_${title}`}
                      key={title}
                      className={styles.tableHead}
                      onClick={() => {
                        orderInvites(title);
                      }}
                    >
                      {friendInviteStore.colsTitles[title]
                        ? friendInviteStore.colsTitles[title].title
                        : title}
                      <img src={iconArrowDouble} alt="sorting_button" />
                    </th>
                  );
                })}
                <th>
                  <div className={styles.shifts}>
                    {friendInviteStore.colsTitles["candidate_days"]?.["title"]}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {friendInviteStore.invitesList.length ? (
                friendInviteStore.invitesList.map((item) => {
                  return (
                    <tr
                      id={`FriendInviteAllTable_showOneInvite_${item.id}`}
                      key={item.id}
                      onClick={() =>
                        navigateUserProfile({
                          id: item.id
                        })
                      }
                      className={styles.tableRow}
                    >
                      {Object.values(friendInviteStore.showFields).map(
                        (title) => {
                          switch (title) {
                            case "uid":
                              return (
                                <td key={`td_${item.id}_${title}`}>
                                  {item.uid !== null ? (
                                    <>
                                      <div>{item.by.name}</div>
                                      <div className={styles.tn}>
                                        {item.by.tn}
                                      </div>
                                      {Object.values(item.by.events).map(
                                        (event, idx) => {
                                          return (
                                            <div
                                              key={`event_${item.id}_${idx}`}
                                              className={styles.company}
                                            >
                                              <Badge
                                                text={event.company}
                                                colorName={
                                                  !isEmpty(
                                                    friendInviteStore.companiesList
                                                  )
                                                    ? getValues(
                                                        friendInviteStore.companiesList
                                                      ).find(
                                                        (company) =>
                                                          event.company ===
                                                          company.title
                                                      )?.color
                                                    : "primary"
                                                }
                                              />
                                              <Badge
                                                text={event.type.title}
                                                textColorName="bw-gray7"
                                                icon={event.type.icon}
                                                iconColor={event.type.color}
                                                colorName="none"
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            case "alt_uid":
                              return (
                                <td key={`td_${item.id}_${title}`}>
                                  {item.alt_uid !== null ? (
                                    <>
                                      <div>{item.alt_uid}</div>
                                      <div
                                        className={styles["notStaff_bw-gray5"]}
                                      >
                                        Не сотрудник
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            case "candidate_phone":
                              return (
                                <td
                                  key={`td_${item.id}_${title}`}
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {formatPhoneNumber(item.candidate_phone)}
                                </td>
                              );
                            default:
                              return (
                                <td key={`td_${item.id}_${title}`}>
                                  {item[title] && item[title] !== -1
                                    ? getFormattedDate(item[title])
                                    : ""}
                                </td>
                              );
                          }
                        }
                      )}
                      <td>
                        <div className={styles.quantityOfShifts}>
                          {item.candidate_days && item.candidate_days !== -1
                            ? item.candidate_days
                            : ""}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className={styles.error}>
                  <td
                    colSpan={
                      Object.values(friendInviteStore.showFields).length + 1
                    }
                  >
                    {friendInviteStore.errorMessage
                      ? friendInviteStore.errorMessage
                      : ""}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      ) : (
        ""
      )}
      {friendInviteStore.page !== 1 ? (
        <p>
          {friendInviteStore.isLoading ? (
            <span className={styles.loading}>Пожалуйста, подождите</span>
          ) : (
            ""
          )}
        </p>
      ) : (
        <LoadedComponent
          isLoading={friendInviteStore.isLoading}
          errorMessage={friendInviteStore.errorMessage}
          actionButton={friendInviteStore.actionButton}
        />
      )}
    </>
  );
};

export default observer(FriendInviteAllTable);
