import styles from "./aregisterOneMasscopyFormPanel.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

import ErrorMessage from "shared/ui/ErrorMessage";
import Switch from "shared/ui/Inputs/Switch";
import LoadedComponent from "widgets/LoadedComponent";
import Tooltip from "shared/ui/Tooltip";

import { ReactComponent as IconCircle } from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as IconRows } from "shared/assets/images/mainIcons/iconRows.svg";
import { ReactComponent as IconColumns } from "shared/assets/images/mainIcons/iconColumns.svg";

import { Application } from "stores/AregisterModule/types/Application";
import { Button } from "shared/ui/Button";

type AregisterOneMasscopyFormPanelProps = {
  tableType: string;
  setOpenedModal: (arg: boolean) => void;
  values: {
    reload: number;
    original: Partial<Application>;
    copies: Partial<Application>[];
    massfill: Partial<Application>;
  };
};

const AregisterOneMasscopyFormPanel = ({
  tableType,
  setOpenedModal,
  values
}: AregisterOneMasscopyFormPanelProps) => {
  const { id } = useParams();
  const { menuStore, aregisterAppcreateStore, aregisterOneMasscopyStore } =
    useStores();
  const { dirty, isValid, handleSubmit } = useFormikContext();
  const [isTableLong, setIsTableLong] = useState(false);

  useEffect(() => {
    if (tableType === "column" && isTableLong) {
      setIsTableLong(false);
    } else if (values?.copies?.length) {
      setIsTableLong(
        menuStore.scroll["clientHeight"] <
          menuStore.scrollbarRef.current.getScrollHeight()
      );
    }
  }, [
    values.copies?.length,
    values.massfill,
    menuStore.scroll["scrollHeight"]
  ]);

  return (
    <div
      className={`${
        (!menuStore.scroll["top"] && tableType === "column") ||
        (menuStore.scroll["top"] < 0.8 && tableType === "column") ||
        (isTableLong && menuStore.scroll["top"] < 0.8)
          ? styles.container
          : styles.container_static
      }  ${
        aregisterAppcreateStore.errorsMessage[`${id}_masscopy`] &&
        Object.values(aregisterAppcreateStore.errorsMessage[`${id}_masscopy`])
          .length
          ? styles.container_error
          : ""
      } ${
        aregisterAppcreateStore.errorsMessage[`${id}_masscopy`] &&
        Object.values(aregisterAppcreateStore.errorsMessage[`${id}_masscopy`])
          .length &&
        menuStore.scroll["top"] > 0.8
          ? styles.container_errorStatic
          : ""
      } ${
        !aregisterAppcreateStore.errorsMessage[id] ||
        !Object.values(aregisterAppcreateStore.errorsMessage[id]).length
          ? styles.container_mini
          : ""
      }`}
    >
      {aregisterAppcreateStore.errorsMessage[`${id}_masscopy`] &&
      Object.values(aregisterAppcreateStore.errorsMessage[`${id}_masscopy`])
        .length
        ? Object.entries(
            aregisterAppcreateStore.errorsMessage[`${id}_masscopy`]
          ).map(([error_name, error]) => {
            if (!error.message) {
              return Object.entries(error).map(
                ([errorInError_name, errorInError]) => {
                  return (
                    <div
                      key={`${error_name}_${errorInError_name}`}
                      className={`${styles.error} ${
                        !error.result ? styles.error_withLink : ""
                      } ${styles.error_withMargin}`}
                    >
                      <ErrorMessage
                        errors={errorInError["message"]}
                        errorIcon={
                          errorInError["result"] ? <IconCircle /> : null
                        }
                      />
                      <LoadedComponent
                        isLoading={
                          aregisterAppcreateStore.isLoadingField.length > 0
                        }
                      />
                    </div>
                  );
                }
              );
            } else {
              return (
                <div
                  key={error_name}
                  className={`${styles.error} ${
                    !error.result ? styles.error_withLink : ""
                  }`}
                >
                  <ErrorMessage
                    errors={error.message}
                    errorIcon={error.result ? <IconCircle /> : null}
                  />
                  <LoadedComponent
                    isLoading={
                      aregisterAppcreateStore.isLoadingField.length > 0
                    }
                  />
                </div>
              );
            }
          })
        : null}
      <div className={styles.buttons}>
        <div
          className={
            (!menuStore.scroll["top"] && tableType === "column") ||
            (menuStore.scroll["top"] < 0.8 && tableType === "column") ||
            (isTableLong && menuStore.scroll["top"] < 0.8)
              ? styles.tooltipButton
              : styles.tooltipButton_bottom
          }
          data-tooltip={`${
            tableType === "column" ? "Развернуть" : "Свернуть"
          } таблицу`}
        >
          {tableType === "column" ? (
            <IconRows
              id="AregisterOneMasscopyFormPanely_changeTableTypeBtn_row"
              onClick={() => aregisterOneMasscopyStore.setTableType(id, "row")}
            />
          ) : (
            <IconColumns
              id="AregisterOneMasscopyFormPanel_changeTableTypeBtn_column"
              onClick={() =>
                aregisterOneMasscopyStore.setTableType(id, "column")
              }
            />
          )}
        </div>

        {!dirty ||
        !isValid ||
        (aregisterAppcreateStore.errorsMessage[`${id}_masscopy`] &&
          Object.values(aregisterAppcreateStore.errorsMessage[`${id}_masscopy`])
            .length > 0) ? (
          <Tooltip
            text="Заполните все обязательные поля"
            placement={
              (!menuStore.scroll["top"] && tableType === "column") ||
              (menuStore.scroll["top"] < 0.8 && tableType === "column") ||
              (isTableLong && menuStore.scroll["top"] < 0.8)
                ? "top"
                : "bottom"
            }
          >
            <Button>Создать</Button>
          </Tooltip>
        ) : (
          <Button
            onClick={() => {
              handleSubmit();
              setOpenedModal(true);
            }}
          >
            Создать
          </Button>
        )}

        <label className={styles.switch}>
          <Switch name="reload" />
          Повторить после создания
        </label>
      </div>
    </div>
  );
};

export default observer(AregisterOneMasscopyFormPanel);
