import styles from "./contributionErrorPanel.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import ErrorMessage from "shared/ui/ErrorMessage";

const ContributionErrorPanel = () => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();

  return aregisterOneStore.editContributionError[id]?.message ? (
    <div className={styles.container}>
      <ErrorMessage
        errors={{
          head: "Вклад не может быть меньше часов",
          color: "danger"
        }}
      />
    </div>
  ) : null;
};

export default observer(ContributionErrorPanel);
