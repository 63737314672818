import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import styles from "./staffOneSalary.module.scss";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
// import StaffOneSalaryFinanceTable from "./StaffOneSalaryFinanceTable";
import StaffOneSalaryHeader from "./StaffOneSalaryHeader";
import StaffOneSalaryFields from "./StaffOneSalaryFields";
import LoadedComponent from "widgets/LoadedComponent";
import StaffOneSalaryMetrics from "./StaffOneSalaryMetrics";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getValues } from "shared/utils/helpers/getValues";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import StaffSalaryForm from "features/StaffSalaryForm";

const FIELDS_LIST = [
  "date",
  "building",
  "type",
  "initiator",
  "money",
  "comment"
];

const StaffOneSalary = () => {
  const { menuStore, staffOneSalaryStore } = useStores();

  const { id } = useParams();

  const componentRef = useRef();
  const ref = useRef<HTMLDivElement>();

  // массив рефов для каждого блока, нужны для скролла к ним по нажатию на метрику
  const refsForBlock = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    staffOneSalaryStore.getData(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffSalary");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/salary`
    });
  }, [id]);

  useEffect(() => {
    if (staffOneSalaryStore.scrollToEdu && ref.current?.offsetTop) {
      menuStore.scrollbarRef.current?.view?.scroll({
        top: ref.current.offsetTop - ref.current.offsetHeight,
        left: 0,
        behavior: "smooth"
      });

      setTimeout(() => {
        staffOneSalaryStore.setScrollToEdu(false);
      }, 500);
    }
  }, [staffOneSalaryStore.scrollToEdu, ref.current?.offsetTop]);

  const toggleFieldsShowing = (value: boolean) => () =>
    getKeys(staffOneSalaryStore.conditionBlockList[id]).map((widget) => {
      staffOneSalaryStore.setConditionBlockList(id, widget, value);
    });

  const openedWidgets = getValues(
    staffOneSalaryStore.conditionBlockList[id]
  ).some((cond) => cond);

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoading[id]}
      error={staffOneSalaryStore.error[id]}
    >
      <div ref={componentRef} className={styles.main}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffOneSalaryHeader componentRef={componentRef} />
        </ErrorBoundary>

        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffOneSalaryMetrics refsForBlock={refsForBlock} />
        </ErrorBoundary>
        {/* <StaffOneSalaryFinanceTable /> */}
        <div
          id={`StaffOneSalary_toggleFieldsShowing_${id}`}
          className={classNames(styles.openAllFieldsButton, {
            [styles.openAllFieldsButtonActive]: !openedWidgets
          })}
          onClick={toggleFieldsShowing(openedWidgets ? false : true)}
        >
          {openedWidgets ? "Свернуть всё" : "Развернуть всё"}
        </div>

        {staffOneSalaryStore.tableList.map((field) => {
          return (
            <div key={field} ref={field === "holdingEdu" ? ref : null}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <StaffOneSalaryFields
                  field={field}
                  refsForBlock={refsForBlock}
                />
              </ErrorBoundary>
            </div>
          );
        })}

        <StaffSalaryForm
          title="Добавление операции"
          isOpenModal={staffOneSalaryStore.isOpenedAddOperationModal}
          setIsOpenModal={() =>
            staffOneSalaryStore.setIsOpenedAddOperationModal(false)
          }
          clearErrorsMessage={() => staffOneSalaryStore.clearErrorsMessage(id)}
          saveBtnOnClickFunc={staffOneSalaryStore.addOperation}
          fields={FIELDS_LIST}
          nameOneOfStaff={staffOneSalaryStore.nameOneOfStaff[id]}
          type="add"
          errorsMessage={staffOneSalaryStore.errorsMessage[id]}
          successMessage={staffOneSalaryStore.successMessage[id]}
        />
      </div>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalary);
