import styles from "./itemsScrollBoard.module.scss";
import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { positionValues, Scrollbars } from "react-custom-scrollbars-2";
import { IScrollBars } from "stores/utils/types/ScrollBars";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import ItemScrollBoardHeader from "./ItemScrollBoardHeader";
import { classNames } from "shared/utils/helpers/classNames";
import ItemsScrollBoardList from "./ItemsScrollBoardList";

const sortList = (list: OptionWithTitle[]) => {
  list.sort((a, b) => {
    typeof a.title === "number" ? (a.title = `${a.title}`) : "";
    typeof b.title === "number" ? (b.title = `${b.title}`) : "";
    const nameA = a.title ? a.title.toLowerCase() : "",
      nameB = b.title ? b.title.toLowerCase() : "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return list;
};

type ItemsScrollBoardProps = {
  options:
    | {
        [key: string]: OptionWithTitle;
      }
    | OptionWithTitle[];
  values?: string | string[] | number;
  valueName?: string;
  searchPlaceholder?: string;
  notSearchable?: boolean;
  addItem?: (option: OptionWithTitle) => void;
  isItemBtnMode?: boolean;
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  isLoading?: boolean;
  notSortable?: boolean;
  onDelete?: () => void;
  selectedItem?: OptionWithTitle;
};

const ItemsScrollBoard = ({
  options,
  values,
  valueName,
  notSearchable,
  searchPlaceholder,
  addItem,
  isItemBtnMode,
  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  searchValue,
  isLoading,
  notSortable,
  onDelete,
  selectedItem
}: ItemsScrollBoardProps) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [foundedItems, setFoundedItems] = useState<OptionWithTitle[]>([]);
  const [otherItems, setOtherItems] = useState<OptionWithTitle[]>([]);
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const scrollbar = useRef<IScrollBars>();

  const valueField = valueName === undefined ? "newname" : valueName;

  const handleScroll = (e: positionValues) => {
    if (e.top > 0.87) {
      setIsScrollBottom(!isScrollBottom);
    } else {
      setIsScrollBottom(false);
    }
  };

  useEffect(() => {
    if (
      isSearchWithPagination &&
      isScrollBottom &&
      maxPage >= page &&
      page === prevPage
    ) {
      setPage(page + 1);
    }
  }, [isScrollBottom]);

  useEffect(() => {
    if (!isSearchWithPagination && options) {
      let modifiedOptions: {
        [key: string]: OptionWithTitle;
      } = {};

      if (!Array.isArray(options)) {
        if ((values || values === 0) && valueField && !Array.isArray(values)) {
          Object.values(options).map((option) => {
            if (
              !`${values}`.includes(option[valueField] as string) &&
              values !== option[valueField]
            ) {
              modifiedOptions[option[valueField] as string] = option;
            }
          });
        } else if (Array.isArray(values) && values.length) {
          const keysArray = Object.keys(options).filter(
            (key) => !values.includes(key)
          );
          keysArray.forEach((key) => (modifiedOptions[key] = options[key]));
        } else {
          modifiedOptions = options;
        }
      } else {
        modifiedOptions = null;
      }

      const newOptions: OptionWithTitle[] = modifiedOptions
        ? Object.values(modifiedOptions)
        : (options as OptionWithTitle[]);

      if (searchInputValue) {
        const founded: OptionWithTitle[] = [];
        const other: OptionWithTitle[] = [];

        newOptions.forEach((col) => {
          if (
            col["title"].toLowerCase().includes(searchInputValue.toLowerCase())
          ) {
            founded.push(col);
          } else {
            other.push(col);
          }
        });

        setFoundedItems(notSortable ? founded : sortList(founded));
        setOtherItems(notSortable ? other : sortList(other));
      } else {
        setOtherItems(notSortable ? newOptions : sortList(newOptions));
        setFoundedItems([]);
      }
    }
  }, [values, options, searchInputValue]);

  return (
    <div
      className={classNames(styles.scrollboard, {
        [styles.scrollboardWithoutPadding]: !notSearchable
      })}
      data-list="true"
    >
      <ItemScrollBoardHeader
        notSearchable={notSearchable}
        searchPlaceholder={searchPlaceholder}
        isSearchWithPagination={isSearchWithPagination}
        getList={getList}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        notSortable={notSortable}
        otherItems={otherItems}
        setOtherItems={setOtherItems}
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      />

      <Scrollbars
        ref={scrollbar}
        style={{ width: "100%" }}
        autoHeight
        autoHeightMax={"100%"}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        onScrollFrame={(e) => handleScroll(e)}
        data-list="true"
      >
        <ItemsScrollBoardList
          options={options}
          values={values}
          valueField={valueField}
          notSearchable={notSearchable}
          addItem={addItem}
          isItemBtnMode={isItemBtnMode}
          isSearchWithPagination={isSearchWithPagination}
          page={page}
          isLoading={isLoading}
          foundedItems={foundedItems}
          otherItems={otherItems}
          setFoundedItems={setFoundedItems}
          onDelete={onDelete}
          selectedItem={selectedItem}
        />
      </Scrollbars>
    </div>
  );
};

export default observer(ItemsScrollBoard);
