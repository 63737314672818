import styles from "./staffOneSafetyWorkCertificateTable.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Table } from "react-bootstrap";

import { getTableArray } from "features/StaffOneSafetyWork";
import { useParams } from "react-router-dom";

type StaffOneInfoSafetyWorkCertificateTableProps = {
  activeTab: string;
  activePosition: string;
  positions: { [key: string]: { [key: string]: string } };
};

const StaffOneInfoSafetyWorkCertificateTable = ({
  activeTab,
  activePosition,
  positions
}: StaffOneInfoSafetyWorkCertificateTableProps) => {
  const { staffOneStore } = useStores();
  const { id } = useParams();
  const titles = [
    "Вид",
    staffOneStore.staffTableCols.type.title,
    "Дата начала",
    "Дата окончания"
  ];

  return !staffOneStore.safety_work[id]?.["certificates"]?.[activeTab] ? (
    <div className={styles.noData}>
      {`Не найдены должности в компании ${
        staffOneStore.companiesTabs.find((company) => company.id === activeTab)
          ?.title || ""
      }`}
    </div>
  ) : staffOneStore.safety_work[id]["certificates"][activeTab][activePosition] ? (
    <>
      {Object.keys(staffOneStore.safety_work[id]["certificates"][activeTab]).map(
        (position) => {
          const table = getTableArray(
            position,
            activePosition,
            staffOneStore.safety_work[id]["certificates"][activeTab][position]
          );

          return position === activePosition && table.length ? (
            <Table borderless className={styles.table} key={activePosition}>
              <thead>
                <tr className={styles.thead}>
                  {titles.map((title) => {
                    return (
                      <th key={title} className={styles.title}>
                        {title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {table.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className={`${styles.tr} ${
                        row.color ? styles[row.color] : ""
                      }`}
                    >
                      {Object.keys(row).map((cell) => {
                        if (cell === "color" || cell === "id") return;
                        return (
                          <td key={`${cell}-${row.id}`} className={styles.td}>
                            {row[cell] ||
                            cell === "date_start" ||
                            cell === "date_end"
                              ? row[cell]
                              : "—"}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            position === activePosition && !table.length && (
              <div
                key={`noPositionData-${activePosition}`}
                className={styles.noData}
              >{`Не найдены сертификаты для должности ${positions[activeTab]?.[activePosition]}`}</div>
            )
          );
        }
      )}
    </>
  ) : (
    <div className={styles.noData}>
      {`Не найдены сертификаты для должности ${positions[activeTab]?.[activePosition]}`}
    </div>
  );
};

export default observer(StaffOneInfoSafetyWorkCertificateTable);
