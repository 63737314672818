import styles from "./buildingOneStaffWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";

import { Formik } from "formik";

import StaffOneStatusesForm from "features/StaffOneStatusesForm";
import FormWindow from "shared/ui/FormWindow";
import BuildingOneStaffWindowTabs from "./BuildingOneStaffWindowTabs";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "shared/utils/validation/validationStaffForm";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { InitialValuesForStatus } from "stores/BuildingModule/buildingOne/buildingOneStaff";
import { getKeys } from "shared/utils/helpers/getKeys";
import { useParams } from "react-router-dom";
import { StatusesList } from "stores/BuildingModule/types/StatusesList";
import { addDays, format, isFuture } from "date-fns";
import { isEmpty, isEqual } from "lodash";

type BuildingOneStaffWindowProps = {
  staff_data: Staff;
};

const BuildingOneStaffWindow = ({
  staff_data
}: BuildingOneStaffWindowProps) => {
  const { buildingOneStore, buildingOneStaffStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [requiredFields, setRequiredFields] = useState<Record<string, number>>(
    {}
  );
  const { id } = useParams();

  useEffect(() => {
    setRequiredFields(
      buildingOneStaffStore.statusesGroups?.[
        buildingOneStaffStore.selectedStatus
      ]?.required_column || {}
    );
  }, [
    buildingOneStaffStore.statusesGroups[buildingOneStaffStore.selectedStatus]
      ?.required_column
  ]);

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  const getMinDate = () => {
    let date: Date = null;

    if (staff_data) {
      const isTransferInTab =
        buildingOneStaffStore.activeTab[id] === "transfer_in";
      const isTransferOutTab =
        buildingOneStaffStore.activeTab[id] === "transfer_out";
      const isLeftToBuildingStatus =
        buildingOneStaffStore.selectedStatus === StatusesList.LEFT_FOR_BUILDING;
      const isActiveStatus =
        buildingOneStaffStore.selectedStatus === StatusesList.ACTIVE;
      if (
        (isTransferOutTab && isLeftToBuildingStatus) ||
        (isTransferInTab && isActiveStatus)
      ) {
        date = new Date(staff_data?.event?.max_event_start);
      } else {
        date = addDays(new Date(staff_data?.event?.max_event_start), 1);
      }
    }

    return date;
  };

  // обычный ли это пользователь
  const isDefaultUser =
    !buildingOneStaffStore.eventTypes[staff_data?.event?.type]?.["custom"]?.[
      "allow_event_start_bool"
    ];

  // минимальная дата начала при добавлении статуса для тех пользователей
  // чей доступ не позволяет ставить любые даты (условно, т.к. на бэке тоже есть проверки)
  const minDate = isDefaultUser ? getMinDate() : undefined;
  // находится ли последний статус в будущем
  const lastStatusInFuture =
    staff_data?.event?.max_event_start &&
    isFuture(new Date(staff_data?.event?.max_event_start || ""));

  const getFieldsForValidation = () => {
    const fields = getKeys(
      buildingOneStaffStore.statusesInitialValues[
        buildingOneStaffStore.selectedStatus
      ]
    );

    if (
      buildingOneStaffStore.eventTypes[buildingOneStaffStore.selectedStatus]
        ?.custom?.close_withholding &&
      buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id]?.result
    ) {
      fields.push("money", "date", "without_edu");
    }
    return fields;
  };
  const validationSchema = getValidationSchema(
    getFieldsForValidation(),
    getKeys(requiredFields)
  );

  const getInitialValues = () => {
    const values = {
      ...buildingOneStaffStore.statusesInitialValues[
        buildingOneStaffStore.selectedStatus
      ],
      company: buildingOneStore.selectedOne.company as string
    };

    if (!lastStatusInFuture && minDate && new Date() <= minDate) {
      values.event_start = format(minDate, "yyyy-MM-dd");
    }

    if ("dismiss_position" in values) {
      values["dismiss_position"] = staff_data.position;
    }

    if ("dismiss_object" in values) {
      values["dismiss_object"] = buildingOneStore.selectedOne.title;
    }

    if (
      buildingOneStaffStore.eventTypes[buildingOneStaffStore.selectedStatus]
        ?.custom?.close_withholding &&
      buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id]?.result
    ) {
      values.money =
        buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id].sum;
      values.date =
        buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id].date;
      values.without_edu = 1;
    }
    return values;
  };
  const initialValues = getInitialValues();
  const handleSumbit = (values: InitialValuesForStatus) => {
    buildingOneStaffStore.addNewStaffEvent(
      { type: buildingOneStaffStore.selectedStatus, ...values },
      staff_data["staff_id"]
    );
  };

  return buildingOneStaffStore.isOpenWindow[id] &&
    !isEmpty(buildingOneStaffStore.staffTableCols) &&
    !isEmpty(
      buildingOneStaffStore.statusesInitialValues[
        buildingOneStaffStore.selectedStatus
      ]
    ) ? (
    <div className={styles.background}>
      <div className={styles.window}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur
          validateOnChange
          validateOnMount
          validationSchema={validationSchema}
          isLoadingForModal={buildingOneStaffStore.isLoadingForForm}
          onSubmit={handleSumbit}
        >
          {({ isValid, dirty }) => (
            <FormWindow
              title="Изменение статуса"
              subtitle={staff_data.name}
              setOpenWindow={() => {
                buildingOneStaffStore.setIsOpenWindow(id, false);
                buildingOneStaffStore.setSelectedStatus("", "");
              }}
              saveBtnTitle="Сохранить"
              saveBtnDisabledValue={
                (lastStatusInFuture && isDefaultUser) ||
                (isEqual(requiredFields, {
                  event_start: 1
                })
                  ? !isValid
                  : !isValid || !dirty)
              }
              addBtnTitle="Отмена"
              addBtnImg={<IconClose />}
              isScroll
              isLoadingForModal={buildingOneStaffStore.isLoadingForForm}
            >
              <>
                <BuildingOneStaffWindowTabs staff_id={staff_data.staff_id} />

                <div className={styles.company}>
                  <p className={styles.company__title}>
                    {buildingOneStore.buildingCols.company?.title}
                  </p>
                  <p>
                    {
                      buildingOneStore.variables.company[
                        buildingOneStore.selectedOne.company
                      ]?.title
                    }
                  </p>
                </div>
                <StaffOneStatusesForm
                  staff_id={staff_data.staff_id}
                  selectedStatus={buildingOneStaffStore.selectedStatus}
                  company={buildingOneStore.selectedOne.company}
                  cols={buildingOneStaffStore.staffTableCols}
                  boolCols={
                    buildingOneStaffStore.statusesGroups?.[
                      buildingOneStaffStore.selectedStatus
                    ]?.bool_column
                  }
                  requiredCols={requiredFields}
                  setRequiredCols={setRequiredFields}
                  openedListName={openedListName}
                  changeOpenedWindows={changeOpenedWindows}
                  params={
                    buildingOneStaffStore.eventTypes[
                      buildingOneStaffStore.selectedStatus
                    ]
                  }
                  isLoadingAllowEdu={buildingOneStaffStore.isLoadingAllowEdu}
                  retention={
                    buildingOneStaffStore.openedAllEventAllowEdu[
                      staff_data.staff_id
                    ]
                  }
                  updateRetention={
                    buildingOneStaffStore.allUpdatedEventAllowEdu[
                      staff_data.staff_id
                    ]
                  }
                  checkEventAllowEdu={buildingOneStaffStore.updateEventAllowEdu}
                  minDateForDefaultUser={minDate}
                  maxDate={
                    lastStatusInFuture
                      ? new Date(staff_data?.event?.max_event_start || "")
                      : null
                  }
                  disabledForm={lastStatusInFuture && isDefaultUser}
                  showRetentionBlock
                  staffCode={
                    buildingOneStaffStore.staffGuid[staff_data.staff_id]
                  }
                  selectStaffGuid={buildingOneStaffStore.selectStaffGuid}
                  staffGuidMessage={
                    buildingOneStaffStore.staffGuidMessage[staff_data.staff_id]
                  }
                />
              </>
            </FormWindow>
          )}
        </Formik>
      </div>{" "}
    </div>
  ) : null;
};

export default observer(BuildingOneStaffWindow);
