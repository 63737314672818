import styles from "./staffOnePremiumLevelBlocks.module.scss";
import { useState } from "react";
import StatusIcon from "shared/ui/StatusIcon";
import StaffOnePremiumLevelReward from "./StaffOnePremiumLevelReward";
import StaffOnePremiumLevelHistory from "./StaffOnePremiumLevelHistory";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const fieldsTitle = {
  history_level: "История уровней",
  reward: "Вознаграждения"
};

type StaffOnePremiumLevelBlocksProps = {
  field: string;
};

const StaffOnePremiumLevelBlocks = ({
  field
}: StaffOnePremiumLevelBlocksProps) => {
  const [isShow, setIsShow] = useState(true);

  return (
    <div className={`${styles.marginTop} ${isShow ? styles.dashedLine : ""}`}>
      <div
        id={`StaffOnePremiumLevelBlocks_showInfo_${field}`}
        onClick={() => {
          setIsShow(!isShow);
        }}
        className={`${styles.blind} ${
          !isShow ? styles.blind_closedInfo : styles.blind_openedInfo
        } `}
      >
        <StatusIcon
          icon="iconcollapsestroke"
          color={!isShow ? "bw-gray6" : "blue-lazure"}
        />
        <p>{fieldsTitle[field]}</p>
        <div className={styles.blind__gradientLine} />
      </div>
      {isShow ? (
        <div className={styles.field}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {(() => {
              switch (field) {
                case "history_level":
                  return <StaffOnePremiumLevelHistory />;
                case "reward":
                  return <StaffOnePremiumLevelReward />;
              }
            })()}
          </ErrorBoundary>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default StaffOnePremiumLevelBlocks;
