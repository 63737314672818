import styles from "./staffOneInfoRetention.module.scss";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";

import { useStores } from "stores/index";
import StatusIcon from "shared/ui/StatusIcon";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const StaffOneInfoRetention = () => {
  const { staffOneStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneStore.withholding_edu) &&
      staffOneStore.getWithHoldingEduWidget(id);
  }, [id]);

  const currentTitles = [
    "index",
    "date_start",
    "closed_ws_num",
    "remainder_operationless",
    "all_operation_sum",
    "status"
  ];

  const cols = {
    index: { title: "Номер вычета" },
    date_start: { title: "Дата начала" },
    closed_ws_num: { title: "Рабочих дней всего" },
    remainder_operationless: {
      title: "Размер вычета в зависимости от кол-ва дней"
    },
    all_operation_sum: { title: "Удержания" },
    status: { title: "Статус вычета" }
  };

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoadingForWidget_withholding_edu[id]}
      withoutText
    >
      <>
        {id in staffOneStore.withholding_edu &&
        Object.keys(staffOneStore.withholding_edu[id]).length &&
        Object.values(cols).length ? (
          <Table className={styles.table}>
            <thead>
              <tr>
                {currentTitles.map((title) => {
                  return (
                    <th key={title}>
                      <p className="m-0">{cols[title]["title"]}</p>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.values(staffOneStore.withholding_edu[id].widget).map(
                (retention) => {
                  return (
                    <tr key={retention.id}>
                      {currentTitles.map((title) => {
                        switch (title) {
                          case "remainder_operationless":
                            return (
                              <td key={title}>
                                <div className="d-flex flex-column">
                                  <p>
                                    {title in retention &&
                                    typeof retention[title] === "number"
                                      ? numberWithSpaces(retention[title])
                                      : ""}
                                  </p>
                                  <p className={styles.formula}>
                                    {retention.remainder_formula}
                                  </p>
                                </div>
                              </td>
                            );
                          case "all_operation_sum":
                            return (
                              <td key={title}>
                                <p>
                                  {retention[title]
                                    ? numberWithSpaces(
                                        Math.abs(retention[title])
                                      )
                                    : ""}
                                </p>
                              </td>
                            );
                          case "status":
                            return (
                              <td key={title}>
                                <div className={styles.status}>
                                  <StatusIcon
                                    icon={
                                      staffOneStore.withholding_edu[id]
                                        .statusList[retention.status]?.["icon"]
                                    }
                                    color={
                                      staffOneStore.withholding_edu[id]
                                        .statusList[retention.status]?.[
                                        "color_api"
                                      ]
                                    }
                                  />
                                  <p>
                                    {staffOneStore.withholding_edu[id]
                                      .statusList[retention.status]?.[
                                      "show_title_api"
                                    ]
                                      ? staffOneStore.withholding_edu[id]
                                          .statusList[retention.status]?.title
                                      : ""}
                                  </p>
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td key={title}>
                                <p>{getFormattedDate(retention[title])}</p>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        ) : (
          <div className={styles.noRetentions}>Вычет не найден</div>
        )}{" "}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoRetention);
