import LoadedComponent from "widgets/LoadedComponent";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import StatusIcon from "shared/ui/StatusIcon";
import { useStores } from "stores";
import styles from "./staffOneSalaryPaymentList.module.scss";
import { ReactComponent as IconFinance } from "shared/assets/images/mainIcons/iconFinance.svg";
import { fileUrl } from "stores/utils/consts";
import { observer } from "mobx-react-lite";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";
import PaymentListTabs from "./PaymentListTabs";
import _ from "lodash";
import { Badge } from "shared/ui/Badge";

//hardcode
const icons = {
  "68770fbae852d14b8870f25cfc371ea669c978e4": { icon: "coins" }, //статус Выплачено
  "8d1fad4ef2f2641fe5f4af6fec90a049a4445300": {
    icon: "like",
    color: "purple"
  }, //статус Подтверждено РП
  e319f656830eef40081824c33b6cb92f8cf516e7: {
    icon: "circlecheck",
    color: "teal"
  }, //статус Создано
  "2f49fbc76296047cbf62f666d027df00adc00b64": {
    icon: "crane",
    color: "deep-purple"
  }, //Ведомости по объектам
  f75c1991700f6bfd9941281f02366804ec1814c2: {
    icon: "persondelete",
    color: "danger"
  } //Ведомости по уволенным
};

const StaffOneSalaryPaymentList = () => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    !(id in staffOneSalaryStore.paymentList) &&
      staffOneSalaryStore.getPaymentList(id);
  }, [id]);

  const paymentList = getValues(staffOneSalaryStore.paymentList[id])
    .filter((payment) =>
      staffOneSalaryStore.selectedTypeForPaymentList[id] !== "all"
        ? staffOneSalaryStore.selectedTypeForPaymentList[id] === payment.type
        : payment
    )
    .filter((payment) =>
      staffOneSalaryStore.selectedCompanyForPaymentList[id] !== "all"
        ? staffOneSalaryStore.selectedCompanyForPaymentList[id] ===
          payment?.object?.company_id
        : payment
    );

  // функция высчитывающая общее количество ведомостей из всех типов
  let paymentListlength = 0;
  const lengths: number[] = [];
  paymentList.forEach((index) => {
    lengths.push(index?.paymentlist.length);
    paymentListlength = _.sum(lengths);
  });

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoadingForPaymentList[id]}
      withoutText
    >
      <>
        {!isEmpty(staffOneSalaryStore.paymentList[id]) &&
        !isEmpty(staffOneSalaryStore.colsForPaymentList) ? (
          <>
            <PaymentListTabs paymentList={paymentListlength} />
            {paymentList?.length ? (
              <Table>
                <thead>
                  <tr>
                    {staffOneSalaryStore.currentTitlesForPaymentList.map(
                      (title) => {
                        return (
                          <th key={title} className={styles.title}>
                            {
                              staffOneSalaryStore.colsForPaymentList[title]?.[
                                "title"
                              ]
                            }
                          </th>
                        );
                      }
                    )}
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {paymentList.map((payment, index) => {
                    const paymentLink = `${fileUrl}/crm/salary/?ps_id=${payment.paymentlist?.[0]?.id}`;
                    const handleOpenPayment = () =>
                      setTimeout(() => {
                        window.open(paymentLink, "_blank");
                      }, 100);
                    return (
                      <React.Fragment key={payment.id}>
                        <tr
                          className={classNames("", {
                            [styles.hoverRow]: !isHovered,
                            [styles.greyRow]: index % 2
                          })}
                        >
                          {staffOneSalaryStore.currentTitlesForPaymentList.map(
                            (title) => {
                              switch (title) {
                                case "object":
                                  return (
                                    <td
                                      key={title}
                                      rowSpan={payment.paymentlist?.length}
                                      className={classNames("", {
                                        [styles.verticalAlignTop]:
                                          payment.paymentlist?.length > 1
                                      })}
                                      data-field="notHover"
                                      onMouseEnter={() => setIsHovered(true)}
                                      onMouseLeave={() => setIsHovered(false)}
                                    >
                                      <div className={styles.building}>
                                        <a
                                          className={styles.buildingTitle}
                                          href={`${fileUrl}/crm/building/?id=${payment.object?.id}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {payment.object?.title}
                                        </a>
                                        <Badge
                                          text={payment.object?.company_title}
                                          colorName={
                                            staffOneSalaryStore.companiesDict[
                                              payment.object?.company_id
                                            ]?.color
                                          }
                                        />
                                      </div>
                                    </td>
                                  );
                                case "type":
                                  return (
                                    <td
                                      key={title}
                                      rowSpan={payment.paymentlist?.length}
                                      className={classNames("", {
                                        [styles.verticalAlignTop]:
                                          payment.paymentlist?.length > 1
                                      })}
                                      data-field="notHover"
                                      onMouseEnter={() => setIsHovered(true)}
                                      onMouseLeave={() => setIsHovered(false)}
                                    >
                                      <div className={styles.statusCell}>
                                        <StatusIcon
                                          icon={icons[payment[title]]?.icon}
                                          color={icons[payment[title]]?.color}
                                        />
                                        <p>
                                          {
                                            staffOneSalaryStore
                                              .paymentListTypes[payment[title]]
                                              ?.title
                                          }
                                        </p>
                                      </div>
                                    </td>
                                  );

                                case "status":
                                  return (
                                    <td
                                      key={title}
                                      onClick={handleOpenPayment}
                                      className={styles.tooltip}
                                      data-tooltip="Перейти к Ведомости"
                                    >
                                      <div className={styles.statusCell}>
                                        <StatusIcon
                                          icon={
                                            icons[
                                              payment.paymentlist?.[0]?.[title]
                                            ]?.icon
                                          }
                                          color={
                                            icons[
                                              payment.paymentlist?.[0]?.[title]
                                            ]?.color
                                          }
                                        />
                                        <p>
                                          {
                                            staffOneSalaryStore
                                              .paymentListStatuses[
                                              payment.paymentlist?.[0]?.[title]
                                            ]?.title
                                          }
                                        </p>
                                      </div>
                                    </td>
                                  );

                                case "uid":
                                  return (
                                    <td
                                      key={title}
                                      onClick={handleOpenPayment}
                                      className={styles.tooltip}
                                      data-tooltip="Перейти к Ведомости"
                                    >
                                      <div className={styles.uidCell}>
                                        <div className={styles.uidIcon}>
                                          <IconFinance />
                                        </div>
                                        <p>
                                          {payment.paymentlist?.[0]?.[title]}
                                        </p>
                                      </div>
                                    </td>
                                  );

                                case "money":
                                  return (
                                    <td
                                      key={title}
                                      className={classNames(
                                        styles.moneyCell,
                                        {},
                                        [styles.tooltip]
                                      )}
                                      onClick={handleOpenPayment}
                                      data-tooltip="Перейти к Ведомости"
                                    >
                                      {numberWithSpaces(
                                        payment.paymentlist?.[0]?.[title]
                                      )}
                                    </td>
                                  );

                                default:
                                  return (
                                    <td
                                      key={title}
                                      onClick={handleOpenPayment}
                                      className={styles.tooltip}
                                      data-tooltip="Перейти к Ведомости"
                                    >
                                      {getFormattedDate(
                                        payment.paymentlist?.[0]?.[title]
                                      )}
                                    </td>
                                  );
                              }
                            }
                          )}
                        </tr>
                        {payment.paymentlist?.length > 1
                          ? payment.paymentlist.map((item, ind) => {
                              if (ind === 0) return;
                              return (
                                <tr
                                  key={item.id}
                                  className={classNames(styles.hoverRow, {
                                    [styles.greyRow]: index % 2
                                  })}
                                  onClick={handleOpenPayment}
                                >
                                  {staffOneSalaryStore.currentTitlesForPaymentList.map(
                                    (title) => {
                                      switch (title) {
                                        case "object":
                                        case "type":
                                          return;

                                        case "status":
                                          return (
                                            <td
                                              key={title}
                                              onClick={handleOpenPayment}
                                              className={styles.tooltip}
                                              data-tooltip="Перейти к Ведомости"
                                            >
                                              <div
                                                className={styles.statusCell}
                                              >
                                                <StatusIcon
                                                  icon={
                                                    icons[item[title]]?.icon
                                                  }
                                                  color={
                                                    icons[item[title]]?.color
                                                  }
                                                />
                                                <p>
                                                  {
                                                    staffOneSalaryStore
                                                      .paymentListStatuses[
                                                      item[title]
                                                    ]?.title
                                                  }
                                                </p>
                                              </div>
                                            </td>
                                          );

                                        case "uid":
                                          return (
                                            <td
                                              key={title}
                                              onClick={handleOpenPayment}
                                              className={styles.tooltip}
                                              data-tooltip="Перейти к Ведомости"
                                            >
                                              <div className={styles.uidCell}>
                                                <div className={styles.uidIcon}>
                                                  <IconFinance />
                                                </div>
                                                <p>{item[title]}</p>
                                              </div>
                                            </td>
                                          );

                                        case "money":
                                          return (
                                            <td
                                              key={title}
                                              className={classNames(
                                                styles.moneyCell,
                                                {},
                                                [styles.tooltip]
                                              )}
                                              onClick={handleOpenPayment}
                                              data-tooltip="Перейти к Ведомости"
                                            >
                                              {numberWithSpaces(item[title])}
                                            </td>
                                          );

                                        default:
                                          return (
                                            <td
                                              key={title}
                                              onClick={handleOpenPayment}
                                              className={styles.tooltip}
                                              data-tooltip="Перейти к Ведомости"
                                            >
                                              {getFormattedDate(item[title])}
                                            </td>
                                          );
                                      }
                                    }
                                  )}
                                </tr>
                              );
                            })
                          : null}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className={styles.withoutData}>Список ведомостей пуст</div>
            )}
          </>
        ) : (
          <div className={styles.withoutData}>Список ведомостей пуст</div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalaryPaymentList);
