import styles from "./buildingOneStaffMovements.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import SearchInput from "shared/ui/Inputs/SearchInput";
import BuildingOneStaffMovementsTable from "./BuildingOneStaffMovementsTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Formik } from "formik";

const BuildingOneStaffMovements = () => {
  const { id } = useParams();
  const { modalWindowsStore, menuStore, buildingOneStaffMovementsStore } =
    useStores();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    buildingOneStaffMovementsStore.setSelectedBuilding(id);
    menuStore.sendTabId(id);
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("buildingOne");
    menuStore.setOpenedSubmodule("buildingOneStaffMovements");
    menuStore.updateWindow({
      mainPath: `/building/id=${id}`,
      path: `/building/id=${id}/staffmovements`
    });
  }, [id]);

  useEffect(() => {
    if (
      buildingOneStaffMovementsStore.page !== 1 &&
      buildingOneStaffMovementsStore.page <=
        buildingOneStaffMovementsStore.maxPage &&
      buildingOneStaffMovementsStore.page !==
        buildingOneStaffMovementsStore.prevPage
    ) {
      buildingOneStaffMovementsStore.getMoreStaffMovements();
    }
  }, [
    buildingOneStaffMovementsStore.page,
    buildingOneStaffMovementsStore.maxPage
  ]);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>
          {buildingOneStaffMovementsStore.selectedBuilding["title"]}
        </p>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Formik
            initialValues={{
              searchBuildingMovementsValue:
                buildingOneStaffMovementsStore.searchValue || ""
            }}
            onSubmit={() => null}
          >
            {({ values, setFieldValue }) => {
              const handleFindData = () => {
                buildingOneStaffMovementsStore.setSearchValue(
                  values.searchBuildingMovementsValue
                );
                buildingOneStaffMovementsStore.getStaffMovementsList();
              };

              const handleOnChange = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                setFieldValue("searchBuildingMovementsValue", e.target.value);
                buildingOneStaffMovementsStore.setSearchValue(e.target.value);
              };

              return (
                <SearchInput
                  name="searchBuildingMovementsValue"
                  inputRef={inputRef}
                  onChange={handleOnChange}
                  handleFindData={handleFindData}
                  blurCondition={
                    buildingOneStaffMovementsStore.isLoading ||
                    modalWindowsStore.isErrorWindow
                  }
                  clearSearch={() => {
                    setFieldValue("searchBuildingMovementsValue", "");
                    buildingOneStaffMovementsStore.setSearchValue("");
                    buildingOneStaffMovementsStore.getStaffMovementsList();
                  }}
                />
              );
            }}
          </Formik>
        </ErrorBoundary>
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BuildingOneStaffMovementsTable />
      </ErrorBoundary>
    </>
  );
};

export default observer(BuildingOneStaffMovements);
