import styles from "./buildingOneTimesheetChart.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { useParams } from "react-router-dom";
import LoadedComponent from "widgets/LoadedComponent";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const BuildingOneTimesheeChart = () => {
  const { buildingOneTimesheetStore } = useStores();

  const { id } = useParams();

  const CustomTooltip = ({
    active,
    payload
  }: {
    active?: string;
    payload?: { staff: string; hours: string }[];
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.tooltip}>
          <p>{getFormattedDate(payload[0]["payload"]["date"])}</p>
          <div>
            <div
              style={{
                backgroundColor: "#F77990"
              }}
            />
            <p>Часы: {payload[0]["payload"]["hours"]}</p>
          </div>
          <div>
            <div
              style={{
                backgroundColor: "#707AD3"
              }}
            />
            <p>Сотрудники: {payload[0]["payload"]["staff"]}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.loading}>
      <LoadedComponent
        isLoading={buildingOneTimesheetStore.isLoadingForChart}
        error={buildingOneTimesheetStore.error[id]}
        withoutText
      >
        {buildingOneTimesheetStore.allOpenedTabelCharts[id]?.[
          buildingOneTimesheetStore.month[id]
        ]?.length ? (
          <div className={styles.chart}>
            <div className={styles.chart__row}>
              <div />
              <p>Сотрудники</p>
              <div />
              <p>Часы</p>
            </div>
            <div className={styles.chart__main}>
              <ResponsiveContainer width="80%" height="80%">
                <AreaChart
                  data={
                    buildingOneTimesheetStore.allOpenedTabelCharts[id]?.[
                      buildingOneTimesheetStore.month[id]
                    ]
                  }
                  margin={{
                    top: 10,
                    right: 10,
                    left: -25,
                    bottom: 0
                  }}
                >
                  <Tooltip content={<CustomTooltip />} />

                  <CartesianGrid vertical={false} stroke="#F3F3F3" />
                  <XAxis
                    dataKey="day"
                    tickLine={false}
                    tick={{
                      fontWeight: "400",
                      fontSize: "12px"
                    }}
                    axisLine={{
                      stroke: "#F3F3F3"
                    }}
                  />

                  <YAxis
                    interval={0}
                    tickLine={false}
                    tick={{
                      fontWeight: "400",
                      fontSize: "12px"
                    }}
                    axisLine={{
                      stroke: "#F3F3F3"
                    }}
                  />

                  <Area
                    type="monotone"
                    dataKey="hours"
                    stackId="1"
                    stroke="#f7788f"
                    fill="#f77990"
                    dot={{
                      stroke: "#f77990",
                      strokeWidth: 5
                    }}
                    activeDot={{ stroke: "#000", strokeWidth: 1, r: 5 }}
                  />
                  <Area
                    type="monotone"
                    dataKey="staff"
                    stackId="2"
                    stroke="#707ad3"
                    fill="#707ad3"
                    dot={{
                      stroke: "#707ad3",
                      strokeWidth: 5
                    }}
                    activeDot={{ stroke: "#000", strokeWidth: 1, r: 5 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={styles.withoutChartsData}>
            Нет данных о сотрудниках
          </div>
        )}
      </LoadedComponent>
    </div>
  );
};

export default observer(BuildingOneTimesheeChart);
