import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "shared/ui/PageTitle";
import LoadedComponent from "widgets/LoadedComponent";
import styles from "./friendInviteOneHistory.module.scss";
import FriendInviteOneHistoryOne from "./FriendInviteOneHistoryOne";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const FriendInviteOneHistory = () => {
  const { menuStore, friendInviteStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    menuStore.setOpenedModule("friendInviteOne");
    menuStore.setOpenedSubmodule("history");
  }, []);

  useEffect(() => {
    menuStore.sendTabId(id);
    friendInviteStore.setSelectedOneInvite(id);
    menuStore.updateWindow({
      mainPath: `/friendinvite/id=${id}`,
      path: `/friendinvite/id=${id}/history`
    });
  }, [id]);
  return (
    <LoadedComponent
      isLoading={friendInviteStore.isLoading}
      error={friendInviteStore.error}
    >
      <>
        <PageTitle title="История" leftCol />
        {Object.values(friendInviteStore.history).length ? (
          <>
            {Object.values(friendInviteStore.history).map((historyGroup) => {
              const historyItems = Object.values(historyGroup);
              const { user_name, time, id } = Object.values(historyItems)[0];
              return (
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  key={`FriendInviteOneHistoryOne_${id}`}
                >
                  <FriendInviteOneHistoryOne
                    time={time}
                    user_name={user_name}
                    historyItems={historyItems}
                  />
                </ErrorBoundary>
              );
            })}
          </>
        ) : (
          <div className={styles.noHistory}>
            У сотрудника ещё нет истории приглашений
          </div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(FriendInviteOneHistory);
