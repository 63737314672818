import styles from "./buildingOneStaffMovementsTable.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores/index";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import LoadedComponent from "widgets/LoadedComponent";
import StatusIcon from "shared/ui/StatusIcon";
import BuildingOneStaffMovementsTransfer from "./BuildingOneStaffMovementsTransfer";
import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import { StaffListItem } from "stores/StaffModule/types/StaffListItem";

const titles = [
  "ТН",
  "ФИО",
  "Должность",
  "Текущий объект",
  "Текущий статус",
  "Отправка в трансфер"
];

const BuildingOneStaffMovementsTable = () => {
  const { buildingOneStaffMovementsStore, menuStore } = useStores();

  const navigate = useNavigate();
  const navigateUserProfile = (id: string) => {
    navigate(`/staff/id=${id}`);
    menuStore.setScrollPosition(menuStore.scroll);
  };

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      buildingOneStaffMovementsStore.maxPage >=
        buildingOneStaffMovementsStore.page &&
      buildingOneStaffMovementsStore.page ===
        buildingOneStaffMovementsStore.prevPage
    ) {
      buildingOneStaffMovementsStore.setPage(
        buildingOneStaffMovementsStore.page + 1
      );
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {buildingOneStaffMovementsStore.staffList[
        buildingOneStaffMovementsStore.selectedBuildingId
      ] !== undefined &&
      buildingOneStaffMovementsStore.staffList[
        buildingOneStaffMovementsStore.selectedBuildingId
      ] &&
      buildingOneStaffMovementsStore.currentTitles.length ? (
        <Table className={styles.table}>
          <thead className={menuStore.isScroll ? styles.shadow : ""}>
            <tr>
              {titles.map((title) => {
                return (
                  <th key={`th_${title}`}>
                    <div className="d-flex align-items-center">
                      <p className="m-0" key={`head_${title}`}>
                        {title}
                      </p>
                      <img src={iconArrowDouble} alt="sort" />
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {buildingOneStaffMovementsStore.staffList[
              buildingOneStaffMovementsStore.selectedBuildingId
            ].map((staff: StaffListItem) => {
              const isHaveCompany = Object.keys(staff.company).includes(
                buildingOneStaffMovementsStore.selectedBuildingCompany
              );
              return (
                <tr key={`td_${staff.id}`}>
                  {buildingOneStaffMovementsStore.currentTitles.map((title) => {
                    switch (title) {
                      case "uid":
                        return (
                          <td key={`date_${staff.id}`}>
                            <p>{staff[title]}</p>
                          </td>
                        );
                      case "fio":
                        return (
                          <td key={`link_${staff.id}`}>
                            <div
                              className={styles.candidate}
                              id={`BuildingOneStaffMovementsTable_showOneStaff_${staff["id"]}`}
                              onClick={() => navigateUserProfile(staff.id)}
                            >
                              <p className={styles.candidate}>
                                {staff.surname}
                              </p>
                            </div>
                          </td>
                        );
                      case "position":
                        return staff[title] && isHaveCompany ? (
                          <td key={`${staff.id}_${staff.uid}`}>
                            {Object.entries(staff[title]).map(
                              ([key, value]) => {
                                if (
                                  buildingOneStaffMovementsStore.selectedBuildingCompany ===
                                  key
                                )
                                  return (
                                    <p key={`${staff.id}_${key}`}>{value}</p>
                                  );
                              }
                            )}
                          </td>
                        ) : (
                          <td key={`${staff.id}_${staff.uid}`}></td>
                        );
                      case "building":
                        return staff[title] && isHaveCompany ? (
                          <td key={`building_${staff.id}`}>
                            {Object.entries(staff[title]).map(
                              ([key, value]) => {
                                if (
                                  buildingOneStaffMovementsStore.selectedBuildingCompany ===
                                  key
                                )
                                  return (
                                    <p key={`${staff.id}_${key}`}>
                                      <Link
                                        className={styles.buildingLink}
                                        id={`BuildingOneStaffMovementsTable_buildingLink_${value.id}`}
                                        to={`/building/id=${value.id}`}
                                        target="_blank"
                                      >
                                        {value.title}
                                      </Link>
                                    </p>
                                  );
                              }
                            )}
                          </td>
                        ) : (
                          <td key={`zero_${staff.id}`}>
                            <p>Сотрудника не было ни на одном объекте</p>
                          </td>
                        );
                      case "event_type":
                        return staff[title] && isHaveCompany ? (
                          <td key={staff.id}>
                            {Object.entries(staff[title]).map(
                              ([key, value]) => {
                                if (
                                  buildingOneStaffMovementsStore.selectedBuildingCompany ===
                                  key
                                )
                                  return (
                                    <div
                                      className={styles.status}
                                      key={`${staff.id}_${key}`}
                                    >
                                      <StatusIcon
                                        icon={value.icon}
                                        color={value.color}
                                      />
                                      <p>{value.title}</p>
                                    </div>
                                  );
                              }
                            )}
                          </td>
                        ) : (
                          <td key={`building_${staff.id}`}></td>
                        );
                      case "date_transfer":
                        return isHaveCompany ? (
                          <td key={`transfer_${staff.id}`}>
                            {Object.values(staff["building"]).map(
                              (building) =>
                                staff["event_type"] &&
                                Object.entries(staff["event_type"]).map(
                                  ([key, value]) => {
                                    const status =
                                      buildingOneStaffMovementsStore.statusList[
                                        value.id_event_type
                                      ];
                                    if (
                                      buildingOneStaffMovementsStore.selectedBuildingCompany ===
                                        key &&
                                      buildingOneStaffMovementsStore
                                        .selectedBuilding.title ===
                                        building.title
                                    ) {
                                      return status?.custom
                                        ?.show_in_employee_transfers ? (
                                        <BuildingOneStaffMovementsTransfer
                                          uid={staff.id}
                                          key={staff.id}
                                        />
                                      ) : (
                                        buildingOneStaffMovementsStore
                                          .statusesCurrentObject[status.id]
                                      );
                                    }
                                  }
                                )
                            )}
                          </td>
                        ) : (
                          <td key={staff.id}>
                            <p>Сотрудника нет в компании объекта</p>
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className={styles.withoutStaffMovements}>
          На данном объекте нет ни одного сотрудника в статусах Отпуск или
          Уволен
        </div>
      )}
      {buildingOneStaffMovementsStore.page !== 1 ? (
        <div className={styles.firstCol}>
          {buildingOneStaffMovementsStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <LoadedComponent
          isLoading={buildingOneStaffMovementsStore.isLoading}
          errorMessage={buildingOneStaffMovementsStore.errorMessage}
          actionButton={buildingOneStaffMovementsStore.actionButton}
        />
      )}
    </>
  );
};

export default observer(BuildingOneStaffMovementsTable);
