import styles from "./buildingOneTimesheetInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";

import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import BuildingOneTimesheetInfoHeader from "./BuildingOneTimesheetInfoHeader";
import BuildingOneTimesheetInfoCell from "./BuildingOneTimesheetInfoCell";
import LoadedComponent from "widgets/LoadedComponent";
import { Tabel } from "stores/BuildingModule/types/Tabel";
import { getMonth, parse } from "date-fns";

const BuildingOneTimesheetInfo = () => {
  const { id } = useParams();
  const { buildingOneTimesheetStore } = useStores();

  const [foundedStaff, setFoundedStaff] = useState<Tabel["staff_list"]>({});

  useEffect(() => {
    if (buildingOneTimesheetStore.searchValue[id]?.length) {
      const foundedStaffList: Tabel["staff_list"] = {};

      Object.entries(
        buildingOneTimesheetStore.allOpenedTabels[id].staff_list
      ).forEach(([staff_id, staff]) => {
        if (
          staff.name
            .toLowerCase()
            .includes(
              buildingOneTimesheetStore.searchValue[id]?.toLowerCase()
            ) ||
          `${staff.uid}`.includes(
            buildingOneTimesheetStore.searchValue[id]?.toLowerCase()
          )
        ) {
          if (
            (buildingOneTimesheetStore.selectedStaffFilter === "itr" &&
              staff.itr) ||
            (buildingOneTimesheetStore.selectedStaffFilter === "non_itr" &&
              !staff.itr) ||
            buildingOneTimesheetStore.selectedStaffFilter === "all"
          )
            foundedStaffList[staff_id] = staff;
        }
      });

      setFoundedStaff(foundedStaffList);
    } else {
      setFoundedStaff({});
    }
  }, [
    buildingOneTimesheetStore.searchValue[id],
    buildingOneTimesheetStore.selectedStaffFilter
  ]);

  // функция проверки текущего месяца
  // равен ли выбранный месяц (month[id]) месяцу из данных табеля (ws_list)
  // нужна для того, чтобы отображать лоадер при быстром переключении месяцев вместо прочерков в таблице
  const checkCurrentMonth = () => {
    if (
      buildingOneTimesheetStore.allOpenedTabels[id]?.ws_list &&
      Object.keys(buildingOneTimesheetStore.allOpenedTabels[id].ws_list).length
    ) {
      const savedMonth =
        getMonth(
          parse(
            Object.keys(
              buildingOneTimesheetStore.allOpenedTabels[id].ws_list
            )[0],
            "yyyy-MM-dd",
            new Date()
          )
        ) + 1;
      return savedMonth !== buildingOneTimesheetStore.month[id] ? true : false;
    }
  };

  return (
    <div className={styles.loading}>
      <LoadedComponent
        isLoading={
          buildingOneTimesheetStore.isLoadingForTabel || checkCurrentMonth()
        }
      >
        <div className={styles.timesheet}>
          <Table className={styles.table}>
            <BuildingOneTimesheetInfoHeader />
            <tbody>
              <>
                {(buildingOneTimesheetStore.searchValue[id]?.length &&
                  Object.values(foundedStaff).length) ||
                (!buildingOneTimesheetStore.searchValue[id]?.length &&
                  buildingOneTimesheetStore.allOpenedTabels[id]?.staff_list &&
                  Object.values(
                    buildingOneTimesheetStore.allOpenedTabels[id].staff_list
                  ).length) ? (
                  Object.entries(
                    buildingOneTimesheetStore.searchValue[id]?.length
                      ? foundedStaff
                      : buildingOneTimesheetStore.allOpenedTabels[id].staff_list
                  ).map(([staff_id, staff]) => {
                    return (
                      <tr
                        key={`staff_${staff_id}`}
                        className={
                          (buildingOneTimesheetStore.selectedStaffFilter ===
                            "itr" &&
                            !staff.itr) ||
                          (buildingOneTimesheetStore.selectedStaffFilter ===
                            "non_itr" &&
                            staff.itr)
                            ? styles.hide
                            : ""
                        }
                      >
                        <td className={styles.tn}>{staff.uid}</td>
                        <td className={styles.fio}>
                          <div>
                            <Link
                              to={`/staff/id=${staff_id}`}
                              id={`BuildingOneTimesheetInfo_${staff_id}`}
                              target="_blank"
                            >
                              {staff.name}
                            </Link>
                          </div>
                        </td>
                        <BuildingOneTimesheetInfoCell staff_id={staff_id} />
                        <td className={styles.hours}>{staff.total_hours}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className={styles.notFound}>Ничего не найдено</td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </div>
      </LoadedComponent>
    </div>
  );
};

export default observer(BuildingOneTimesheetInfo);
