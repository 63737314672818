import styles from "./searchInput.module.scss";
import { useState } from "react";
import { useField, useFormikContext } from "formik";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconClose.svg";
import { ReactComponent as IconSearch } from "shared/assets/images/mainIcons/iconSearch.svg";
import { classNames } from "shared/utils/helpers/classNames";

export type SearchInputProps = {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  clearSearch?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  handleFindData?: () => void;
  blurCondition?: boolean;
  autocomplete?: "off" | "on";
  fromList?: boolean;
  withoutBtn?: boolean;
  pattern?: (value: string) => string;
};

const SearchInput = ({
  name,
  onChange,
  placeholder,
  clearSearch,
  inputRef,
  handleFindData,
  blurCondition,
  autocomplete = "off",
  fromList,
  withoutBtn,
  pattern
}: SearchInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const { setFieldTouched } = useFormikContext();
  const [field, meta, { setValue }] = useField(name);
  const hasError = !!meta.error;

  const handleOnFocusInput = () => setIsFocused(true);

  const handleOnClickClearBtn = () => {
    if (clearSearch) clearSearch();
    setValue("");
    setFieldTouched(name, true, false);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (!withoutBtn && event.key === "Enter" && !meta.error && handleFindData) {
      handleFindData();
    } else if (event.key === "Escape") {
      handleOnClickClearBtn();
    }
    if (blurCondition && inputRef?.current) {
      inputRef.current.blur();
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    setTimeout(() => setIsFocused(false), 200);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = pattern
      ? pattern(event.target.value)
      : event.target.value;
    setValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <div
      className={styles.searchWrapper}
      data-type={fromList ? "list-search" : ""}
    >
      <IconSearch
        className={classNames(styles.iconSearch, {
          [styles.iconSearchActive]: isFocused || !!field.value
        })}
        id="Searchinput_findByIconSearch"
        data-type={fromList ? "list-search" : ""}
      />
      <input
        {...field}
        ref={inputRef}
        className={classNames(styles.search, {
          [styles.inputError]: hasError,
          [styles.searchWithButtons]: !withoutBtn
        })}
        type="text"
        placeholder={placeholder || "Быстрый поиск"}
        value={field.value ?? ""}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onFocus={handleOnFocusInput}
        autoComplete={autocomplete || "off"}
        onBlur={handleOnBlur}
        data-type={fromList ? "list-search" : "search"}
      />
      {(isFocused || !!field.value) && (
        <div
          className={classNames(styles.btnsGroup, {
            [styles.btnsGroupVisible]: isFocused || !!field.value
          })}
          data-type={fromList ? "list-search" : ""}
        >
          {isFocused && !withoutBtn && (
            <button
              className={classNames(styles.findBtn, {
                [styles.disabled]: hasError || !field.value
              })}
              type="button"
              id="Searchinput_findData"
              onClick={field.value && !hasError ? handleFindData : undefined}
              disabled={hasError || !field.value}
              data-type={fromList ? "list-search" : ""}
            >
              Найти
            </button>
          )}
          <IconClose
            className={styles.closeBtn}
            id="Searchinput_clearSearch"
            onClick={handleOnClickClearBtn}
            data-type={fromList ? "list-search" : ""}
          />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
