import styles from "./staffOneInfoWorkedShifts.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import MidTable from "shared/ui/Table/MidTable";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import { useParams } from "react-router-dom";
import LoadedComponent from "widgets/LoadedComponent";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const StaffOneInfoWorkedShifts = () => {
  const { staffOneStore } = useStores();

  const { id } = useParams();

  const [activeTab, setActiveTab] = useState("");
  let tableDate = [];

  useEffect(() => {
    !(id in staffOneStore.workshift) && staffOneStore.getWorkshiftWidget(id);
  }, [id]);

  useEffect(() => {
    setActiveTab(staffOneStore.selectedCompany);
  }, [staffOneStore.selectedCompany]);

  const getWorkshifts = () => {
    staffOneStore.workshift[id]?.[activeTab]
      ? Object.values(staffOneStore.workshift[id][activeTab])
          .slice(0, 3)
          .map((watch) => {
            tableDate.push({
              id: watch.id,
              start_date: getFormattedDate(String(watch.start_date)),
              position_title: watch.position_title || "Должность не указана",
              workshifts: watch.workshifts.toString()
            });
          })
      : (tableDate = null);

    return (
      <MidTable
        titles={["Дата начала", "Должность", "Смены"]}
        tableRowData={tableDate}
        textAlign={{ workshifts: "right" }}
        fixWidth={{ start_date: "125px", position_title: "455px" }}
        defaultMsg={() => {
          return (
            <div className={styles.withoutShifts}>
              {`Не найдена история должностей в компании ${
                staffOneStore.companiesData[activeTab]?.title || ""
              }`}
            </div>
          );
        }}
        tableFooterData={
          staffOneStore.workshift[id]?.[activeTab] && {
            totalText: `Всего смен в ${staffOneStore.companiesData[activeTab].title}`,
            totalSum: (
              <div className={styles.totalSum}>
                {staffOneStore.workshift[id]?.total[activeTab]}
              </div>
            )
          }
        }
      />
    );
  };

  return (
    <LoadedComponent
      // лоадер staffOneStore.isLoadingForWidgetMetrics[id] нужен для того, чтобы корректно отобразились табы по компаниям
      // применять во всех виджетах, где используются табы по компаниям
      isLoading={
        staffOneStore.isLoadingForWidget_workshift[id] ||
        staffOneStore.isLoadingForWidgetMetrics[id]
      }
      withoutText
    >
      <>
        <div className="position-relative">
          {staffOneStore.workshift[id]?.[activeTab] && (
            <div className={styles.shiftsTotal}>
              Всего отработано смен:
              <span className={styles.shiftsTotalNum}>
                {staffOneStore.workshift[id]?.total.count_work_all}
              </span>
            </div>
          )}
          {staffOneStore.companiesTabs.length ? (
            <SwitchedTabs
              isClippedTabs={staffOneStore.companiesTabs.length > 2}
              tabs={staffOneStore.companiesTabs}
              changeActiveTab={setActiveTab}
              selectedTab={activeTab}
            />
          ) : (
            ""
          )}
        </div>
        {getWorkshifts()}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoWorkedShifts);
