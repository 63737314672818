import styles from "./staffOneEditFormWindow.module.scss";
import { observer } from "mobx-react-lite";
import { OverlayTrigger, Popover } from "react-bootstrap";
import StaffOneEditFormObjectVersionWindow from "../StaffOneEditFormObjectVersionWindow";

type StaffOneEditFormWindowProps = {
  show: boolean;
  field: string;
  title: string;
  setOpenWindow: () => void;
};

const StaffOneEditFormWindow = ({
  show,
  field,
  title,
  setOpenWindow
}: StaffOneEditFormWindowProps) => {
  return (
    <OverlayTrigger
      trigger="focus"
      defaultShow={show}
      show={show}
      placement="right"
      overlay={
        <Popover
          onClick={(event) => {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
          }}
          className={styles.window}
        >
          <Popover.Body className={styles.form}>
            {show && (
              <StaffOneEditFormObjectVersionWindow
                field={field}
                setOpenWindow={setOpenWindow}
                title={title}
              />
            )}
          </Popover.Body>
        </Popover>
      }
    >
      {({ ref, ...triggerHandler }) => {
        return (
          <div
            onClick={(event) => {
              event.stopPropagation();
              event.nativeEvent.stopImmediatePropagation();
            }}
            id="StaffOneEditFormWindow_objectVersionWindow"
            data-window={true}
            {...triggerHandler}
          >
            <div ref={ref} />
          </div>
        );
      }}
    </OverlayTrigger>
  );
};

export default observer(StaffOneEditFormWindow);
