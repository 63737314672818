import { observer } from "mobx-react-lite";
import styles from "./windowBody.module.scss";
import { fileUrl } from "stores/utils/consts";
import { useStores } from "stores";
import { Award } from "stores/StaffModule/types/Award";
import { useState } from "react";
import awardDefault from "shared/assets/images/mainIcons/awardDefault.png";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { getKeys } from "shared/utils/helpers/getKeys";
import { ButtonNext } from "shared/ui/ButtonNext";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";
import ErrorMessage from "shared/ui/ErrorMessage";

type WindowBodyProps = {
  currentAward: Award;
  withoutAwards: boolean;
  changeAward: boolean;
};

const WindowBody = ({
  currentAward,
  withoutAwards,
  changeAward
}: WindowBodyProps) => {
  const { staffOneAwardsStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");

  const changeOpenedWindows = (name: string) => {
    openedListName !== name ? setOpenedListName(name) : setOpenedListName(null);
  };

  const showPrevAward = () => {
    const index = getKeys(staffOneAwardsStore.awards).indexOf(
      staffOneAwardsStore.openedAward
    );
    if (index !== 0 && !withoutAwards) {
      staffOneAwardsStore.setOpenedAward(
        getKeys(staffOneAwardsStore.awards)[index - 1]
      );
      staffOneAwardsStore.setIsActiveButton(
        staffOneAwardsStore.awardGroups[
          staffOneAwardsStore.awards[staffOneAwardsStore.openedAward].custom
            ?.group
        ]
      );
    }
  };

  const showNextAward = () => {
    const index = getKeys(staffOneAwardsStore.awards).indexOf(
      staffOneAwardsStore.openedAward
    );
    if (
      index !== getKeys(staffOneAwardsStore.awards).length - 1 &&
      !withoutAwards
    ) {
      staffOneAwardsStore.setOpenedAward(
        getKeys(staffOneAwardsStore.awards)[index + 1]
      );
      staffOneAwardsStore.setIsActiveButton(
        staffOneAwardsStore.awardGroups[
          staffOneAwardsStore.awards[staffOneAwardsStore.openedAward].custom
            ?.group
        ]
      );
    }
  };

  return (
    <>
      <div className={styles.children}>
        <div className={styles.awardContainer}>
          {withoutAwards ? (
            <div className={styles.noAwards}>
              {`У сотрудника нет наград за ${staffOneAwardsStore.isActiveButton.title}`}
            </div>
          ) : (
            <div className={styles.aboutAward}>
              {currentAward?.custom?.autoset === "platform" && (
                <div className={styles.awardDetails}>
                  <p className={styles.title}>
                    {staffOneAwardsStore.cols.platform.title}
                  </p>
                  {currentAward.platform_title}
                </div>
              )}

              <div className={styles.awardDetails}>
                <p className={styles.title}>
                  {staffOneAwardsStore.cols.type.title}
                </p>
                {currentAward.type_title}
              </div>
              <div className={styles.awardDetails}>
                <p className={styles.title}>
                  {staffOneAwardsStore.cols.author.title}
                </p>
                {currentAward.author}
              </div>
              {changeAward ? (
                <DatePickerField
                  name="award_start"
                  title="Дата выдачи"
                  isCalendarOpened={openedListName === "award_start"}
                  setIsCalendarOpened={() => {
                    changeOpenedWindows("award_start");
                  }}
                  placeholderVisible
                  onChange={() => staffOneAwardsStore.clearErrorsMessage()}
                />
              ) : (
                <div className={styles.awardDetails}>
                  <p className={styles.title}>Дата выдачи</p>
                  {getFormattedDate(currentAward.award_start)}
                </div>
              )}
            </div>
          )}
        </div>
        <ButtonNext
          disabled={
            getKeys(staffOneAwardsStore.awards).filter((id, index) => {
              return id === staffOneAwardsStore.openedAward && index !== 0;
            })?.length === 0 || withoutAwards
          }
          leftDirection
          className={classNames("", {
            [styles.invisible]: changeAward
          })}
          onClick={showPrevAward}
        />
        <div className={styles.imageContainer}>
          {withoutAwards ? (
            <img src={awardDefault} className={styles.image} />
          ) : (
            <img
              src={(fileUrl + currentAward.img_src) as string}
              className={styles.image}
            />
          )}
          <div className={styles.awardTitle}>
            {!withoutAwards && currentAward.type_title}
          </div>
        </div>
        <ButtonNext
          disabled={
            getKeys(staffOneAwardsStore.awards).filter((id, index) => {
              return (
                id === staffOneAwardsStore.openedAward &&
                index === getKeys(staffOneAwardsStore.awards).length - 1
              );
            })?.length > 0 || withoutAwards
          }
          className={classNames("", {
            [styles.invisible]: changeAward
          })}
          onClick={showNextAward}
        />
      </div>
      {!isEmpty(staffOneAwardsStore.errorsMessage) ? (
        <ErrorMessage errors={staffOneAwardsStore.errorsMessage} />
      ) : null}
    </>
  );
};
export default observer(WindowBody);
