import styles from "./aregisterOnePresave.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { Table } from "react-bootstrap";

import { ReactComponent as IconCheck } from "shared/assets/images/iconStatus/BigCheck.svg";
import { getEntries } from "shared/utils/helpers/getEntries";
import { isEmpty } from "lodash";

const currentTitles = ["internal_num", "date_report", "presave"];

const tableCols = {
  internal_num: {
    title: "Временный номер"
  },
  date_report: {
    title: "Дата"
  },
  presave: {
    title: "Статус"
  }
};

const AregisterOnePresave = () => {
  const { id } = useParams();
  const { menuStore, aregisterOneStore } = useStores();

  useEffect(() => {
    aregisterOneStore.setSelectedAregister(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("aregisterOne");
    menuStore.setOpenedSubmodule("aregisterOnePresave");
    menuStore.updateWindow({
      mainPath: `/aregister/id=${id}`,
      path: `/aregister/id=${id}/presaves`
    });
  }, [id]);

  return !isEmpty(aregisterOneStore.presaves[id]) ? (
    <Table className={styles.table}>
      <thead>
        <tr>
          {currentTitles.map((title) => {
            return (
              <th key={title}>
                <div>{tableCols[title].title}</div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {getEntries(aregisterOneStore.presaves[id]).map(
          ([presave_id, presave]) => {
            return (
              <tr key={presave_id}>
                {currentTitles.map((title) => {
                  switch (title) {
                    case "internal_num":
                      return (
                        <td key={`${presave_id}-${title}`}>
                          <div className="d-flex flex-column gap-2">
                            <p>{presave.title || "Без названия"}</p>
                            <p
                              className={styles.presaveId}
                            >{`id: ${presave_id}`}</p>
                          </div>
                        </td>
                      );
                    case "date_report":
                      return (
                        <td key={`${presave_id}-${title}`}>{presave.date}</td>
                      );
                    case "presave":
                      return (
                        <td key={`${presave_id}-${title}`}>
                          <IconCheck className={styles.iconCheck} />
                        </td>
                      );
                    default:
                      return (
                        <td key={`${presave_id}-${title}`}>{presave[title]}</td>
                      );
                  }
                })}
              </tr>
            );
          }
        )}
      </tbody>
    </Table>
  ) : (
    <div className={styles.withoutData}>
      К заявке не привязана ни одна почасовка
    </div>
  );
  return null;
};

export default observer(AregisterOnePresave);
