import styles from "./salaryPaymentListOneInfoCard.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import iconTurn from "shared/assets/images/mainIcons/iconTurn.svg";

import ErrorMessage from "shared/ui/ErrorMessage";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonTheme } from "shared/ui/Button";

type SalaryPaymentListOneInfoCardProps = {
  getStatusIcon: (status_id: string) => JSX.Element;
};

const SalaryPaymentListOneInfoCard = ({
  getStatusIcon
}: SalaryPaymentListOneInfoCardProps) => {
  const { id } = useParams();
  const { salaryPaymentlistOneStore } = useStores();

  return (
    <div className={styles.body}>
      <div className={styles.body__firstColumn}>
        {Object.values(salaryPaymentlistOneStore.paymentCols).map((field) => {
          if (
            salaryPaymentlistOneStore.paymentParams[field.newname]?.access_show
          ) {
            switch (field.newname) {
              case "uid":
                return (
                  <div className={styles.row} key={field.newname}>
                    <div className={styles.field}>
                      <p className={styles.field__title}>{field.title}</p>
                      <p className={styles.field__value}>
                        {
                          salaryPaymentlistOneStore.selectedOnePayment[
                            field.newname
                          ]
                        }
                      </p>
                    </div>
                    {salaryPaymentlistOneStore.selectedOnePayment.staff?.id &&
                    salaryPaymentlistOneStore.paymentParams.date_start
                      ?.access_show ? (
                      <div className={styles.field}>
                        <p className={styles.field__title}>Дата ведомости</p>
                        <p className={styles.field__value}>
                          {
                            salaryPaymentlistOneStore.selectedOnePayment
                              .date_start_formatted
                          }
                        </p>
                      </div>
                    ) : (
                      <>
                        {salaryPaymentlistOneStore.paymentParams.time_create
                          ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>
                              {
                                salaryPaymentlistOneStore.paymentCols
                                  .time_create.title
                              }
                            </p>
                            <p className={styles.field__value}>
                              {salaryPaymentlistOneStore.selectedOnePayment.time_create_formatted?.slice(
                                0,
                                10
                              )}
                            </p>
                          </div>
                        ) : null}
                        {salaryPaymentlistOneStore.paymentParams.date_start
                          ?.access_show &&
                        salaryPaymentlistOneStore.paymentParams.date_end
                          ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>Период</p>
                            <p className={styles.field__value}>
                              {`${salaryPaymentlistOneStore.selectedOnePayment.date_start_formatted} - ${salaryPaymentlistOneStore.selectedOnePayment.date_end_formatted}`}
                            </p>
                          </div>
                        ) : salaryPaymentlistOneStore.paymentParams.date_start
                            ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>
                              {
                                salaryPaymentlistOneStore.paymentCols.date_start
                                  .title
                              }
                            </p>
                            <p className={styles.field__value}>
                              {
                                salaryPaymentlistOneStore.selectedOnePayment
                                  .date_start_formatted
                              }
                            </p>
                          </div>
                        ) : salaryPaymentlistOneStore.paymentParams.date_end
                            ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>
                              {
                                salaryPaymentlistOneStore.paymentCols.date_end
                                  .title
                              }
                            </p>
                            <p className={styles.field__value}>
                              {
                                salaryPaymentlistOneStore.selectedOnePayment
                                  .date_end_formatted
                              }
                            </p>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                );
              case "date_start":
                if (salaryPaymentlistOneStore.paymentParams.uid.access_show) {
                  return;
                }

                return (
                  <div className={styles.row} key={field.newname}>
                    {salaryPaymentlistOneStore.selectedOnePayment.staff?.id ? (
                      <div className={styles.field}>
                        <p className={styles.field__title}>Дата ведомости</p>
                        <p className={styles.field__value}>
                          {
                            salaryPaymentlistOneStore.selectedOnePayment
                              .date_start_formatted
                          }
                        </p>
                      </div>
                    ) : (
                      <>
                        {salaryPaymentlistOneStore.paymentParams.time_create
                          ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>
                              {
                                salaryPaymentlistOneStore.paymentCols
                                  .time_create.title
                              }
                            </p>
                            <p className={styles.field__value}>
                              {salaryPaymentlistOneStore.selectedOnePayment.time_create_formatted?.slice(
                                0,
                                10
                              )}
                            </p>
                          </div>
                        ) : null}
                        {salaryPaymentlistOneStore.paymentParams.date_start
                          ?.access_show &&
                        salaryPaymentlistOneStore.paymentParams.date_end
                          ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>Период</p>
                            <p className={styles.field__value}>
                              {`${salaryPaymentlistOneStore.selectedOnePayment.date_start_formatted} - ${salaryPaymentlistOneStore.selectedOnePayment.date_end_formatted}`}
                            </p>
                          </div>
                        ) : salaryPaymentlistOneStore.paymentParams.date_start
                            ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>
                              {
                                salaryPaymentlistOneStore.paymentCols.date_start
                                  .title
                              }
                            </p>
                            <p className={styles.field__value}>
                              {
                                salaryPaymentlistOneStore.selectedOnePayment
                                  .date_start_formatted
                              }
                            </p>
                          </div>
                        ) : salaryPaymentlistOneStore.paymentParams.date_end
                            ?.access_show ? (
                          <div className={styles.field}>
                            <p className={styles.field__title}>
                              {
                                salaryPaymentlistOneStore.paymentCols.date_end
                                  .title
                              }
                            </p>
                            <p className={styles.field__value}>
                              {
                                salaryPaymentlistOneStore.selectedOnePayment
                                  .date_end_formatted
                              }
                            </p>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                );
              case "date_end":
                if (
                  salaryPaymentlistOneStore.paymentParams.uid.access_show ||
                  salaryPaymentlistOneStore.paymentParams.date_start.access_show
                ) {
                  return;
                }
                return (
                  <div className={styles.row} key={field.newname}>
                    {salaryPaymentlistOneStore.paymentParams.time_create
                      ?.access_show ? (
                      <div className={styles.field}>
                        <p className={styles.field__title}>
                          {
                            salaryPaymentlistOneStore.paymentCols.time_create
                              .title
                          }
                        </p>
                        <p className={styles.field__value}>
                          {salaryPaymentlistOneStore.selectedOnePayment.time_create_formatted?.slice(
                            0,
                            10
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={styles.field}>
                      <p className={styles.field__title}>
                        {salaryPaymentlistOneStore.paymentCols.date_end.title}
                      </p>
                      <p className={styles.field__value}>
                        {
                          salaryPaymentlistOneStore.selectedOnePayment
                            .date_end_formatted
                        }
                      </p>
                    </div>
                  </div>
                );
              case "time_create":
                if (
                  salaryPaymentlistOneStore.paymentParams.uid.access_show ||
                  salaryPaymentlistOneStore.paymentParams.date_start
                    .access_show ||
                  salaryPaymentlistOneStore.paymentParams.date_end.access_show
                ) {
                  return;
                }
                return (
                  <div className={styles.row} key={field.newname}>
                    <div className={styles.field}>
                      <p className={styles.field__title}>{field.title}</p>
                      <p className={styles.field__value}>
                        {salaryPaymentlistOneStore.selectedOnePayment.time_create_formatted?.slice(
                          0,
                          10
                        )}
                      </p>
                    </div>
                  </div>
                );

              case "type":
                return (
                  <div className={styles.row} key={field.newname}>
                    <div className={styles.field}>
                      <p className={styles.field__title}>{field.title}</p>
                      <p className={styles.field__value}>
                        {
                          salaryPaymentlistOneStore.paymentTypes[
                            salaryPaymentlistOneStore.selectedOnePayment.type
                          ].title
                        }
                      </p>
                    </div>
                  </div>
                );

              case "author":
              case "made_payment":
                return (
                  <div className={styles.row} key={field.newname}>
                    <div className={styles.field}>
                      <p className={styles.field__title}>{field.title}</p>
                      <p className={styles.field__value}>
                        {salaryPaymentlistOneStore.selectedOnePayment[
                          field.newname
                        ]?.id
                          ? `${
                              salaryPaymentlistOneStore.selectedOnePayment[
                                field.newname
                              ].surname
                            } ${
                              salaryPaymentlistOneStore.selectedOnePayment[
                                field.newname
                              ].name
                            }${
                              salaryPaymentlistOneStore.selectedOnePayment[
                                field.newname
                              ].patronymic
                                ? " " +
                                  salaryPaymentlistOneStore.selectedOnePayment[
                                    field.newname
                                  ].patronymic
                                : ""
                            }`
                          : ""}
                      </p>
                    </div>
                  </div>
                );

              case "status":
                return (
                  <div className={styles.row_withBtns} key={field.newname}>
                    <div className={styles.field}>
                      <p className={styles.field__title}>{field.title}</p>
                      <p className={styles.field__value}>
                        {getStatusIcon(
                          salaryPaymentlistOneStore.selectedOnePayment.status
                        )}
                        {
                          salaryPaymentlistOneStore.paymentStatuses[
                            salaryPaymentlistOneStore.selectedOnePayment.status
                          ]?.title
                        }
                      </p>
                    </div>

                    <div className={styles.field__buttons}>
                      {(() => {
                        const accessStatuses: string[] = [];

                        Object.entries(
                          salaryPaymentlistOneStore.paymentStatuses
                        ).forEach(([status_id, { custom }]) => {
                          if (
                            custom.accessstatus &&
                            Object.values(custom.accessstatus).includes(
                              salaryPaymentlistOneStore.selectedOnePayment
                                .status
                            )
                          ) {
                            accessStatuses.push(status_id);
                          }
                        });

                        return accessStatuses.map((status_id) => {
                          return (
                            <Button
                              id="SalaryPaymentListOneInfoCard_changePaymentStatus"
                              theme={ButtonTheme.SECONDARY}
                              key={status_id}
                              className={
                                styles[
                                  `button_${salaryPaymentlistOneStore.paymentStatuses[status_id]?.custom.color}`
                                ]
                              }
                            >
                              {getStatusIcon(status_id)}
                              {
                                salaryPaymentlistOneStore.paymentStatuses[
                                  status_id
                                ]?.custom.btn_text
                              }
                            </Button>
                          );
                        });
                      })()}
                    </div>
                  </div>
                );
              case "id":
              case "object":
              case "staff":
              case "calc":
                return;

              default:
                return (
                  <div className={styles.row} key={field.newname}>
                    <div className={styles.field}>
                      <p className={styles.field__title}>{field.title}</p>
                      <p className={styles.field__value}>
                        {typeof salaryPaymentlistOneStore.selectedOnePayment[
                          field.newname
                        ] !== "object" ? (
                          field.type === "date" ||
                          field.type === "timestamp" ? (
                            getFormattedDate(
                              salaryPaymentlistOneStore.selectedOnePayment[
                                field.newname
                              ]
                            )
                          ) : (
                            salaryPaymentlistOneStore.selectedOnePayment[
                              field.newname
                            ]
                          )
                        ) : (
                          <br />
                        )}
                      </p>
                    </div>
                  </div>
                );
            }
          }
        })}
      </div>
      <div className={styles.body__secondColumn}>
        <div className={styles.body__secondColumn__buttons}>
          {salaryPaymentlistOneStore.paymentStatuses[
            salaryPaymentlistOneStore.selectedOnePayment.status
          ].custom.accessbutton &&
          Object.values(
            salaryPaymentlistOneStore.paymentStatuses[
              salaryPaymentlistOneStore.selectedOnePayment.status
            ].custom.accessbutton
          ).includes("calcSalary") ? (
            <Button
              id="SalaryPaymentListOneInfoCard_calcSalary"
              theme={ButtonTheme.SECONDARY}
            >
              <img src={iconTurn} />
              Пересчет денежных средств
            </Button>
          ) : (
            ""
          )}

          {salaryPaymentlistOneStore.paymentTypes[
            salaryPaymentlistOneStore.selectedOnePayment.type
          ].custom.recalculate &&
          !salaryPaymentlistOneStore.paymentStatuses[
            salaryPaymentlistOneStore.selectedOnePayment.status
          ].custom.done ? (
            <Button
              id="SalaryPaymentListOneInfoCard_reaculculate"
              theme={ButtonTheme.SECONDARY}
            >
              <img src={iconTurn} />
              Пересчет ведомости
            </Button>
          ) : (
            ""
          )}
        </div>

        {salaryPaymentlistOneStore.errorsMessage[id] &&
        Object.values(salaryPaymentlistOneStore.errorsMessage[id]).length
          ? Object.entries(salaryPaymentlistOneStore.errorsMessage[id]).map(
              ([error_id, error]) => {
                return <ErrorMessage errors={error} key={error_id} />;
              }
            )
          : null}
      </div>
    </div>
  );
};

export default observer(SalaryPaymentListOneInfoCard);
