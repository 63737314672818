import styles from "./aregisterFormPanel.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";

import Switch from "shared/ui/Inputs/Switch";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import ErrorMessage from "shared/ui/ErrorMessage";
import LoadedComponent from "widgets/LoadedComponent";

import { ReactComponent as IconCircle } from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { Formula } from "stores/AregisterModule/types/Formula";

type AregisterFormPanelProps = {
  type: string;
  checkSizeErrors: (formula: Partial<Formula>) => boolean;
};

const AregisterFormPanel = ({
  type,
  checkSizeErrors
}: AregisterFormPanelProps) => {
  const { menuStore, aregisterAppcreateStore } = useStores();
  const { dirty, isValid, handleSubmit, handleReset, values } =
    useFormikContext();
  const { id } = useParams();


  return (
    <div
      className={`${
        menuStore.scroll["top"] > 0.8
          ? styles.container_static
          : styles.container
      } ${
        aregisterAppcreateStore.errorsMessage[type === "add" ? type : id] &&
        Object.values(
          aregisterAppcreateStore.errorsMessage[type === "add" ? type : id]
        ).length
          ? styles.container_error
          : ""
      } 
      ${
        aregisterAppcreateStore.errorsMessage[type === "add" ? type : id] &&
        Object.values(
          aregisterAppcreateStore.errorsMessage[type === "add" ? type : id]
        ).length &&
        menuStore.scroll["top"] > 0.8
          ? styles.container_errorStatic
          : ""
      } 
      ${
        type === "edit" &&
        (!aregisterAppcreateStore.errorsMessage[id] ||
          !Object.values(aregisterAppcreateStore.errorsMessage[id]).length)
          ? styles.container_mini
          : ""
      }`}
    >
      {aregisterAppcreateStore.errorsMessage[type === "add" ? type : id] &&
      Object.values(
        aregisterAppcreateStore.errorsMessage[type === "add" ? type : id]
      ).length
        ? Object.entries(
            aregisterAppcreateStore.errorsMessage[type === "add" ? type : id]
          ).map(([error_name, error]) => {
            return (
              <div
                key={error_name}
                className={`${styles.error} ${
                  !error.result ? styles.error_withLink : ""
                }`}
              >
                <ErrorMessage
                  errors={error.message}
                  errorIcon={error.result ? <IconCircle /> : null}
                />
                <LoadedComponent
                  isLoading={aregisterAppcreateStore.isLoadingField.length > 0}
                />
              </div>
            );
          })
        : null}
      <div className={styles.buttons}>
        {type === "add" ? (
          <label className={styles.switch}>
            <Switch name="create_report" />
            Создать Отчёт мастера после сохранения
          </label>
        ) : null}
        <ButtonsGroupForSettings
          saveBtnTitle={
            type === "add" ? "Создать" : type === "edit" ? "Сохранить" : null
          }
          saveBtnDisabled={
            !isValid ||
            !dirty ||
            Boolean(
              aregisterAppcreateStore.errorsMessage[
                type === "add" ? type : id
              ] &&
                Object.values(
                  aregisterAppcreateStore.errorsMessage[
                    type === "add" ? type : id
                  ]
                ).length
            ) ||
            checkSizeErrors(
              aregisterAppcreateStore.formula[
                `${values["type"]}_${values["type_work"]}_${values["sub_work"]}_${values["species"]}`
              ]
            )
          }
          saveBtnOnClick={() => {
            handleSubmit();
            handleReset();
            aregisterAppcreateStore.deleteErrors(type === "add" ? type : id);
            aregisterAppcreateStore.setNewAregisterValues({});
            aregisterAppcreateStore.setOpenedAllAregisterValues(
              {},
              id,
              "delete"
            );
          }}
          addBtnTitle={type === "add" ? "Отмена" : null}
          addBtnDisabled={!dirty}
          addBtnOnClick={() => {
            handleReset();
            aregisterAppcreateStore.deleteErrors(type === "add" ? type : id);
            aregisterAppcreateStore.setNewAregisterValues({});
            aregisterAppcreateStore.setOpenedAllAregisterValues(
              {},
              id,
              "delete"
            );
          }}
          cancelBtnImg={<IconClose />}
          cancelBtnTitle={type === "edit" ? "Удалить заявку" : null}
        />
      </div>
    </div>
  );
};

export default observer(AregisterFormPanel);
