import styles from "./staffOneInfoStatuses.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from "stores/index";
import { useNavigate, useParams } from "react-router-dom";

// import { Link } from "react-router-dom";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import StatusIcon from "shared/ui/StatusIcon";
import StaffOneStatusesWindow from "../../StaffOneStatuses/StaffOneStatusesOnObject/StaffOneStatusesWindow";

import LoadedComponent from "widgets/LoadedComponent";
import { fileUrl } from "stores/utils/consts";
import { getUnits } from "shared/utils/helpers/getUnits";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { isEmpty } from "lodash";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { Status } from "stores/StaffModule/types/Status";

const StaffOneInfoStatuses = () => {
  const { staffOneStore, staffOneStatusesStore } = useStores();
  const [activeTab, setActiveTab] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(staffOneStore.selectedCompany);
  }, [staffOneStore.selectedCompany]);

  useEffect(() => {
    !(id in staffOneStore.building_staff_events) &&
      staffOneStore.getBuildingEventWidget(id);
    staffOneStatusesStore.setSelectedOneForStatuses(id, true);
  }, [id]);

  let building = "";

  const changeActiveTab = (id: string) => {
    setActiveTab(id);
    staffOneStore.setSelectedCompanyInStatus(id);
  };
  const handleOnButtonClick = () => {
    staffOneStatusesStore.setSelectedCompany(activeTab);
    setTimeout(() => {
      staffOneStatusesStore.setOpenedWindow("add");
    }, 200);
  };
  const buildingEvents = getValues(
    staffOneStore.building_staff_events[id]?.[activeTab]
  );

  const getStatusesForNotesFunc = () => {
    let futureStatus: Partial<Status> = {};
    let currentStatus: Partial<Status> = {};
    let firstPreviousStatus: Partial<Status> = {};
    buildingEvents.forEach((statuses) => {
      isEmpty(futureStatus) &&
        (futureStatus = getValues(statuses).find(
          (status) => status.future < 0
        ));
      isEmpty(currentStatus) &&
        (currentStatus = getValues(statuses).find(
          (status) => status.future === 0
        ));
      isEmpty(firstPreviousStatus) &&
        (firstPreviousStatus = getValues(statuses).find(
          (status) => status.future > 0
        ));
    });
    return { futureStatus, currentStatus, firstPreviousStatus };
  };

  return (
    <LoadedComponent
      // лоадер staffOneStore.isLoadingForWidgetMetrics[id] нужен для того, чтобы корректно отобразились табы по компаниям
      // применять во всех виджетах, где используются табы по компаниям
      isLoading={
        staffOneStore.isLoadingForWidget_building_staff_events[id] ||
        staffOneStore.isLoadingForWidgetMetrics[id]
      }
      withoutText
    >
      <>
        <div className={styles.tabs}>
          <SwitchedTabs
            tabs={staffOneStore.companiesTabs}
            changeActiveTab={changeActiveTab}
            selectedTab={activeTab}
          />
          <Button
            theme={ButtonTheme.CLEAR}
            onClick={handleOnButtonClick}
            id="StaffOneInfoStatuses_openStatusForm"
            data-notclickable={true}
          >
            Добавить статус
            <IconAdd />
          </Button>
        </div>
        {staffOneStore.selectedCompany.length ? (
          <>
            {staffOneStatusesStore.openedWindow === "add" && (
              <div className={styles.window}>
                <StaffOneStatusesWindow />
              </div>
            )}

            {!isEmpty(staffOneStore.building_staff_events[id]?.[activeTab]) ? (
              getValues(staffOneStore.building_staff_events[id][activeTab]).map(
                (item, buildingIndex) =>
                  buildingIndex < 2 &&
                  getValues(item).map((status, index) => {
                    const statusesForNotes = getStatusesForNotesFunc();
                    const isSubDate = Boolean(
                      staffOneStore.buildingEventsType[status.type]?.custom
                        .sub_date
                    );
                    const isSubVacation =
                      status.sub_start_date && status.sub_end_date && isSubDate;
                    // Проверяем совпадают ли полностью даты отпусков Ое и ОМ
                    const isSameDates =
                      isSubVacation &&
                      Boolean(
                        status.event_start === status.sub_start_date &&
                          status.event_end === status.sub_end_date
                      );
                    const statusTypeTitle = () => {
                      if (statusesForNotes.futureStatus?.id === status.id)
                        return "Следующий статус:";
                      else if (statusesForNotes.currentStatus?.id === status.id)
                        return "Текущий статус:";
                      else if (
                        statusesForNotes.firstPreviousStatus?.id === status.id
                      )
                        return "Предыдущие статусы:";
                    };

                    return (
                      <div
                        key={status.id}
                        className={classNames("", {
                          [styles.wrapperCurrent]:
                            statusesForNotes.currentStatus?.id === status.id,
                          [styles.wrapperFuture]:
                            statusesForNotes.futureStatus?.id === status.id,
                          [styles.wrapper]:
                            statusesForNotes.currentStatus?.id !== status.id &&
                            statusesForNotes.futureStatus?.id !== status.id &&
                            getValues(item)[index - 1]?.id !==
                              statusesForNotes.futureStatus?.id,
                          [styles.futureTransferOut]:
                            status.future < 0 &&
                            getValues(
                              getValues(
                                staffOneStore.building_staff_events[id][
                                  activeTab
                                ]
                              )[buildingIndex - 1]
                            )[0]?.future < 0,
                          [styles.currentTransferOut]:
                            status.future === 0 &&
                            getValues(
                              getValues(
                                staffOneStore.building_staff_events[id][
                                  activeTab
                                ]
                              )[buildingIndex - 1]
                            )[0]?.future === 0
                        })}
                      >
                        {/* Тип статуса */}
                        <div className={styles.typeTitle}>
                          {statusTypeTitle()}
                        </div>

                        {/* Объект статуса */}
                        {(() => {
                          if (
                            statusesForNotes.futureStatus?.id === status.id ||
                            statusesForNotes.currentStatus?.id === status.id ||
                            building !== status.building ||
                            statusesForNotes.firstPreviousStatus?.id ===
                              status.id
                          ) {
                            if (
                              statusesForNotes.futureStatus?.id !== status.id &&
                              statusesForNotes.currentStatus?.id !== status.id
                            ) {
                              building !== status.building &&
                                (building = status.building);
                            }
                            return (
                              <div className={styles.building}>
                                <span>Объект:</span>
                                <a
                                  className={styles.buildingTitle}
                                  href={`${fileUrl}/crm/building/?id=${status.building}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {status.building_title}
                                </a>
                                {/* <Link
                                    className={styles.building__title}
                                    to={`../../building/id=${status.building}/timesheet`}
                                    target="_blank"
                                  >
                                    {status.building_title}
                                  </Link> */}
                              </div>
                            );
                          }
                        })()}

                        {/* Статус */}
                        <div className={styles.status}>
                          <div>
                            <div
                              className={classNames("", {
                                [styles[`statusTitle_${status.color}`]]:
                                  statusesForNotes.currentStatus?.id ===
                                  status.id,
                                [styles.statusTitle]:
                                  statusesForNotes.currentStatus?.id !==
                                  status.id
                              })}
                            >
                              <StatusIcon
                                icon={status.icon}
                                color={status.color}
                                negative={
                                  statusesForNotes.currentStatus?.id ===
                                  status.id
                                }
                              />
                              <div>{status.type_title}</div>
                            </div>
                            {status.bage ? (
                              <div className={styles.bage}>{status.bage}</div>
                            ) : null}
                            {isSubDate ? (
                              <div className={styles.bage}>
                                {isSameDates ? "Oе" : "Ом"}
                              </div>
                            ) : null}
                            {status.event_end ? (
                              <p className={styles.date}>
                                {`c ${getFormattedDate(
                                  status.event_start
                                )} по ${
                                  index === 0 &&
                                  staffOneStore.buildingEventsType[status.type]
                                    ?.custom.active
                                    ? "настоящее время"
                                    : status.event_end
                                    ? `${getFormattedDate(status.event_end)}`
                                    : ""
                                }`}
                              </p>
                            ) : (
                              <p>{getFormattedDate(status.event_start)}</p>
                            )}
                            {statusesForNotes.currentStatus?.id ===
                            status.id ? (
                              <>
                                <div
                                  className={classNames(styles.line, {}, [
                                    styles[`line_${status.color}`]
                                  ])}
                                />
                                <p className={styles.inStatus}>В статусе:</p>
                                <p className={styles.days}>
                                  {` ${calculateDays(
                                    status.event_start,
                                    new Date()
                                  )} ${getUnits(
                                    calculateDays(
                                      status.event_start,
                                      new Date()
                                    ),
                                    "день",
                                    "дня",
                                    "дней"
                                  )}`}
                                </p>
                              </>
                            ) : null}
                          </div>
                          <div
                            className={classNames(styles.timeCreate, {
                              [styles.timeCreateSub]:
                                statusesForNotes.currentStatus?.id ===
                                  status.id && isSubDate
                            })}
                          >
                            {`Создан  ${getFormattedDate(status.time_create)
                              ?.replace(/ /g, ", в ")
                              .substring(0, 19)}`}
                          </div>
                        </div>

                        {/* Для статуса "Отпуск". Если есть ежегодный отпуск - отображать его даты второй строкой статуса */}
                        {!isSameDates &&
                        isSubVacation &&
                        status.sub_start_date ? (
                          <div className={styles.subStatus}>
                            <div>
                              <div className={styles.bage}>Oе</div>
                              <div className={styles.date}>
                                <p>
                                  {`c ${getFormattedDate(
                                    status.sub_start_date
                                  )} по ${
                                    index === 0 && !status.sub_end_date
                                      ? "настоящее время"
                                      : status.sub_end_date
                                      ? `${getFormattedDate(
                                          status.sub_end_date
                                        )}`
                                      : ""
                                  }`}
                                </p>
                              </div>
                              {statusesForNotes.currentStatus?.id ===
                              status.id ? (
                                <>
                                  <div
                                    className={classNames(styles.line, {}, [
                                      styles[`line_${status.color}`]
                                    ])}
                                  />
                                  <p className={styles.inStatus}>В статусе:</p>
                                  <p className={styles.days}>
                                    {` ${calculateDays(
                                      status.event_start,
                                      new Date()
                                    )} ${getUnits(
                                      calculateDays(
                                        status.event_start,
                                        new Date()
                                      ),
                                      "день",
                                      "дня",
                                      "дней"
                                    )}`}
                                  </p>
                                </>
                              ) : null}
                            </div>
                            <div className={styles.timeCreate}>
                              {`Создан  ${getFormattedDate(status.time_create)
                                ?.replace(/ /g, ", в ")
                                .substring(0, 19)}`}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })
              )
            ) : (
              <div className={styles.withoutStatuses}>
                Сотрудника не было ни на одном объекте
              </div>
            )}
            <div
              className={styles.link}
              id={`StaffOneInfoStatuses_goToStatusesPage_${id}`}
              onClick={() => navigate(`./statuses`)}
            >
              Показать всю историю статусов
            </div>
          </>
        ) : null}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoStatuses);
