import styles from "./staffOnePremiumLevelHistory.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import StaffOnePremiumLevelHistoryTotal from "./StaffOnePremiumHistoryTotal";
import StaffOnePremiumDetailWorkshift from "./StaffOnePremiumDetailWorkshift";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const StaffOnePremiumLevelHistory = () => {
  const { staffOnePremiumLevelStore } = useStores();
  const { id } = useParams();

  return (
    <>
      {staffOnePremiumLevelStore.premium_level_current[id]?.["history"] &&
      Object.values(
        staffOnePremiumLevelStore.premium_level_current[id]["history"]
      ).length ? (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffOnePremiumLevelHistoryTotal />
          </ErrorBoundary>
          {/* Если записей в staffOnePremiumLevelStore.premium_level_detail_workshift[id] больше 100 (то есть больше 100 лет),
          то вторую диаграмму не выводим, так как фронт не может это все обработать */}
          {staffOnePremiumLevelStore.premium_level_detail_workshift[id] &&
          Object.values(
            staffOnePremiumLevelStore.premium_level_detail_workshift[id]
          ).length < 100 ? (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <StaffOnePremiumDetailWorkshift />
            </ErrorBoundary>
          ) : (
            ""
          )}
        </>
      ) : (
        <div className={styles.withoutData}>
          У сотрудника ещё нет истории уровней
        </div>
      )}
    </>
  );
};

export default observer(StaffOnePremiumLevelHistory);
