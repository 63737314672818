import { Link, useParams } from "react-router-dom";
import styles from "./staffOneInfoCommentsNo.module.scss";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

const StaffOneInfoCommentsNo = () => {
  const { id } = useParams();

  return (
    <div className={styles.noCommentsContainer}>
      <div className={styles.noComments}>
        У сотрудника ещё нет комментариев.
      </div>
      <Link to={`/staff/id=${id}/comments`} className={styles.link}>
        <Button
          theme={ButtonTheme.SECONDARY}
          size={ButtonSize.M}
          id="StaffOneInfoCommentsBtn"
        >
          Оставить комментарий
        </Button>
      </Link>
    </div>
  );
};

export default StaffOneInfoCommentsNo;
