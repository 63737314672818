import styles from "./buildingOneWorkshiftInfoCard.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

import Modal from "shared/ui/Modal";
import ErrorMessage from "shared/ui/ErrorMessage";
import StatusIcon from "shared/ui/StatusIcon";

import iconLockOpen from "shared/assets/images/mainIcons/iconLockOpen.svg";
import iconLock from "shared/assets/images/mainIcons/iconLock.svg";

import { getMonth, getYear } from "date-fns";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonTheme } from "shared/ui/Button";

const BuildingOneWorkshiftInfoCard = () => {
  const {
    buildingOneWorkshiftStore,
    buildingOneStore,
    menuStore,
    buildingOneTimesheetStore
  } = useStores();
  const navigate = useNavigate();

  const { id } = useParams();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const navigateOneWorkshift = (id: string) => {
    navigate(`/workshift/id=${id}`);
  };
  const navigateOneBuilding = (id: string) => {
    navigate(`../../building/id=${id}`);
  };
  const navigateOneBuildingTimesheet = (id: string) => {
    navigate(`../../building/id=${id}/timesheet`);
  };

  return (
    <>
      {Object.values(buildingOneWorkshiftStore.workshiftCols).length &&
      buildingOneWorkshiftStore.selects.status &&
      buildingOneWorkshiftStore.selectedOne &&
      Object.values(buildingOneWorkshiftStore.selectedOne).length ? (
        <>
          <div className={styles.infoBlock}>
            <div className={styles.title}>
              <p>
                {`Смена ${getFormattedDate(
                  buildingOneWorkshiftStore.selectedOne.date_report
                )}`}
              </p>
              {!buildingOneWorkshiftStore.selects.status?.[
                buildingOneWorkshiftStore.selectedOne.status
              ]?.custom.done && (
                <div
                  id="BuildingOneWorkshiftInfo_delete_workshift"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  <StatusIcon icon="iconbasket" color="accent-coral" />
                </div>
              )}
            </div>

            {Object.keys(buildingOneWorkshiftStore.workshiftCols).map(
              (title) => {
                switch (title) {
                  case "date_report":
                    if (!buildingOneWorkshiftStore.workshiftCols.status) {
                      return (
                        <div className={styles.row} key={title}>
                          <div className={styles.field}>
                            <p className={styles.title}>
                              {
                                buildingOneWorkshiftStore.workshiftCols[
                                  title
                                ]?.["title"]
                              }
                            </p>
                            <p className={styles.value}>
                              {getFormattedDate(
                                buildingOneWorkshiftStore.selectedOne[title]
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    } else return;
                  case "status":
                    return (
                      <div key={title} className={styles.row}>
                        {buildingOneWorkshiftStore.workshiftCols.date_report ? (
                          <div
                            className={`${styles.field} ${styles.field_customWidth}`}
                          >
                            <p className={styles.title}>
                              {
                                buildingOneWorkshiftStore.workshiftCols
                                  .date_report?.["title"]
                              }
                            </p>
                            <p className={styles.value}>
                              {getFormattedDate(
                                buildingOneWorkshiftStore.selectedOne
                                  .date_report
                              )}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className={styles.field}>
                          <p className={styles.title}>
                            {
                              buildingOneWorkshiftStore.workshiftCols[title]?.[
                                "title"
                              ]
                            }
                          </p>

                          {!buildingOneWorkshiftStore.selects.status?.[
                            buildingOneWorkshiftStore.selectedOne.status
                          ]?.custom.done ? (
                            <div className="d-flex justify-content-between align-items-center gap-3">
                              <div className="d-flex">
                                <img
                                  src={iconLockOpen}
                                  className={styles.lockOpenIcon}
                                />
                                <p>
                                  {
                                    buildingOneWorkshiftStore.selects.status?.[
                                      buildingOneWorkshiftStore.selectedOne
                                        .status
                                    ]?.title
                                  }
                                </p>
                              </div>

                              <Button
                                id="BuildingOneWorkshiftInfo_close_workshift"
                                theme={ButtonTheme.CANCELLED}
                                onClick={() =>
                                  buildingOneWorkshiftStore.editStatus(
                                    id,
                                    Object.values(
                                      buildingOneWorkshiftStore.selects.status
                                    ).find((type) => type.custom.done === "on")
                                      .id,
                                    buildingOneWorkshiftStore.selectedOne
                                      .comment
                                  )
                                }
                                disabled={
                                  !!buildingOneWorkshiftStore.errorMessage[id]
                                    ?.head
                                }
                              >
                                {
                                  Object.values(
                                    buildingOneWorkshiftStore.selects.status
                                  ).find((type) => type.custom.done === "on")
                                    ?.custom.button
                                }
                              </Button>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center gap-3">
                              <div className="d-flex">
                                <img
                                  src={iconLock}
                                  className={styles.lockIcon}
                                />
                                <p>
                                  {
                                    buildingOneWorkshiftStore.selects.status[
                                      buildingOneWorkshiftStore.selectedOne
                                        .status
                                    ]?.title
                                  }
                                </p>
                              </div>

                              <Button
                                theme={ButtonTheme.SECONDARY}
                                id="BuildingOneWorkshiftInfo_open_workshift"
                                onClick={() =>
                                  buildingOneWorkshiftStore.editStatus(
                                    id,
                                    Object.values(
                                      buildingOneWorkshiftStore.selects.status
                                    ).find((type) => type.custom.done !== "on")
                                      .id,
                                    buildingOneWorkshiftStore.selectedOne
                                      .comment
                                  )
                                }
                                disabled={
                                  !!buildingOneWorkshiftStore.errorMessage[id]
                                    ?.head
                                }
                              >
                                {
                                  Object.values(
                                    buildingOneWorkshiftStore.selects.status
                                  ).find((type) => type.custom.done !== "on")
                                    ?.custom?.button
                                }
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  case "building":
                    return (
                      <div key={title} className={styles.row}>
                        <div className={styles.field}>
                          <p className={styles.title}>
                            {
                              buildingOneWorkshiftStore.workshiftCols[title]?.[
                                "title"
                              ]
                            }
                          </p>
                          <a
                            className={styles.value}
                            id={`Navigate_to_${buildingOneWorkshiftStore.selectedOne[title]}`}
                            onClick={() =>
                              navigateOneBuilding(
                                buildingOneWorkshiftStore.selectedOne[title]
                              )
                            }
                          >
                            {buildingOneWorkshiftStore.buildingTitle}
                          </a>
                        </div>
                      </div>
                    );
                  case "id":
                  case "comment":
                    return;
                  default:
                    return (
                      <div key={title} className={styles.row}>
                        <div className={styles.field}>
                          <p className={styles.title}>
                            {
                              buildingOneWorkshiftStore.workshiftCols[title]?.[
                                "title"
                              ]
                            }
                          </p>
                          <p className={styles.value}>
                            {buildingOneWorkshiftStore.selects[title]?.[
                              buildingOneWorkshiftStore.selectedOne[title]
                            ]?.title ||
                              buildingOneWorkshiftStore.selectedOne[title] ||
                              "—"}
                          </p>
                        </div>
                      </div>
                    );
                }
              }
            )}

            <div className={styles.row}>
              {["prev", "next"].map((workshift) => {
                return (
                  <div key={workshift} className={styles.field}>
                    <p className={styles.title}>{`${
                      workshift === "prev" ? "Предыдущая" : "Следующая"
                    } смена`}</p>
                    {buildingOneWorkshiftStore.selectedOne[workshift]
                      ?.status ? (
                      <div className="d-flex align-items-center gap-2">
                        <a
                          className={styles.value}
                          id={`Navigate_to_${buildingOneWorkshiftStore.selectedOne[workshift].id}`}
                          onClick={() =>
                            navigateOneWorkshift(
                              buildingOneWorkshiftStore.selectedOne[workshift]
                                .id
                            )
                          }
                        >
                          {getFormattedDate(
                            buildingOneWorkshiftStore.selectedOne[workshift]
                              .date_report
                          )}
                        </a>
                        {!buildingOneWorkshiftStore.selects.status[
                          buildingOneWorkshiftStore.selectedOne[workshift]
                            .status
                        ]?.custom.done ? (
                          <div className={styles.changeWorkshift}>
                            <img
                              src={iconLockOpen}
                              className={styles.lockOpenIcon}
                            />
                            <p>
                              {
                                buildingOneWorkshiftStore.selects.status[
                                  buildingOneWorkshiftStore.selectedOne[
                                    workshift
                                  ].status
                                ].title
                              }
                            </p>
                          </div>
                        ) : (
                          <div className={styles.changeWorkshift}>
                            <img src={iconLock} className={styles.lockIcon} />
                            <p>
                              {
                                buildingOneWorkshiftStore.selects.status?.[
                                  buildingOneWorkshiftStore.selectedOne[
                                    workshift
                                  ].status
                                ].title
                              }
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p className={styles.value}>—</p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {buildingOneWorkshiftStore.errorMessage[id]?.head ? (
            <ErrorMessage errors={buildingOneWorkshiftStore.errorMessage[id]} />
          ) : (
            ""
          )}
          <Modal
            type="clarification"
            show={showDeleteModal}
            onHide={() => {
              setShowDeleteModal(false);
            }}
            title="Удалить данную смену?"
            btnWithCrossTitle="Удалить"
            btnWithCrossOnClick={() => {
              setShowDeleteModal(false);
              setIsShowSuccessModal(true);
            }}
            blueBtnOnClick={() => {
              setShowDeleteModal(false);
            }}
            blueBtnTitle="Отмена"
            btnWithCrossImg
          />
        </>
      ) : (
        ""
      )}
      {isShowSuccessModal ? (
        <Modal
          type="success"
          successIcon
          show={isShowSuccessModal}
          onHide={() => {
            setIsShowSuccessModal(false);
            buildingOneWorkshiftStore.deleteWorkshift(id);
            navigateOneBuildingTimesheet(
              buildingOneWorkshiftStore.selectedOne.building
            );
            buildingOneStore.getBuildingOne(
              buildingOneWorkshiftStore.selectedOne.building
            );

            buildingOneTimesheetStore.getTabel(
              buildingOneWorkshiftStore.selectedOne.building,
              buildingOneTimesheetStore.year[
                buildingOneWorkshiftStore.selectedOne.building
              ] || getYear(new Date()),
              buildingOneTimesheetStore.month[
                buildingOneWorkshiftStore.selectedOne.building
              ] || getMonth(new Date()) + 1
            );
            Object.entries(menuStore.allWindows).forEach(([key, value]) => {
              if (value["path"] === `/workshift/id=${id}`) {
                menuStore.deleteWindow(key);
              }
            });
          }}
          title="Смена удалена"
          direction="row"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default observer(BuildingOneWorkshiftInfoCard);
