import { Table } from "react-bootstrap";
import styles from "./polatiAppVacantRequestTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import { Link } from "react-router-dom";
import PolatiAppVacantRequestSelect from "./PolatiAppVacantRequestSelect/index";
import { Field, Formik } from "formik";
import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const statuses = [
  { value: "ЧС", label: "ЧС" },
  { value: "На объекте", label: "На объекте" },
  { value: "Нет ответа", label: "Нет ответа" },
  { value: "СС", label: "СС" },
  { value: "Ожидает", label: "Ожидает" },
  { value: "Отработано", label: "Отработано" }
];

const PolatiAppVacantRequestTable = () => {
  const { polatiAppStore } = useStores();

  return (
    <>
      {Object.keys(polatiAppStore.polatiAppData).length ? (
        <Table className={styles.table}>
          <thead>
            <tr>
              {polatiAppStore.currentTitles.map((title) => {
                return (
                  <th key={title} className={styles.tableHead}>
                    {polatiAppStore.polatiAppCols[title]
                      ? polatiAppStore.polatiAppCols[title].title
                      : title}

                    <img
                      src={iconArrowDouble}
                      alt="sorting_button"
                      className={styles.arrowBtn}
                    />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {Object.values(polatiAppStore.polatiAppData).map(
              (element, index) => {
                return (
                  <tr
                    key={element.id}
                    className={`
                        ${!(index % 2) ? styles.greyRow : ""} ${
                      element.status === "ЧС" ? styles.redRow : ""
                    } ${element.status === "СС" ? styles.orangeRow : ""}`}
                  >
                    {polatiAppStore.currentTitles.map((title) => {
                      switch (title) {
                        case "time_create":
                          return (
                            <td key={title}>
                              {getFormattedDate(element[title]).slice(0, 16)}
                            </td>
                          );
                        case "name":
                          return (
                            <td key={title}>
                              <Link
                                to={`/staff/id=${element.uid}`}
                                target="_blank"
                                id={`PolatiAppVacantRequestTable_goToStaff_${element.uid}`}
                                className={styles.link}
                              >
                                {element[title]}
                              </Link>
                            </td>
                          );
                        case "current_project":
                        case "current_building":
                        case "project":
                        case "building":
                          return (
                            <td key={title} className={styles.link}>
                              <Tooltip
                                color="blue-lazure"
                                text={`Переход в ${polatiAppStore.polatiAppCols[title]["title"]} находится в разработке`}
                              >
                                <Link
                                  className={styles.link}
                                  id={`PolatiAppVacantRequestTable_toTo${title}_${element.id}`}
                                  to=""
                                  target=""
                                >
                                  {element[title]}
                                </Link>
                              </Tooltip>
                            </td>
                          );

                        case "status_on_object":
                          return (
                            <td key={title}>
                              <div className={styles.statusRow}>
                                <StatusIcon
                                  icon={element.events.type.icon}
                                  color={element.events.type.color}
                                />
                                {element.events.type.title}
                              </div>
                            </td>
                          );

                        case "phone":
                          return <td key={title}>{`+${element.phone}`}</td>;
                        case "status":
                          return (
                            <td key={title} className={styles.statusCell}>
                              <Formik
                                initialValues={element}
                                enableReinitialize
                                onSubmit={(values) => {
                                  // eslint-disable-next-line no-console
                                  console.log(values);
                                }}
                              >
                                {({ values, setFieldValue }) => {
                                  return (
                                    <Field
                                      key={values.id}
                                      as={PolatiAppVacantRequestSelect}
                                      name="status"
                                      value={values.status}
                                      options={statuses}
                                      onClick={(option: {
                                        [key: string]: string;
                                      }) => {
                                        setFieldValue(
                                          "status",
                                          option["value"]
                                        );
                                      }}
                                      id={values.id}
                                      setStatus={polatiAppStore.setStatus}
                                    />
                                  );
                                }}
                              </Formik>
                            </td>
                          );

                        default:
                          return <td key={title}>{element[title]}</td>;
                      }
                    })}
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      ) : (
        <div className={styles.withoutData}>Отсутствуют данные</div>
      )}
    </>
  );
};

export default observer(PolatiAppVacantRequestTable);
