import styles from "./staffOneWorkshift.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";
import StaffOneWorkshiftHeader from "./StaffOneWorkshiftHeader";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import StaffOneWorkshiftTable from "./StaffOneWorkshiftTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const StaffOneWorkshift = () => {
  const { menuStore, staffOneAllSectionsStore } = useStores();

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneAllSectionsStore.setSelectedOneForAllSections(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffWorkshift");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/workshift`
    });

    activeTab !== "all" && setActiveTab("all");
  }, [id]);

  useEffect(() => {
    if (staffOneAllSectionsStore.tabs?.length) {
      setActiveTab(
        staffOneAllSectionsStore.tabs.length > 1
          ? "all"
          : staffOneAllSectionsStore.tabs[0].id
      );
    }
  }, [staffOneAllSectionsStore.tabs]);

  const setSelectedTab = (id: string) => {
    setActiveTab(id);
  };

  return (
    <>
      <PageTitle title="Отработано смен" leftCol />
      <LoadedComponent
        isLoading={staffOneAllSectionsStore.isLoading[id]}
        error={staffOneAllSectionsStore.error[id]}
      >
        {staffOneAllSectionsStore.workshift &&
        Object.keys(staffOneAllSectionsStore.workshift).length ? (
          <div>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <StaffOneWorkshiftHeader />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <div className={styles.companies}>
                <ButtonsTabs
                  selectedTab={activeTab}
                  tabs={[
                    staffOneAllSectionsStore.tabs.length > 1 && {
                      title: "Все",
                      id: "all"
                    },
                    ...staffOneAllSectionsStore.tabs
                  ]}
                  changeActiveTab={setSelectedTab}
                  title="Компании"
                />
              </div>
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <StaffOneWorkshiftTable activeTab={activeTab} />
            </ErrorBoundary>
          </div>
        ) : (
          <div className={styles.withoutWorkshifts}>
            Не найдена история должностей
          </div>
        )}
      </LoadedComponent>
    </>
  );
};

export default observer(StaffOneWorkshift);
