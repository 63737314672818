import styles from "./awardsFields.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { fileUrl } from "stores/utils/consts";
import { Award } from "stores/StaffModule/types/Award";
import { classNames } from "shared/utils/helpers/classNames";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import StatusIcon from "shared/ui/StatusIcon";
import AwardsGalleryWindow from "../AwardsGalleryWindow";
import { useState } from "react";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getEntries } from "shared/utils/helpers/getEntries";

type AwardsFieldsProps = {
  awards: Record<string, Award>;
  field: string;
};

const AwardsFields = ({ awards, field }: AwardsFieldsProps) => {
  const { staffOneAwardsStore } = useStores();
  const [openWindow, setOpenWindow] = useState(false);

  const isOpenedField =
    (!staffOneAwardsStore.awardsArray?.includes(field) &&
      !staffOneAwardsStore.isFocusSearch) ||
    (staffOneAwardsStore.isFocusSearch &&
      staffOneAwardsStore.awardsFieldsFoundDuringSearch.includes(field));

  const toggleOneFieldShowing = () => {
    if (
      !staffOneAwardsStore.isFocusSearch &&
      !staffOneAwardsStore.searchValue
    ) {
      staffOneAwardsStore.changeOpenedAwards(
        !isOpenedField ? "delete" : "add",
        [field]
      );
    }
    return;
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsGalleryWindow
          openWindow={openWindow}
          setOpenWindow={() => setOpenWindow(false)}
        />
      </ErrorBoundary>

      <div className={styles.dashedLine}>
        <div className={styles.widgetHeader}>
          <div
            id={`AwardsFields_toggleOneFieldShowing_${field}`}
            onClick={toggleOneFieldShowing}
            className={classNames(styles.fieldButton, {
              [styles.fieldButtonNonActive]: staffOneAwardsStore.isFocusSearch,
              [styles.fieldButtonClosed]: !isOpenedField,
              [styles.fieldButtonOpened]: isOpenedField
            })}
          >
            <StatusIcon
              icon="iconcollapsestroke"
              color={!isOpenedField ? "bw-gray6" : "blue-lazure"}
            />
            <p>{field}</p>
            <div className={styles.gradientLine} />
          </div>
        </div>
        {isOpenedField ? (
          <div className={styles.field}>
            {getKeys(awards).length ? (
              getEntries(awards).map(([id, award]) => {
                const handleAwardWindowOpen = () => {
                  staffOneAwardsStore.setOpenedAward(id);
                  setOpenWindow(true);
                  staffOneAwardsStore.setIsActiveButton(
                    staffOneAwardsStore.awardGroups[
                      staffOneAwardsStore.awards[
                        staffOneAwardsStore.openedAward
                      ].custom?.group
                    ]
                  );
                };
                return (
                  <div
                    key={award.id}
                    className={styles.award}
                    onClick={handleAwardWindowOpen}
                  >
                    <div className={styles.dataText}>
                      {getFormattedDate(award.award_start)}
                    </div>
                    {award.img_src !== -1 ? (
                      <img
                        src={fileUrl + award.img_src}
                        alt={award.comment}
                        className={styles.img}
                      />
                    ) : (
                      ""
                    )}
                    <h3 className={styles.title}>{award.type_title}</h3>
                  </div>
                );
              })
            ) : (
              <div className={styles.withoutAwards}>
                {`У сотрудника нет наград за ${field}`}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default observer(AwardsFields);
