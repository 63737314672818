import styles from "./staffOneInfoConvictions.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import MidTable from "shared/ui/Table/MidTable";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import LoadedComponent from "widgets/LoadedComponent";

const StaffOneInfoConvictions = () => {
  const { staffOneStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneStore.conviction) && staffOneStore.getConvictionWidget(id);
  }, [id]);

  const allConvictions =
    staffOneStore.conviction[id] && Object.values(staffOneStore.conviction[id]);

  const titles = [
    "Статья",
    " ",
    "Дата получения",
    "Вид наказания",
    "Тип срока"
  ];

  const content = allConvictions?.slice(0, 2).map((item) => {
    return {
      id: item.id,
      article_number: item.article.number,
      article: item.article.title,
      date: item.date_start,
      punishment: item.punishment,
      period: item.term_type
    };
  });

  return (
    <LoadedComponent
      isLoading={staffOneStore.isLoadingForWidget_conviction[id]}
      withoutText
    >
      <MidTable
        titles={titles}
        tableRowData={content}
        defaultMsg={() => {
          return (
            <div className={styles.withoutConviction}>
              Нет сведений о судимости
            </div>
          );
        }}
      />
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoConvictions);
