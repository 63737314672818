import styles from "./staffOneSafetyWorkCommentsTable.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const StaffOneInfoSafetyWorkCommentsTable = () => {
  const { staffOneStore } = useStores();
  const { id } = useParams();

  const titles = [
    staffOneStore.staffTableCols.time_create.title,
    staffOneStore.staffTableCols.comment.title
  ];

  return Object.values(staffOneStore.safety_work[id]?.["comments"]).length ? (
    <>
      {Object.values(staffOneStore.safety_work[id]).map(() => {
        const table: { id: string; event_date: string; comment: string }[] = [];
        Object.values(staffOneStore.safety_work[id]["comments"]).forEach(
          (comment) => {
            table.push({
              id: comment.id,
              event_date: getFormattedDate(comment.event_date),
              comment: comment.comment
            });
          }
        );

        return table.length ? (
          <Table
            borderless
            className={styles.table}
            key={`comments-${table[0].id}`}
          >
            <thead>
              <tr className={styles.thead}>
                {titles.map((title) => {
                  return (
                    <th key={title} className={styles.title}>
                      {title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {table.map((row) => {
                return (
                  <tr key={row.id} className={styles.tr}>
                    {Object.keys(row).map((cell) => {
                      if (cell === "id") return;
                      return (
                        <td key={`${cell}-${row.id}`} className={styles.td}>
                          {row[cell] ? row[cell] : "—"}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className={styles.noData}>Комментарии не найдены.</div>
        );
      })}
    </>
  ) : (
    <div className={styles.noData}>Комментарии не найдены.</div>
  );
};

export default observer(StaffOneInfoSafetyWorkCommentsTable);
