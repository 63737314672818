import styles from "./staffOneSalaryFields.module.scss";
import { observer } from "mobx-react-lite";
import StaffOneSalaryRetentionPPE from "./StaffOneSalaryRetentionPPE";
import StaffOneSalaryLastOperations from "./StaffOneSalaryLastOperations";
import StaffOneSalaryRetentionUC from "./StaffOneSalaryRetentionUC";
import StaffOneSalaryPaymentList from "./StaffOneSalaryPaymentList";
import StaffOneSalaryPremium from "./StaffOneSalaryPremium";
import StatusIcon from "shared/ui/StatusIcon";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { MutableRefObject } from "react";
import { classNames } from "shared/utils/helpers/classNames";

const fieldsTitlе = {
  retentionPPE: "Удержания СИЗ",
  withholding_edu: "Вычеты УЦ",
  paymentlist: "Ведомости сотрудника",
  premium: "Премии",
  operations: "Последние операции"
};

const fields = {
  retentionPPE: <StaffOneSalaryRetentionPPE />,
  withholding_edu: <StaffOneSalaryRetentionUC />,
  paymentlist: <StaffOneSalaryPaymentList />,
  premium: <StaffOneSalaryPremium />,
  operations: <StaffOneSalaryLastOperations />
};

type StaffOneSalaryFieldsProps = {
  field: string;
  refsForBlock: MutableRefObject<HTMLDivElement[]>;
};

const StaffOneSalaryFields = ({
  field,
  refsForBlock
}: StaffOneSalaryFieldsProps) => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  const setConditionBlockList = () => {
    staffOneSalaryStore.setConditionBlockList(
      id,
      field,
      !staffOneSalaryStore.conditionBlockList[id]?.[field]
    );
  };

  return (
    <div
      className={classNames(styles.marginBottom, {
        [styles.dashedLine]:
          id in staffOneSalaryStore.conditionBlockList &&
          field in staffOneSalaryStore.conditionBlockList[id] &&
          staffOneSalaryStore.conditionBlockList[id][field]
      })}
      // Динамически задаем ref для каждого блока
      ref={(el) => (refsForBlock.current[field] = el)}
    >
      <div
        id={`StaffOneSalaryFields_showInfo_${field}`}
        onClick={setConditionBlockList}
        className={classNames(styles.blind, {
          [styles.closedInfo]:
            !staffOneSalaryStore.conditionBlockList[id]?.[field],
          [styles.openedInfo]:
            staffOneSalaryStore.conditionBlockList[id]?.[field]
        })}
      >
        <StatusIcon
          icon="iconcollapsestroke"
          color={
            !staffOneSalaryStore.conditionBlockList[id]?.[field]
              ? "bw-gray6"
              : "blue-lazure"
          }
        />
        <p>{fieldsTitlе[field]}</p>
        <div className={styles.gradientLine} />
      </div>
      {staffOneSalaryStore.conditionBlockList[id]?.[field] ? (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className={styles.field}>{fields[field]}</div>
        </ErrorBoundary>
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(StaffOneSalaryFields);
