import styles from "./switchedTabs.module.scss";
import Tooltip, { TooltipProps } from "shared/ui/Tooltip";

type SwitchedTabsProps = {
  tabs: Array<{
    id: string;
    title: string;
    tooltip?: TooltipProps;
    isBlockedTab?: boolean;
  }>;
  changeActiveTab: (id: string) => void;
  selectedTab: string | null;
  isClippedTabs?: boolean;
  tooltip?: TooltipProps;
};

const SwitchedTabs = ({
  tabs,
  changeActiveTab,
  selectedTab,
  isClippedTabs
}: SwitchedTabsProps) => {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        {tabs.map((tab, i) => {
          return tab.id !== undefined ? (
            tab.tooltip ? (
              <div
                key={`tab-${tab.title}-${tab.id}`}
                className={`${styles.tabLinkContainer} ${
                  tab.id === selectedTab ? styles.activeContainer : ""
                }
              ${
                tab.id === selectedTab && i === 0
                  ? styles[`activeContainer_${i}`]
                  : ""
              }
               ${i === 0 ? styles[`tabLinkContainer_${i}`] : ""}`}
                id={`tab-${tab.title}-${tab.id}`}
              >
                <Tooltip {...tab.tooltip}>
                  <button
                    className={`${styles.tabLinks}
                  ${isClippedTabs && styles.tabLinksClipped}
                  ${tab.id === selectedTab ? styles.active : ""} ${
                      i === 0 ? styles[`tabLink_${i}`] : ""
                    }`}
                    onClick={() => {
                      !tab.isBlockedTab && changeActiveTab(tab.id);
                    }}
                  >
                    {tab.title}
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div
                className={`${styles.tabLinkContainer} ${
                  tab.id === selectedTab ? styles.activeContainer : ""
                }
              ${
                tab.id === selectedTab && i === 0
                  ? styles[`activeContainer_${i}`]
                  : ""
              }
               ${i === 0 ? styles[`tabLinkContainer_${i}`] : ""}`}
                key={`tab-${tab.title}-${tab.id}`}
                id={`tab-${tab.title}-${tab.id}`}
              >
                <button
                  className={`${styles.tabLinks}
                  ${isClippedTabs && styles.tabLinksClipped}
                  ${tab.id === selectedTab ? styles.active : ""} ${
                    i === 0 ? styles[`tabLink_${i}`] : ""
                  }`}
                  onClick={() => {
                    !tab.isBlockedTab && changeActiveTab(tab.id);
                  }}
                >
                  {tab.title}
                </button>
              </div>
            )
          ) : null;
        })}
      </div>
    </div>
  );
};

export default SwitchedTabs;
