import styles from "./staffTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useRef } from "react";
import { useParams } from "react-router-dom";

import { Table } from "react-bootstrap";
import LoadedComponent from "widgets/LoadedComponent";
import { isEmpty } from "lodash";

import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import StaffTableHeader from "./StaffTableHeader";
import StaffTableBody from "./StaffTableBody";
import StaffTableFooter from "./StaffTableFooter";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const StaffTable = () => {
  const { aregisterOneStore } = useStores();
  const { id } = useParams();

  const ref = useRef<HTMLTableElement>();
  useOnClickOutside({
    ref,
    handler: () => {
      aregisterOneStore.focusedPresave[id] &&
        aregisterOneStore.setFocusedPresave("");
      aregisterOneStore.focusedCompanyStaff[id] &&
        aregisterOneStore.setFocusedCompanyStaff("");
    }
  });

  return (
    <>
      {!isEmpty(aregisterOneStore.selectedOne) &&
      !isEmpty(aregisterOneStore.presaves[id]) ? (
        <Table className={styles.table} ref={ref}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffTableHeader />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffTableBody />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffTableFooter />
          </ErrorBoundary>
        </Table>
      ) : (
        <div className={styles.withoutPresaves}>Почасовки не найдены</div>
      )}
      <LoadedComponent isLoading={aregisterOneStore.isLoading[id]} />
    </>
  );
};

export default observer(StaffTable);
