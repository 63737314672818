import styles from "./aregisterAllListTable.module.scss";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStores } from "stores/index";
import { useRef, useState } from "react";

import { Form, useFormikContext } from "formik";
import { Table } from "react-bootstrap";
import ScrollButton from "shared/ui/Buttons/ScrollButton";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Checkbox from "shared/ui/Inputs/Checkbox";
import Tooltip from "shared/ui/Tooltip";
import SelectField from "./SelectField";
import AregisterTableLoader from "./AregisterTableLoader";
import AregisterPanels from "./AregisterPanels";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import iconLockOpen from "shared/assets/images/mainIcons/iconLockOpen.svg";
import iconLock from "shared/assets/images/mainIcons/iconLock.svg";
import iconCancel from "shared/assets/images/mainIcons/iconCancel.svg";
import { ReactComponent as IconExclamation } from "shared/assets/images/mainIcons/iconExclamation.svg";
import { browserName } from "react-device-detect";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import AregisterTableHeader from "./AregisterTableHeader";
import AregisterTableMassEditRow from "./AregisterTableMassEditRow";
import { classNames } from "shared/utils/helpers/classNames";
import { AregisterListFormik } from "stores/AregisterModule/types/AregisterListFormik";

const AregisterAllListTable = () => {
  const { aregisterListStore, menuStore, filterStore } = useStores();

  const [openedListName, setOpenedListName] = useState("");

  const { values, handleReset, setFieldValue, setFieldTouched } =
    useFormikContext<AregisterListFormik>();

  const navigate = useNavigate();
  const navigateToApplication = (id: string) => () => {
    if (!aregisterListStore.openedPanel) {
      navigate(`./id=${id}`);
      menuStore.setScrollPosition(menuStore.scroll);
    }
  };

  const tableRef = useRef<HTMLTableElement>();

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  const getStatus = (status: string) =>
    Object.values(aregisterListStore.selects.status || {}).find(
      (reg_status) => reg_status.title === status
    );

  const isMassStatus = aregisterListStore.openedEditType === "mass_status";

  const handleMassStatusCheckboxClick = (id: string) => () => {
    let updatedValue: string[];
    if (values.aregister?.includes(id)) {
      updatedValue = values.aregister.filter((value: string) => value !== id);
    } else {
      if (values.aregister?.length) {
        updatedValue = [...values.aregister, id];
      } else {
        updatedValue = [id];
      }
    }

    if (updatedValue.length) {
      setFieldValue("aregister", updatedValue);
      setFieldTouched("aregister");
    } else {
      handleReset();
    }
  };

  return (
    <>
      <Form>
        {aregisterListStore.currentTitles.length ? (
          <>
            <ScrollButton tableRef={tableRef} />
            <Table
              ref={tableRef}
              className={styles.table}
              style={{
                marginTop: getTableMarginTop(
                  filterStore.savedFiltersHeight,
                  Boolean(aregisterListStore.searchValue),
                  filterStore.isOpenAdvancedSearch ||
                    filterStore.isOpenedSavedFilterOptions ||
                    filterStore.isOpenedColumnsOptions,
                  browserName === "Firefox"
                ),
                transition: "0.2s"
              }}
            >
              <AregisterTableHeader />
              {values?.info?.length ? (
                <tbody className={styles.tableBody}>
                  <AregisterTableMassEditRow
                    openedListName={openedListName}
                    changeOpenedWindows={changeOpenedWindows}
                    getStatus={getStatus}
                  />
                  {values.info.map((item, i) => {
                    const status = getStatus(item.status);

                    if (status.id === aregisterListStore.openedMassStatusEdit)
                      return;

                    return (
                      <tr
                        key={item.id}
                        className={classNames("", {
                          [styles.greyRow]: !(i % 2),
                          [styles.detailHoverRow]:
                            !aregisterListStore.openedPanel
                        })}
                        id={`AregisterAllListTable_to_Aregister_${item.id}`}
                        onClick={navigateToApplication(item.id)}
                      >
                        {isMassStatus ? (
                          <td>
                            <Checkbox
                              name={"aregister"}
                              value={item.id}
                              onChange={handleMassStatusCheckboxClick(item.id)}
                            />
                          </td>
                        ) : null}
                        {aregisterListStore.currentTitles.map((title) => {
                          switch (title) {
                            case "internal_num":
                              return (
                                <td key={`${item.id}_${title}`}>
                                  <div
                                    className={classNames("", {
                                      [styles.withErrorIcon]:
                                        aregisterListStore.unableCloseApp?.find(
                                          (aregister) => aregister === item.id
                                        )
                                    })}
                                  >
                                    <div
                                      className={classNames("", {
                                        [styles.open]:
                                          item.status === "Открыта",
                                        [styles.deleted]:
                                          item.status === "Аннулирована",
                                        [styles.closed]:
                                          item.status === "Закрыта"
                                      })}
                                    >
                                      <img
                                        src={
                                          item.status === "Открыта"
                                            ? iconLockOpen
                                            : item.status === "Аннулирована"
                                            ? iconCancel
                                            : iconLock
                                        }
                                      />
                                      <p>{item[title]}</p>
                                    </div>
                                    {aregisterListStore.unableCloseApp?.find(
                                      (aregister) => aregister === item.id
                                    ) ? (
                                      <Tooltip
                                        placement="top"
                                        color="accent-coral"
                                        text="Заявка не может быть закрыта"
                                      >
                                        <IconExclamation />
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                </td>
                              );
                            case "date_start":
                            case "date_end":
                              return !aregisterListStore.openedPanel ||
                                isMassStatus ? (
                                <td key={`${item.id}_${title}`}>
                                  <p>{getFormattedDate(item[title])}</p>
                                </td>
                              ) : (
                                <td key={`${item.id}_${title}`}>
                                  {!status?.custom?.done &&
                                  !status?.custom?.cancelled ? (
                                    <DatePickerField
                                      name={`info.${i}.${title}`}
                                      title=""
                                      placeholderVisible
                                      isCalendarOpened={
                                        openedListName === `info.${i}.${title}`
                                      }
                                      setIsCalendarOpened={() => {
                                        changeOpenedWindows(
                                          `info.${i}.${title}`
                                        );
                                      }}
                                      placement="right"
                                      className={styles.datePickerCustom}
                                    />
                                  ) : (
                                    <p>{getFormattedDate(item[title])}</p>
                                  )}
                                </td>
                              );
                            case "master":
                            case "brigadier":
                              return !aregisterListStore.openedPanel ||
                                isMassStatus ? (
                                <td key={`${item.id}_${title}`}>
                                  <p>{item[title]?.fio}</p>
                                </td>
                              ) : (
                                <td key={`${item.id}_${title}`}>
                                  <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                  >
                                    <SelectField title={title} id={item.id} />
                                  </ErrorBoundary>
                                </td>
                              );
                            case "type_work":
                            case "type":
                            case "species":
                            case "sub_work":
                              return (
                                <td key={`${item.id}_${title}`}>
                                  <p>{item[title]}</p>
                                </td>
                              );
                            case "size":
                              return (
                                <td key={`${item.id}_${title}`}>
                                  <p>
                                    {item[title] && +item[title].toFixed(4)}
                                  </p>
                                </td>
                              );
                            case "act_hours":
                            case "kc":
                            default:
                              return (
                                <td key={`${item.id}_${title}`}>
                                  <p>
                                    {title === "project"
                                      ? item[title]?.title
                                      : item[title]}
                                  </p>
                                </td>
                              );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr className={styles.error}>
                    <td colSpan={aregisterListStore.currentTitles.length}>
                      <div data-type="error">
                        {aregisterListStore.errorMessage || "Ничего не найдено"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </>
        ) : null}
        <AregisterTableLoader />
      </Form>
      <AregisterPanels />
    </>
  );
};

export default observer(AregisterAllListTable);
