import { observer } from "mobx-react-lite";
import styles from "./salaryAddPremium.module.scss";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import { Formik } from "formik";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import ErrorMessage from "shared/ui/ErrorMessage";
import Select from "shared/ui/Inputs/Select";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { getValidationSchema } from "./validation";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const SalaryAddPremium = () => {
  const { menuStore, salaryPremiumOneStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string | null;
  }>({});

  const navigate = useNavigate();

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary/premium`) {
        menuStore.deleteWindow(key);
      }
    });
    salaryPremiumOneStore.getDataForAdd();
    getInitialValues();
    menuStore.setOpenedModule("salary");
    menuStore.setOpenedSubmodule("add_premium");
    menuStore.updateWindow({
      mainPath: "/salary",
      path: "/salary/add_premium",
      title: "Финансы"
    });
  }, []);

  const getInitialValues = () => {
    const values: { [key: string]: string | null } = {};
    salaryPremiumOneStore.fieldsForAdd.forEach((field) => {
      switch (salaryPremiumOneStore.cols[field]["type"]) {
        case "timestamp":
        case "date":
          values[field] = null;
          break;
        default:
          values[field] = "";
      }
    });
    setInitialValues(values);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent isLoading={salaryPremiumOneStore.isLoading}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={getValidationSchema(
            Object.keys(initialValues),
            salaryPremiumOneStore.requiredFields
          )}
          onSubmit={(values) => {
            salaryPremiumOneStore.setInitialValues({
              ...values,
              id: "dfsfewrt43trferst",
              time_create: "15.07.2022",
              last_update: "15.07.2022",
              status: "257ddd5543bbcef9030101132345973b93005c0b"
            });
          }}
        >
          {({ values, isValid, dirty, handleSubmit, handleReset }) => {
            return (
              <>
                {Object.keys(values).map((title) => {
                  const col = salaryPremiumOneStore.cols[title];
                  const param = salaryPremiumOneStore.params[title];
                  if (param?.isvariable) {
                    return (
                      <div key={title} className={styles.field}>
                        <Select
                          name={title}
                          options={
                            param.variable as {
                              [key: string]: { title: string };
                            }
                          }
                          title={col.title}
                          required={salaryPremiumOneStore.requiredFields.includes(
                            title
                          )}
                          valueName="id"
                          isFloating
                          withClearBtn
                        />
                      </div>
                    );
                  }
                  if (col.type === "timestamp" || col.type === "date") {
                    return (
                      <div key={title} className={styles.field}>
                        <DatePickerField
                          name={title}
                          title={col.title}
                          isCalendarOpened={openedListName === title}
                          setIsCalendarOpened={() =>
                            changeOpenedListName(title)
                          }
                          withClearBtn
                          dateFormat={
                            title === "calculation_period" ? "MM.yyyy" : null
                          }
                          required={salaryPremiumOneStore.requiredFields.includes(
                            title
                          )}
                          withMonthLongName
                        />
                      </div>
                    );
                  }
                })}

                {!isValid || !dirty ? (
                  <div className={styles.errors}>
                    <ErrorMessage
                      errors={{
                        head: "Не все обязательные поля заполнены",
                        color: "danger"
                      }}
                    />
                  </div>
                ) : null}
                <div className={styles.buttons}>
                  <ButtonsGroupForSettings
                    saveBtnTitle="Создать"
                    saveBtnDisabled={!isValid || !dirty}
                    saveBtnOnClick={() => {
                      handleSubmit();
                      navigate("../salary/premium/id=dfsfewrt43trferst");
                      // hardcode
                      setTimeout(() => {
                        handleReset();
                      }, 200);
                    }}
                    addBtnTitle="Очистить форму"
                    addBtnOnClick={handleReset}
                    addBtnDisabled={!dirty}
                    addBtnImg={<IconClose />}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(SalaryAddPremium);
