import styles from "./StaffOneComments.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import PageTitle from "shared/ui/PageTitle";
import LoadedComponent from "widgets/LoadedComponent";
import CommentInputField from "features/Comments/CommentInputField/index";
import { useParams } from "react-router-dom";
import CommentsShowWithButton from "features/Comments/CommentsShowWithButton/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";

const StaffOneComments = () => {
  const { id } = useParams();
  const { menuStore, staffOneAllSectionsStore, commentsStore } = useStores();

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneAllSectionsStore.setSelectedOneForAllSections(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffComment");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/comments`
    });
    !commentsStore.comments[id] && commentsStore.getComments(id);
  }, [id]);

  return (
    <>
      <PageTitle title="Комментарии" leftCol />
      <LoadedComponent
        isLoading={commentsStore.isLoading[id]}
        error={commentsStore.error[id]}
      >
        <div className={styles.wrapper}>
          <div className={styles.commentsList}>
            {!isEmpty(commentsStore.comments[id]) ? (
              <ul>
                {getValues(commentsStore.comments[id]).map((comment) => {
                  return (
                    <ErrorBoundary
                      FallbackComponent={ErrorFallback}
                      key={comment.id}
                    >
                      <CommentsShowWithButton
                        comment={comment}
                        deleteComment={commentsStore.deleteComment}
                      />
                    </ErrorBoundary>
                  );
                })}
              </ul>
            ) : (
              <ul className={styles.withoutComment}>
                У сотрудника ещё нет комментариев
              </ul>
            )}
          </div>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <CommentInputField
              addComment={commentsStore.addComment}
              commentCol={
                staffOneAllSectionsStore.selectedOneForAllSections.cols
                  ?.comments?.comment
              }
            />
          </ErrorBoundary>
        </div>
      </LoadedComponent>
    </>
  );
};

export default observer(StaffOneComments);
