import styles from "./staffOnePremiumLevelReward.module.scss";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import React from "react";
import { fileUrl } from "stores/utils/consts";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { classNames } from "shared/utils/helpers/classNames";
import { PremiumLevelSelect } from "stores/StaffModule/types/Selects";
import { months } from "shared/utils/dictionaries/months";

const cols = {
  year: "Год",
  month: "Месяц",
  level: "Уровень",
  fraction: "Процент от ЗП",
  wage: "Заработано",
  money: "Сумма вознаграждения"
};

const currentTitles = ["year", "month", "level", "fraction", "wage", "money"];

const titlesWithRightPos = ["wage", "money"];

const StaffOnePremiumLevelReward = () => {
  const { staffOnePremiumLevelStore } = useStores();
  const { id } = useParams();

  const { history } = staffOnePremiumLevelStore.premium_level_current[id];

  const getFraction = (select: PremiumLevelSelect) => {
    return `${numberWithSpaces(
      select?.["custom"]?.["fraction"] || 0,
      false,
      1
    )} %`;
  };

  const getSum = (field: string) => {
    let result = 0;
    Object.values(history || {}).forEach((year) => {
      result += Object.values(year["month"])
        .map((item) => item[field])
        .reduce((sum, current) => sum + current, 0);
    });
    return numberWithSpaces(result);
  };

  const sortedList = Object.values(history || {}).sort(
    (a, b) => b.year - a.year
  );

  return (
    <>
      {Object.values(history || {}).length ? (
        <Table className={styles.table}>
          <thead>
            <tr className={styles.tableHeader}>
              <th className={styles.title}>Итого:</th>
              <th></th>
              <th></th>
              <th></th>
              <th className={styles.sum}>{getSum("wage")}</th>
              <th className={styles.sum}>{getSum("money")}</th>
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            <tr>
              {currentTitles.map((title) => {
                return (
                  <td
                    key={title}
                    className={classNames(styles.title, {
                      [styles.titleRight]: titlesWithRightPos.includes(title)
                    })}
                  >
                    <p>{cols[title]}</p>
                  </td>
                );
              })}
            </tr>
            {sortedList.map((year) => {
              const sortedYearByMonthArray =
                year["month"] &&
                Object.values(year["month"]).sort((a, b) => b.month - a.month);

              const typeOfPremiumLevel =
                staffOnePremiumLevelStore.premium_level_selects[
                  sortedYearByMonthArray[0]?.["level"]
                ];
              return (
                <React.Fragment key={year.year}>
                  <tr>
                    {currentTitles.map((title) => {
                      switch (title) {
                        case "year":
                          return (
                            <td
                              key={title}
                              className={classNames("", {
                                [styles.yearCell]:
                                  sortedYearByMonthArray?.length > 1
                              })}
                            >
                              {year[title]}
                            </td>
                          );
                        case "month":
                          return (
                            <td key={title}>
                              {months[sortedYearByMonthArray[0]?.[title]]}
                            </td>
                          );
                        case "level":
                          return (
                            <td key={title}>
                              <div className={styles.rowWithIcon}>
                                {typeOfPremiumLevel?.["img"] ? (
                                  <img
                                    src={`${fileUrl}${typeOfPremiumLevel["img"]}`}
                                  />
                                ) : (
                                  ""
                                )}
                                {typeOfPremiumLevel?.["title"]}
                              </div>
                            </td>
                          );
                        case "fraction":
                          return (
                            <td key={title}>
                              {getFraction(typeOfPremiumLevel)}
                            </td>
                          );
                        case "wage":
                        case "money":
                          return (
                            <td key={title} className={styles.moneyCell}>
                              {sortedYearByMonthArray[0]?.[title]
                                ? numberWithSpaces(
                                    sortedYearByMonthArray[0][title]
                                  )
                                : "-"}
                            </td>
                          );
                      }
                    })}
                  </tr>
                  {sortedYearByMonthArray?.length > 1
                    ? sortedYearByMonthArray.map((month, ind) => {
                        const typeOfPremiumLevel =
                          staffOnePremiumLevelStore.premium_level_selects[
                            sortedYearByMonthArray[ind]?.["level"]
                          ];
                        if (ind === 0) return;
                        return (
                          <tr key={month.month}>
                            {currentTitles.map((title) => {
                              switch (title) {
                                case "year":
                                  return (
                                    <td
                                      key={title}
                                      className={classNames("", {
                                        [styles.yearCell]:
                                          ind !==
                                          sortedYearByMonthArray.length - 1
                                      })}
                                    ></td>
                                  );
                                case "month":
                                  return (
                                    <td key={title}>
                                      {
                                        months[
                                          sortedYearByMonthArray[ind]?.[title]
                                        ]
                                      }
                                    </td>
                                  );
                                case "level":
                                  return (
                                    <td key={title}>
                                      <div className={styles.rowWithIcon}>
                                        {typeOfPremiumLevel?.["img"] ? (
                                          <img
                                            src={`${fileUrl}${typeOfPremiumLevel["img"]}`}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {typeOfPremiumLevel?.["title"]}
                                      </div>
                                    </td>
                                  );
                                case "fraction":
                                  return (
                                    <td key={title}>
                                      {getFraction(typeOfPremiumLevel)}
                                    </td>
                                  );
                                case "wage":
                                case "money":
                                  return (
                                    <td
                                      key={title}
                                      className={styles.moneyCell}
                                    >
                                      {sortedYearByMonthArray[ind]?.[title]
                                        ? numberWithSpaces(
                                            sortedYearByMonthArray[ind][title]
                                          )
                                        : "-"}
                                    </td>
                                  );
                              }
                            })}
                          </tr>
                        );
                      })
                    : ""}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className={styles.withoutData}>
          У сотрудника ещё нет вознаграждений
        </div>
      )}
    </>
  );
};

export default observer(StaffOnePremiumLevelReward);
