import styles from "./staffOneSafetyWork.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";

import StaffOneInfoSafetyWorkPositionsList from "./StaffOneInfoSafetyWorkPositionsList";
import StaffOneInfoSafetyWorkCertificateTable from "./StaffOneInfoSafetyWorkCertificateTable";
import StaffOneInfoSafetyWorkCommentsTable from "./StaffOneInfoSafetyWorkCommentsTable";
import LoadedComponent from "widgets/LoadedComponent";

const StaffOneInfoSafetyWork = () => {
  const { staffOneStore } = useStores();

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("");
  const [noCertificateData, setNoCertificateData] = useState(false);
  const [activePosition, setActivePosition] = useState("");
  const [positions, setPositions] = useState<{
    [key: string]: { [key: string]: { [key: string]: string } };
  }>({});

  useEffect(() => {
    !(id in staffOneStore.safety_work) && staffOneStore.getSafetyWorkWidget(id);
    setActiveTab(staffOneStore.selectedCompany);
  }, [id, staffOneStore.selectedCompany]);

  const tabs = [
    ...staffOneStore.companiesTabs,
    { id: "comments", title: "Комментарии" }
  ];

  return (
    <LoadedComponent
      // лоадер staffOneStore.isLoadingForWidgetMetrics[id] нужен для того, чтобы корректно отобразились табы по компаниям
      // применять во всех виджетах, где используются табы по компаниям
      isLoading={
        staffOneStore.isLoadingForWidget_safety_work[id] ||
        staffOneStore.isLoadingForWidgetMetrics[id]
      }
      withoutText
    >
      <div className="w-100">
        {noCertificateData && (
          <div className={styles.noCertificateDate}>
            Должности сотрудника не соотносятся с трудоустройством.
          </div>
        )}
        <div
          className={`${styles.tabs} ${
            tabs.length > 3 ? styles.tabs_clipped : ""
          }`}
        >
          <SwitchedTabs
            tabs={tabs}
            changeActiveTab={(tab) => {
              setActiveTab(tab);
            }}
            selectedTab={activeTab}
          />
        </div>
        <StaffOneInfoSafetyWorkPositionsList
          activePosition={activePosition}
          setActivePosition={setActivePosition}
          positions={positions}
          setPositions={setPositions}
          id={id}
          activeTab={activeTab}
          setNoCertificateData={(boolean: boolean) =>
            setNoCertificateData(boolean)
          }
        />
        {positions?.[id] &&
          (activeTab !== "comments" ? (
            <StaffOneInfoSafetyWorkCertificateTable
              positions={positions[id]}
              activeTab={activeTab}
              activePosition={activePosition}
            />
          ) : (
            <StaffOneInfoSafetyWorkCommentsTable />
          ))}
      </div>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoSafetyWork);
