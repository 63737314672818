import styles from "./buildingOneStaffTableError.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import ErrorMessage from "shared/ui/ErrorMessage";
import { Button, ButtonTheme } from "shared/ui/Button";

const BuildingOneStaffTableError = () => {
  const { buildingOneStaffStore } = useStores();
  const { id } = useParams();

  return buildingOneStaffStore.errorsMessage[id] &&
    Object.values(buildingOneStaffStore.errorsMessage[id]).length ? (
    <div className={styles.container}>
      {Object.values(buildingOneStaffStore.errorsMessage[id]).map((error) => {
        return <ErrorMessage key={error.head} errors={error} />;
      })}
      <div className={styles.btnBlock}>
        <Button
          theme={ButtonTheme.SECONDARY}
          id="BuildingOneStaffTableError_close_panel"
          type="button"
          onClick={() => buildingOneStaffStore.cleanErrorMessage()}
        >
          Закрыть сообщение
        </Button>
      </div>
    </div>
  ) : null;
};

export default observer(BuildingOneStaffTableError);
