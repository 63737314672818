import styles from "./commentsShowWithButton.module.scss";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { fileUrl } from "stores/utils/consts";
import Modal from "shared/ui/Modal";
import { ReactComponent as IconClip } from "shared/assets/images/mainIcons/iconClip/iconClip.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { Comment } from "stores/Comments/types/Comment";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";

type CommentsShowWithButtonProps = {
  comment: Comment;
  deleteComment: (rid: string, id: string) => void;
};

const CommentsShowWithButton = ({
  comment,
  deleteComment
}: CommentsShowWithButtonProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [border, setBorder] = useState(false);

  const { id } = useParams();

  let fileName: string;
  let url: string | null = null;
  let isImage = false;

  if (!isEmpty(comment.files)) {
    fileName = getValues(comment.files)[0]?.title;
    url = getValues(comment.files)[0]?.path;
    const ext = fileName?.split(".")?.pop();
    isImage = ["jpg", "jpeg", "png", "gif"].indexOf(ext) !== -1;
  }

  return (
    <>
      <li className="w-100">
        <span
          className={`${styles.commentWrapper} ${
            border ? styles.wrapperActive : ""
          }`}
        >
          <span className={styles.comment}>
            <span className={styles.commentDateAndName}>
              <span className={styles.date}>
                {getFormattedDate(comment.time_create).replace(/ /g, ", в ")}
              </span>
              <span className={styles.authorName}>
                {comment.author?.title || ""}
              </span>
            </span>
            <span className={styles.commentMiddleRow}>
              {isImage && (
                <a
                  href={fileUrl + url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.imageFile}
                  id={`commentsShowWithButton_imageLink_${comment.id}`}
                >
                  <img src={fileUrl + url} />
                </a>
              )}
              {comment ? (
                <span className={styles.commentText}>{comment.comment}</span>
              ) : (
                <span className={styles.commentNoText}></span>
              )}
            </span>
            {url && !isImage ? (
              <a
                href={fileUrl + url}
                target="_blank"
                rel="noreferrer"
                className={styles.uploadedFile}
                key={comment.id}
                id={`commentsShowWithButton_fileLink_${comment.id}`}
              >
                <IconClip />
                {fileName}
              </a>
            ) : null}
          </span>
          <span>
            <Button
              type="button"
              className={styles.basketBtn}
              theme={ButtonTheme.SECONDARY}
              size={ButtonSize.S}
              id={`commentsShowWithButton_deleteButton_${comment.id}`}
              onClick={() => {
                setShowDeleteModal(true);
              }}
              onMouseEnter={() => setBorder(true)}
              onMouseLeave={() => setBorder(false)}
            >
              <IconBasket className={styles.basketBtnImg} />
            </Button>
          </span>
        </span>
      </li>

      <Modal
        type="clarification"
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        title="Вы уверены, что хотите удалить комментарий?"
        btnWithCrossTitle="Удалить"
        btnWithCrossOnClick={() => {
          deleteComment(id, comment.id);
        }}
        greyBtnOnClick={() => {
          setShowDeleteModal(false);
        }}
        greyBtnTitle="Отмена"
        btnWithCrossImg
      />
    </>
  );
};

export default CommentsShowWithButton;
