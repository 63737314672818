import { WidgetMetrics } from "./../types/WidgetMetrics";
import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { StaffListItem } from "../types/StaffListItem";
import { Salary } from "../types/Salary";
import { Company } from "../types/Company";
import { SafetyWork } from "../types/SafetyWork";
import { BuildingEventType } from "../types/BuildingEventType";
import { Widgets } from "../types/Widgets";
import { Award } from "../types/Award";
import { Workshift } from "../types/Workshift";
import { Status } from "../types/Status";
import { Conviction } from "../types/Conviction";
import { SalaryTableRecordsItem } from "../types/SalaryTableRecordsItem";
import { WithholdingEdu } from "../types/WithholdingEdu";

import { isEmpty, without } from "lodash";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { PremiumLevelSelect } from "../types/Selects";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";
import { StaffCardProps } from "../types/StaffCardProps";
import { History } from "../types/History";

type Data = {
  company: Record<string, Company>;
  record: Partial<StaffListItem>;
  unlocked_user: boolean;
  errors?: string;
  message?: Errors["message"];
};

type PremiumLevel = {
  level: string;
  currens_ws: number;
  money: number;
  history: {
    [key: string]: {
      year: number;
      month: {
        [key: string]: {
          month: number;
          days_in_month: number;
          ws: number;
          level: string;
          money: number;
          between: {
            start: string;
            end: number;
          };
        };
      };
    };
  };
};

export default class staffOneStore {
  error: { [staff_id: string]: boolean } = {};
  errorText: { [key: string]: string } = {};
  isLoading: { [staff_id: string]: boolean } = {};
  isLoadingForWidgets: { [staff_id: string]: boolean } = {};
  isLoadingForWidgetMetrics: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_premium_level: { [staff_id: string]: boolean } = {};
  rebootData: { [key: string]: boolean } = {};

  isLoadingForWidget_building_staff_events: { [staff_id: string]: boolean } =
    {};
  isLoadingForWidget_salary: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_withholding_edu: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_workshift: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_safety_work: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_conviction: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_awards: { [staff_id: string]: boolean } = {};
  isLoadingForWidget_history: { [staff_id: string]: boolean } = {};

  info: Partial<StaffListItem> = {};
  widgetMetrics: { [staff_id: string]: WidgetMetrics } = {};
  companiesList: { [company_id: string]: Company } = {};
  awards: { [key: string]: { [key: string]: Award } } = {};
  building_staff_events: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          [key: string]: Status;
        };
      };
    };
  } = {};
  history: Record<string, History> = {};
  safety_work: { [key: string]: SafetyWork } = {};
  workshift: { [key: string]: Workshift } = {};

  conviction: { [key: string]: { [key: string]: Conviction } } = {};
  companiesData: {
    [key: string]: Company;
  } = {};
  salary: { [key: string]: Salary } = {};
  withholding_edu: { [key: string]: WithholdingEdu } = {};

  premium_level: { [key: string]: PremiumLevel } = {};
  premium_level_selects: { [key: string]: PremiumLevelSelect } = {};
  // вывод сообщения в виджете, если сотрудник не участвует в программе
  premium_level_error_text: { [key: string]: string } = {};

  buildingEventsType: { [key: string]: BuildingEventType } = {};

  companiesTabs: { id: string; title: string; order: number }[] = [];
  selectedCompany = "";

  selectedCompanyInStatus = "";

  companiesPlacesTabs: { id: string; title: string; status: string }[] = [];
  selectedCompanyPlace = "";

  staffTableCols: { [key: string]: { title: string | null } } = {};
  staffGroups: Partial<{
    [key: string]: {
      id: string;
      title: string;
      columns: { [key: string]: string };
    };
  }> = {};

  selectedOne: Partial<Data> = {};
  openedAllStaff: { [key: string]: Partial<Data> } = {};
  lockedIDs: Record<string, { id: string; tn: number }> = {};
  accessToUnlock = false;

  widgetsOrder = [
    "building_staff_events",
    "salary",
    "withholding_edu",
    "workshift",
    "safety_work",
    "conviction",
    "awards",
    "premium_level",
    "history",
    "comments"
  ];

  objectVersionCols: string[] = [];

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  setSelectedCompany = (id: string) => {
    this.selectedCompany = id;
    this.selectedCompanyInStatus = "";
    this.setCompaniesPlacesTabs();
  };

  setSelectedCompanyInStatus = (id: string) => {
    this.selectedCompanyInStatus = id;
  };

  setCompaniesTabs = (id: string) => {
    //создаем массив из объектов с ключами: название компании и ее порядок в метриках
    const orderList =
      this.widgetMetrics[id]?.["building_metrics"] &&
      Object.values(this.widgetMetrics[id]["building_metrics"]).map(
        (building) => {
          return {
            company_title: building.company_title,
            order: building.order
          };
        }
      );

    Object.entries(this.companiesData).forEach(([key, company]) => {
      if (key) {
        // в переменную order записываем порядок каждой компании в метриках
        const order =
          orderList?.length &&
          orderList.map((item) => item["company_title"]).includes(company.title)
            ? orderList.filter(
                (item) => item.company_title === company.title
              )[0]["order"]
            : orderList?.length
            ? // ставим order на 1 больше чем максимальный из массива orderList для тех компаний, которые не приходят в метриках
              orderList.sort((a, b) => b.order - a.order)[0]["order"] + 1
            : 1;
        const companiesTab: { id: string; title: string; order: number } = {
          id: "",
          title: "",
          order
        };
        if (company.id && company.title) {
          companiesTab["id"] = company.id;
          companiesTab["title"] = company.title;
          if (!this.companiesTabs.map((tab) => tab.id).includes(company.id)) {
            this.companiesTabs.push(companiesTab);
          }
        }
      }
    });

    // сортируем согласно заданному выше поле order
    this.companiesTabs.sort((a, b) => a.order - b.order);
    if (this.companiesTabs.length) {
      this.setSelectedCompany(this.companiesTabs[0]["id"]);
    }
    if (
      !this.companiesData?.[this.selectedCompany]?.["employments"] &&
      this.info["position"]
    ) {
      runInAction(() => {
        this.info["position"] = "Должность не указана";
      });
    }

    this.isLoading[id] = false;
  };

  setSelectedCompanyPlace = (id: string) => {
    this.selectedCompanyPlace = id;

    const changeValue = (initialValue: string | string[], value?: string) => {
      if (typeof initialValue === "object") {
        initialValue.forEach((item) => {
          this.info[item] = this.companiesData[this.selectedCompany]?.[
            "employments"
          ]?.[id]?.[item]
            ? this.companiesData[this.selectedCompany]["employments"][id][item]
            : "";
        });
      } else {
        this.info[initialValue] = this.companiesData[this.selectedCompany]?.[
          "employments"
        ]?.[id]?.[value]
          ? this.companiesData[this.selectedCompany]["employments"][id][value]
          : this.companiesData[this.selectedCompany]?.["employments"]?.[id]?.[
              initialValue
            ]
          ? "Должность не указана"
          : "";
      }
    };
    changeValue("position", "position_title");

    changeValue(without(this.objectVersionCols, "position"));
  };

  setCompaniesPlacesTabs = () => {
    runInAction(() => {
      if (this.companiesData[this.selectedCompany]?.["employments"]) {
        this.companiesPlacesTabs = [];
        const datesObj = Object.entries(
          this.companiesData[this.selectedCompany]["employments"]
        );
        datesObj.sort((a, b) => {
          if (a[1].trud_dog.start_date === b[1].trud_dog.start_date) {
            return 0;
          } else if (a[1].trud_dog.start_date < b[1].trud_dog.start_date) {
            return 1;
          } else return -1;
        });
        datesObj.forEach(([key, value]) => {
          const companiesPlacesTab: {
            id: string;
            title: string;
            status: string;
          } = {
            id: "",
            title: "",
            status: ""
          };
          companiesPlacesTab["id"] = key;
          companiesPlacesTab["status"] = value["status"];
          if (typeof value["employment_title"] === "string") {
            companiesPlacesTab["title"] = value["employment_title"]
              ? value["employment_title"]
              : "Не определено";
          }
          this.companiesPlacesTabs.push(companiesPlacesTab);
        });
        this.setSelectedCompanyPlace(this.companiesPlacesTabs[0].id);
      } else {
        this.companiesPlacesTabs = [];
      }
    });
  };

  getTitleField = (key: string): string => {
    if (Object.keys(this.staffTableCols).length && this.staffTableCols[key]) {
      return this.staffTableCols[key]["title"];
    } else return "";
  };

  updateStaffInfo = (obj?: Partial<Data>) => {
    runInAction(() => {
      if (obj) {
        this.info = obj["record"];
        this.companiesData = obj["company"];
      } else {
        this.info = {};
        this.companiesData = {};
        this.selectedCompany = "";
        this.selectedCompanyPlace = "";
        this.companiesPlacesTabs = [];
        this.companiesTabs = [];
      }
    });
  };

  setSelectedOneForInfo = (id: string) => {
    this.isLoading[id] = true;
    this.isLoadingForWidgets[id] = true;
    this.error[id] = false;

    this.updateStaffInfo();
    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
    }

    if (
      Object.keys(this.openedAllStaff).length &&
      this.openedAllStaff[id] &&
      !this.rebootData[id]
    ) {
      if (Object.values(this.openedAllStaff[id]).length) {
        this.selectedOne = this.openedAllStaff[id];
        this.updateStaffInfo(this.selectedOne);
        this.setCompaniesTabs(id);
      } else {
        this.error[id] = true;
      }
      this.isLoading[id] = false;
      this.isLoadingForWidgets[id] = false;
    } else {
      Promise.all([
        isEmpty(this.buildingEventsType) && this.getBuildingEventType(id),
        isEmpty(this.premium_level_selects) && this.getPremiumLevelSelects(),
        isEmpty(this.staffTableCols) && this.getStaffCardProps(),
        this.getOneOfStaffData(id)
      ]);
    }
  };

  getStaffCardProps = async () => {
    try {
      const data: ApiResponse<StaffCardProps> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getStaffCardProps"
        });

      runInAction(() => {
        if (data.code == 200) {
          getValues(data.result.groups).forEach((group) => {
            this.staffGroups[group.id] = group;
          });
          this.staffTableCols = data.result.columns;
          this.objectVersionCols = getKeys(data.result.ov_cols);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  getOneOfStaffData = async (id: string) => {
    this.isLoading[id] = true;
    this.error[id] = false;
    this.errorText[id] = "";

    try {
      const data: Data = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getOne",
        params: {
          staff_id: id
        }
      });
      runInAction(() => {
        if (!data.errors) {
          //  удалить, когда поля будут приходить корректно
          data.record.inn = data.record.inn.trim();
          data.record.snils = data.record.snils.trim();
          data.record.pasp_n = data.record.pasp_n.trim();
          data.record.pasp_code = data.record.pasp_code.trim();

          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/staff/id=${id}`,
            title: `${data.record.surname || ""} ${data.record.name || ""}`
          });

          if (data.record.locked) {
            this.lockedIDs[id] = {
              id: data.record.locked_id,
              tn: data.record.locked
            };
          }

          this.accessToUnlock = data.unlocked_user;

          this.rootStore.menuStore.tabId === id && this.updateStaffInfo(data);

          if (!(id in this.lockedIDs)) {
            this.getWidgetMetrics(id);
          }
        } else {
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/staff/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error[id] = true;
          // hardcode - пока приходит 200 код при ошибке "Не найден сотрудник"
          this.errorText[id] = data.message.head;
        }

        this.openedAllStaff[id] = data;
      });
    } catch (error) {
      this.error[id] = true;
      this.rootStore.menuStore.updateTabWindow({
        mainPath: `/staff/id=${id}`,
        title: "Ничего не найдено"
      });
      runInAction(() => {
        this.error[id] = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getBuildingEventWidget = async (id: string) => {
    this.isLoadingForWidget_building_staff_events[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["building_staff_events"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.building_staff_events[id] =
              data["widgets"]["building_staff_events"];
          }
        } else {
          this.building_staff_events[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.building_staff_events[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_building_staff_events[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getWithHoldingEduWidget = async (id: string) => {
    this.isLoadingForWidget_withholding_edu[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["withholding_edu"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.withholding_edu[id] = data["widgets"]["withholding_edu"];
          }
        } else {
          this.withholding_edu[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.withholding_edu[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_withholding_edu[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getWorkshiftWidget = async (id: string) => {
    this.isLoadingForWidget_workshift[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["workshift"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.workshift[id] = data["widgets"]["workshift"];
          }
        } else {
          this.workshift[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.workshift[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_workshift[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getSafetyWorkWidget = async (id: string) => {
    this.isLoadingForWidget_safety_work[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["safety_work"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.safety_work[id] = data["widgets"]["safety_work"];
          }
        } else {
          this.safety_work[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.safety_work[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_safety_work[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getConvictionWidget = async (id: string) => {
    this.isLoadingForWidget_conviction[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["conviction"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.conviction[id] = data["widgets"]["conviction"];
          }
        } else {
          this.conviction[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.conviction[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_conviction[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getAwardsWidget = async (id: string) => {
    this.isLoadingForWidget_awards[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["awards"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.awards[id] = data["widgets"]["awards"];
          }
        } else {
          this.awards[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.awards[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_awards[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getHistoryWidget = async (id: string) => {
    this.isLoadingForWidget_history[id] = true;

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["history"]
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          if (this.rootStore.menuStore.tabId === id) {
            this.history[id] = data["widgets"]["history"];
          }
        } else {
          this.history[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.history[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_history[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getSalaryWidget = async (id: string) => {
    this.isLoadingForWidget_salary[id] = true;

    try {
      const data: Widgets & {
        // здесь расширяем тип Widgets, т.к.
        // изменилась вложенность у таблицы финансов
        // ранее приходила таблица с ключами-месяцами года
        // сейчас ключи - года, а внутри уже ключи-месяцы года
        widgets: {
          salary: {
            table: {
              records: {
                [key: string]:
                  | SalaryTableRecordsItem
                  | {
                      [key: string]: SalaryTableRecordsItem;
                    };
              };
            };
          };
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: this.widgetsOrder
        }
      });

      runInAction(() => {
        if (!data["errors"]) {
          // перед сохранением данных в this.allData["widgets"] нужно изменить структуру финансов
          // записываем ответ с сервера в объект который унаследует тип data
          const allDataWithYearSalaryTable = data["widgets"];
          // проверяем доступен ли пользователю виджет финансов
          if (
            allDataWithYearSalaryTable.salary?.table?.records &&
            Object.values(allDataWithYearSalaryTable.salary.table.records)
              .length
          ) {
            // и перезаписываем его, вызвав функцию this.changeSalaryTableRecords()
            // которая вернет отформатированные данные финансов
            allDataWithYearSalaryTable.salary.table.records =
              this.changeSalaryTableRecords(
                allDataWithYearSalaryTable.salary.table.records as {
                  [key: string]: { [key: string]: SalaryTableRecordsItem };
                }
              );
          }

          if (this.rootStore.menuStore.tabId === id) {
            this.salary[id] = allDataWithYearSalaryTable["salary"];
          }
        } else {
          this.salary[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.salary[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_salary[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getWidgetMetrics = async (id: string) => {
    this.isLoadingForWidgetMetrics[id] = true;
    try {
      const data: ApiResponse<WidgetMetrics | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getWidgetMetrics",
          params: {
            uid: id
          }
        });

      runInAction(() => {
        if (data.result) {
          Promise.all([
            isEmpty(this.companiesList) && this.getCompanies(id)
          ]).then(() => {
            runInAction(() => {
              this.widgetMetrics[id] = data["result"] as WidgetMetrics;
              this.setCompaniesTabs(id);
            });
          });
        } else {
          this.widgetMetrics[id] = null;
          this.error[id] = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidgetMetrics[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getCompanies = async (id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList"
        });

      runInAction(() => {
        if (data.result !== -1) {
          getValues(data.result).forEach((company) => {
            this.companiesList[company.id] = company;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
      });
    }
  };

  getPremiumLevelWidget = async (id: string) => {
    this.isLoadingForWidget_premium_level[id] = true;

    try {
      const data: ApiResponse<PremiumLevel> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "level",
          method: "getOne",
          params: {
            id
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          if (Object.values(data["result"]).length && !("message" in data)) {
            this.premium_level[id] = data["result"];
          } else {
            this.premium_level[id] = null;
            this.premium_level_error_text[id] = data["message"]?.["head"];
          }
        } else {
          this.premium_level[id] = null;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.premium_level[id] = null;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForWidget_premium_level[id] = false;
        this.setRebootStaff(id, false);
      });
    }
  };

  getPremiumLevelSelects = async () => {
    try {
      const data: { selects: { type: { [key: string]: PremiumLevelSelect } } } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "level",
          method: "getTypes"
        });

      runInAction(() => {
        if (!data["errors"]) {
          this.premium_level_selects = data["selects"]["type"];
        } else {
          this.premium_level_selects = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.premium_level_selects = {};
      });
    }
  };

  getBuildingEventType = async (id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: BuildingEventType } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_events_type",
          method: "getList"
        });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((type) => {
            this.buildingEventsType[type.id] = type;
          });
        } else this.error[id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
      });
    }
  };

  changeSalaryTableRecords = (salaryTableRecords: {
    [key: string]: { [key: string]: SalaryTableRecordsItem };
  }) => {
    const newSalaryTableRecords: {
      [key: string]: SalaryTableRecordsItem;
    } = {};

    Object.values(salaryTableRecords).forEach((yearData) => {
      Object.entries(yearData).forEach(([month, monthData]) => {
        newSalaryTableRecords[month] = monthData;
      });
    });

    return newSalaryTableRecords as { [key: string]: SalaryTableRecordsItem };
  };

  unlockStaff = async (id: string) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "unlockedUser",
        body: {
          id
        }
      });

      runInAction(() => {
        if (data.code === 200) {
          this.getOneOfStaffData(id);
          delete this.lockedIDs[id];
        } else {
          this.error[id] = true;
          this.isLoading[id] = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
        this.isLoading[id] = false;
      });
    }
  };

  setRebootStaff = (id: string, arg: boolean) => {
    this.rebootData[id] = arg;
  };

  resetAll = () => {
    this.error = {};
    this.errorText = {};
    this.isLoading = {};
    this.isLoadingForWidgets = {};
    this.isLoadingForWidgetMetrics = {};
    this.isLoadingForWidget_premium_level = {};
    this.rebootData = {};

    this.isLoadingForWidget_building_staff_events = {};
    this.isLoadingForWidget_salary = {};
    this.isLoadingForWidget_withholding_edu = {};
    this.isLoadingForWidget_workshift = {};
    this.isLoadingForWidget_safety_work = {};
    this.isLoadingForWidget_conviction = {};
    this.isLoadingForWidget_awards = {};
    this.isLoadingForWidget_history = {};

    this.info = {};
    this.widgetMetrics = {};
    this.companiesList = {};
    this.awards = {};
    this.building_staff_events = {};
    this.history = {};
    this.safety_work = {};
    this.workshift = {};

    this.conviction = {};
    this.salary = {};
    this.withholding_edu = {};

    this.premium_level = {};
    this.premium_level_selects = {};
    this.premium_level_error_text = {};

    this.buildingEventsType = {};

    this.companiesTabs = [];
    this.selectedCompany = "";

    this.selectedCompanyInStatus = "";

    this.companiesPlacesTabs = [];
    this.selectedCompanyPlace = "";

    this.staffTableCols = {};
    this.staffGroups = {};

    this.selectedOne = {};
    this.openedAllStaff = {};
    this.lockedIDs = {};
    this.accessToUnlock = false;
  };
}
